
  import SidebarItem from '@/components/SidebarItem/SidebarItem.vue';
  import {
    FEATURE_FILTER,
    FEATURE_IMAP_TOKENS,
    FEATURE_TEAM_ACCOUNT,
  } from '@/lib/featureFlags';
  import {
    SETTINGS_ACCOUNT,
    SETTINGS_CONTACT_CONTACTGROUPS,
    SETTINGS_DOMAINS,
    SETTINGS_EMAIL_MIGRATION,
    SETTINGS_ENCRYPTION,
    SETTINGS_GROUP_SUBSCRIPTIONS,
    SETTINGS_IMAP_TOKENS,
    SETTINGS_MAIL,
    SETTINGS_MAIL_FILTERS,
    SETTINGS_MAIL_FOLDERS,
    SETTINGS_PGP_KEYRING,
    SETTINGS_SUBSCRIPTION,
    SETTINGS_TUTORIALS,
  } from '@/router/named-routes';
  import Sidebar from '@/wrappers/SidebarWrapper/SidebarWrapper';
  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';

  export default Vue.extend({
    name: 'SettingsSidebar',
    components: {
      SidebarItem,
      Sidebar,
    },
    computed: {
      ...mapState('authentication', {
        domains: (state: any) => state.user.domains,
      }),
      ...mapGetters('authentication', ['isManager']),
      navigationItems() {
        return [
          {
            id: 'settings-account-settings',
            name: this.$gettext('Account settings'),
            icon: 'gear',
            route: { name: SETTINGS_ACCOUNT },
          },
          {
            id: 'settings-mail-settings',
            name: this.$gettext('Mail settings'),
            icon: 'mailsettings',
            route: { name: SETTINGS_MAIL },
          },
          {
            id: 'settings-trusted-devices',
            name: this.$gettext('Trusted devices/IMAP'),
            icon: 'devices',
            route: { name: SETTINGS_IMAP_TOKENS },
            featureFlag: FEATURE_IMAP_TOKENS,
          },
          {
            id: 'settings-folder',
            name: this.$gettext('Folders'),
            icon: 'folder',
            route: { name: SETTINGS_MAIL_FOLDERS },
          },
          {
            id: 'settings-encryption',
            name: this.$gettext('Encryption'),
            icon: 'locked-outline',
            route: { name: SETTINGS_ENCRYPTION },
          },
          {
            id: 'settings-pgp-keyring',
            name: this.$gettext('PGP keyring'),
            icon: 'key',
            route: { name: SETTINGS_PGP_KEYRING },
          },
          this.isManager && {
            id: 'settings-domains',
            name: this.$gettext('Domains'),
            icon: 'domains',
            route: { name: SETTINGS_DOMAINS },
          },
          {
            id: 'settings-filters',
            name: this.$gettext('Filters'),
            icon: 'filter',
            route: { name: SETTINGS_MAIL_FILTERS },
            featureFlag: FEATURE_FILTER,
          },
          {
            id: 'settings-contact-groups',
            name: this.$gettext('Contact groups'),
            icon: 'group',
            route: { name: SETTINGS_CONTACT_CONTACTGROUPS },
          },
          this.isManager && {
            id: 'settings-subscription',
            name: this.$gettext('Subscription'),
            icon: 'subscription',
            route: { name: SETTINGS_SUBSCRIPTION },
          },
          this.isManager && {
            id: 'settings-email-accounts',
            name: this.$gettext('Group subscription'),
            icon: 'email-accounts',
            route: { name: SETTINGS_GROUP_SUBSCRIPTIONS },
            featureFlag: FEATURE_TEAM_ACCOUNT,
          },
          {
            id: 'settings-email-migration',
            name: this.$gettext('Migration'),
            icon: 'migration',
            route: { name: SETTINGS_EMAIL_MIGRATION },
          },
          {
            id: 'settings-tutorials',
            name: this.$gettext('Guides and tutorials'),
            icon: 'tutorial',
            route: { name: SETTINGS_TUTORIALS },
          },
        ].filter(Boolean);
      },
    },
  });
