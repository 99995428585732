
  import Vue from 'vue';
  import Icon from '@/components/Icon/Icon.vue';
  import api from '@/api';

  export default Vue.extend({
    name: 'StatusBanner',
    components: {
      Icon,
    },
    data() {
      return {
        category: '',
        name: '',
        link: '',
        impactClass: '',
        scheduleDate: '',
      };
    },
    created() {
      this.getStatusUpdate();
    },
    methods: {
      async getStatusUpdate() {
        const output = await api.statusUpdate.get();
        if (!output) {
          return;
        }
        this.category = Object.keys(output)[0];
        const details = output[this.category as keyof typeof output];
        switch (this.category) {
          case 'incident':
            this.name = details['name'];
            this.link = details['link'];
            switch (details['impact']) {
              case 'critical':
                this.impactClass = 'user-message--error';
                break;
              case 'major':
                this.impactClass = 'user-message--warning';
                break;
            }
            break;
          case 'maintenance':
            this.name = details['name'];
            this.link = details['link'];
            this.scheduleDate = details['schedule_date'];
            this.impactClass = 'user-message--info';
            break;
        }
      },
    },
  });
