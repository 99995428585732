
  import Vue, { PropType } from 'vue';

  import ActionBarButton from '@/components/ActionBarButton.vue';
  import Checkbox from '@/components/form/Checkbox.vue';
  import Dropdown from '@/components/Dropdown/Dropdown.vue';
  import RadioInput from '@/components/form/RadioInput.vue';

  export const enum SortOption {
    CreationDateAsc = 'CreationDateAsc',
    CreationDateDesc = 'CreationDateDesc',
    AliasEmailAsc = 'AliasEmailAsc',
    AliasEmailDesc = 'AliasEmailDesc',
  }

  export interface AliasFilterEvent {
    active: boolean;
    inactive: boolean;
    domains: string[];
    sortBy: SortOption;
  }

  export default Vue.extend({
    name: 'AliasesTopBar',
    components: {
      ActionBarButton,
      Checkbox,
      Dropdown,
      RadioInput,
    },
    props: {
      aliases: {
        type: Array as PropType<Alias[]>,
        required: true,
      },
    },
    data() {
      return {
        active: false,
        inactive: false,
        sortBy: SortOption.CreationDateDesc,
        selectedDomains: [] as string[],
      };
    },
    computed: {
      filterOptions(): Record<string, string> {
        return {
          active: this.$gettext('Active'),
          inactive: this.$gettext('Inactive'),
        };
      },
      domains(): string[] {
        return [
          ...new Set(this.aliases.map((alias) => alias.alias.split('@')[1])),
        ].sort();
      },
      filterCount(): number {
        return (
          Number(this.active) +
          Number(this.inactive) +
          this.selectedDomains.length
        );
      },
      filterLabel(): string {
        if (this.filterCount === 0) {
          return this.$gettext('Filter by');
        } else if (this.filterCount === 1) {
          let filter;
          if (this.active) {
            filter = this.$gettext('Active');
          } else if (this.inactive) {
            filter = this.$gettext('Inactive');
          } else {
            filter = this.selectedDomains[0];
          }
          return this.$gettextInterpolate(
            this.$gettext('Filter by: %{ filter }'),
            { filter }
          );
        } else {
          return this.$gettextInterpolate(
            this.$gettext('%{ number } filters'),
            { number: this.filterCount }
          );
        }
      },
      sortLabel(): string {
        return this.$gettext('Sort by');
      },
      sortOptions(): HTMLSelectOption[] {
        return [
          {
            value: SortOption.CreationDateAsc,
            name: this.$gettext('Creation date (ascending)'),
          },
          {
            value: SortOption.CreationDateDesc,
            name: this.$gettext('Creation date (descending)'),
          },
          {
            value: SortOption.AliasEmailAsc,
            name: this.$gettext('Alias email (ascending)'),
          },
          {
            value: SortOption.AliasEmailDesc,
            name: this.$gettext('Alias email (descending)'),
          },
        ];
      },
    },
    mounted() {
      // emit the initial filter values that are set without user interaction
      this.emit();
    },
    watch: {
      active() {
        this.emit();
      },
      inactive() {
        this.emit();
      },
      sortBy() {
        this.emit();
      },
      selectedDomains() {
        this.emit();
      },
    },
    methods: {
      clearFilters(): void {
        // not resetting sort order because i dont think users expect that
        this.active = false;
        this.inactive = false;
        this.selectedDomains.splice(0, this.selectedDomains.length);
      },
      toggleDomain(domain: string): void {
        if (this.selectedDomains.includes(domain)) {
          this.selectedDomains.splice(this.selectedDomains.indexOf(domain), 1);
        } else {
          this.selectedDomains.push(domain);
        }
      },
      emit(): void {
        const event: AliasFilterEvent = {
          active: this.active,
          inactive: this.inactive,
          domains: this.selectedDomains,
          sortBy: this.sortBy,
        };
        this.$emit('change', event);
      },
    },
  });
