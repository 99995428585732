
  import Vue from 'vue';
  import api from '@/api';
  import Badge from '@/components/Badge/Badge.vue';
  import ContactForm from '@/components/ContactForm/ContactForm.vue';
  import Dropdown from '@/components/Dropdown/Dropdown.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import formatRecipient from '@/lib/formatRecipient';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';

  export default Vue.extend({
    name: 'RecipientBadge',
    components: {
      Badge,
      ContactForm,
      Dropdown,
      Icon,
      Modal,
      Spinner,
    },
    mixins: [asyncActionsMixin],
    props: {
      draggable: {
        type: Boolean,
        default: false,
      },
      hideEmail: {
        type: Boolean,
        default: false,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      isKeyAvailable: {
        type: Boolean,
        default: false,
      },
      isRemovable: {
        type: Boolean,
        default: false,
      },
      official: {
        type: Boolean,
        default: false,
      },
      showBadgeDropdown: {
        type: Boolean,
        default: true,
      },
      showKeyAvailability: {
        type: Boolean,
        default: false,
      },
      tabindex: {
        type: [Number, String],
        default: 0,
      },
      value: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: '',
      },
    },
    data() {
      return {
        contact: null as Contact | null,
        badgeClicked: false,
      };
    },
    computed: {
      badge(): any {
        return this.$refs.badge;
      },
      displayValue(): string {
        return formatRecipient(this.value, this.hideEmail);
      },
      defaultContactData(): Partial<Contact> {
        return {
          name: this.value.name || '',
          email_addresses: [
            {
              label: 'home',
              value: this.value.email || '',
              fingerprints: [],
              is_primary: true,
            },
          ],
        };
      },
      selectedAddress: {
        get(): string {
          return this.value.email ? this.value.email : '';
        },
        set(value: string) {
          this.$emit('input', {
            contact: this.value.contact,
            name: this.value.name,
            email: value,
          });
        },
      },
      keyAvailable(): boolean {
        return this.showKeyAvailability && this.isKeyAvailable;
      },
      emailAddresses(): string[] {
        if (this.contact) {
          return this.contact.email_addresses.map((email) => email.value);
        } else {
          return [this.value.email];
        }
      },
      emailAddressOptions(): HTMLSelectOption[] {
        return this.emailAddresses.map((emailAddress) => ({
          value: emailAddress,
          name: emailAddress,
        }));
      },
      prefillContact(): Contact {
        return {
          address: '',
          always_encrypt: false,
          always_show_external_images: false,
          always_sign: false,
          city: '',
          company_name: '',
          contact_group_ids: [],
          country: '',
          display_name: '',
          email_addresses: [
            {
              label: 'home',
              value: this.value.email || '',
              fingerprints: [],
              is_primary: true,
            },
          ],
          favorite: false,
          id: '',
          job_title: '',
          name: this.value.name || '',
          notes: '',
          phone_number: '',
          po_box: '',
          state: '',
          website: '',
          zipcode: '',
        };
      },
    },
    created() {
      this.contact = this.value.contact || null;
    },
    methods: {
      focus() {
        this.badge.focus();
      },
      onBlur() {
        this.$emit('blur');
      },
      onFocus() {
        this.$emit('focus');
      },
      async onClickBadge(toggleDropdown: () => void) {
        if (!this.isInvalid) {
          toggleDropdown();
          this.contact = await this.findContactInformation();
        }
      },
      findContactInformation: asyncAction(
        'findingContactInformation',
        async function (this: any) {
          if (this.contact) return this.contact;

          const { contacts } = await api.contacts.find({
            groupId: 'all',
            limit: 1,
            offset: 0,
            query: this.value.email,
          });

          return contacts[0] || null;
        }
      ),
      onEdit() {
        this.$emit('edit', this.value.email);
      },
      async copy() {
        try {
          await navigator.clipboard.writeText(this.displayValue);
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      async cut() {
        try {
          await navigator.clipboard.writeText(this.displayValue);
          this.$emit('delete');
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      onContactSaved({ contact }: { contact: Contact }) {
        this.contact = contact;
      },
    },
  });
