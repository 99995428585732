
  import Vue from 'vue';

  interface HcaptchaOptions {
    sitekey: string;
    theme?: 'light' | 'dark';
    size?: 'normal' | 'compact';
    tabindex?: number | string;
    callback: (hcaptchaResponseToken: string) => void;
    'expired-callback': () => void;
    'error-callback': (err: Error) => void;
  }

  declare global {
    interface Window {
      hcaptcha: {
        render: (el: HTMLElement, options: HcaptchaOptions) => string;
        reset: (widgetId: string) => void;
        getResponse: (widgetId: string) => string;
        execute: (widgetId: string) => void;
      };
    }
  }

  const HCAPTCHA_SITE_KEY = process.env.VUE_APP_HCAPTCHA_SITEKEY;

  export default Vue.extend({
    name: 'Captcha',
    props: {
      tabindex: {
        type: [Number, String],
        default: 0,
      },
      validate: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        captchaToken: '',
        hcaptchaId: '',
        valid: false,
        styleElement: null as HTMLStyleElement | null,
      };
    },
    computed: {
      input(): HTMLInputElement {
        return this.$refs.input as HTMLInputElement;
      },
      hcaptchaLanguageCode(): string {
        if (this.$language.current.includes('de')) {
          return 'de';
        }
        return 'en';
      },
      captchaErrorMessage(): string {
        return this.$gettext(
          'Something went wrong while trying to validate if you are a human. Please try again.'
        );
      },
    },
    watch: {
      captchaToken() {
        /* when the value is updated programatically, the input is not updated
           right away. so we check for validity on the next tick to make sure it
           has the right value. */
        this.$nextTick(() => {
          this.valid = this.input.checkValidity();
        });
      },
    },
    mounted() {
      if (typeof window.hcaptcha === 'undefined') {
        this.loadHcaptcha();
      } else {
        this.renderHcaptcha();
      }
      this.injectDarkModeExemptStyles();
    },
    beforeDestroy() {
      this.removeDarkModeExemptStyles();
    },
    methods: {
      loadHcaptcha() {
        return new Promise<void>((resolve, reject) => {
          const script = document.createElement('script');
          script.src =
            'https://hcaptcha.com/1/api.js' +
            `?hl=${this.hcaptchaLanguageCode}` +
            '&onload=hcaptchaOnloadCallback' +
            '&render=explicit';
          script.addEventListener('load', () => {
            (window as any).hcaptchaOnloadCallback = () => {
              (window as any).hcaptchaOnloadCallback = null;
              resolve();
            };
          });
          script.onerror = reject;
          document.body.appendChild(script);
        })
          .then(this.renderHcaptcha)
          .catch((err) => {
            this.$emit('error', err);
          });
      },
      renderHcaptcha() {
        this.hcaptchaId = window.hcaptcha.render(
          this.$refs.hcaptchaWidget as HTMLElement,
          {
            callback: this.onVerify.bind(this),
            'expired-callback': this.onExpired.bind(this),
            'error-callback': this.onError.bind(this),
            sitekey: HCAPTCHA_SITE_KEY || '',
            tabindex: this.tabindex,
          }
        );
        this.$emit('initialized');
      },
      resetHcaptcha() {
        window.hcaptcha.reset(this.hcaptchaId);
        this.$emit('reset');
      },
      onVerify(hcaptchaResponseToken: string) {
        this.captchaToken = hcaptchaResponseToken;
        this.$emit('verify', this.captchaToken);
      },
      onExpired() {
        this.captchaToken = '';
        this.$emit('expired');
      },
      onError(err: Error) {
        this.captchaToken = '';
        this.resetHcaptcha();
        this.$emit('error', err);
      },
      injectDarkModeExemptStyles() {
        // The hCaptcha component injects a hard to style <iframe>
        // that cannot be styled easily (no classes, unable to add
        // inline styles). Inverted captcha images are hard to
        // recognise, so exempt iframes (hCaptcha) from the dark mode
        // inversion by injecting a <style> tag.
        const css =
          '.dark-mode iframe { filter: invert(1) hue-rotate(180deg); }';
        const styleElement = document.createElement('style');
        document.head.appendChild(styleElement);
        styleElement.appendChild(document.createTextNode(css));
        this.styleElement = styleElement;
      },
      removeDarkModeExemptStyles() {
        // See injectDarkModeExemptStyles() above.
        if (this.styleElement) this.styleElement.remove();
      },
    },
  });
