(
<template>
  <AppHeader
    :show-back-button="true"
    :back-text="backTo"
    @backClicked="onBackClicked"
    class="message-detail-header"
  >
    <template #button>
      <router-link
        v-test:composeButton
        class="button button--primary button--icon-only-small-breakpoint app-header__action message-detail-header__compose-button"
        :to="composeRoute"
      >
        <translate>Compose</translate>
        <Icon symbol="pen" />
      </router-link>
      <MailSearchForm />
    </template>
  </AppHeader>
</template>
)

<script>
  import Icon from '@/components/Icon/Icon';
  import MailSearchForm from '@/components/SearchForm/MailSearchForm';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import {
    MAIL_COMPOSE,
    MAIL_FOLDER,
    MAIL_SEARCH,
  } from '@/router/named-routes';
  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      AppHeader,
      Icon,
      MailSearchForm,
    },
    mixins: [folderUtilsMixin],
    data() {
      return {
        backFolder: {},
      };
    },
    computed: {
      ...mapGetters(['activeFolder']),
      backTo() {
        return this.isSearch
          ? this.$gettext('Search results')
          : this.getFolderName(this.backFolder);
      },
      isSearch() {
        return this.$route.query.query;
      },
      composeRoute() {
        return {
          name: MAIL_COMPOSE,
          query: { ...this.$route.query, folder: this.activeFolder.id },
        };
      },
    },
    watch: {
      activeFolder() {
        if (!Object.keys(this.backFolder).length) {
          this.backFolder = this.activeFolder;
        }
      },
    },
    created() {
      this.backFolder = this.activeFolder;
    },
    methods: {
      onBackClicked() {
        // $route.query has the page number for the search/folder views
        this.isSearch
          ? this.$router.push({ name: MAIL_SEARCH, query: this.$route.query })
          : this.$router.push({
              name: MAIL_FOLDER,
              query: this.$route.query,
              params: { folder: this.backFolder.id },
            });
      },
    },
  };
</script>

<style src="./MessageDetailHeader.scss" lang="scss" />
