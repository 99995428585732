
  import api from '@/api';
  import {
    CHARGEBEE_WRONG_VALUE,
    CHARGEBEE_WRONG_VALUE_COUNTRY,
    CHARGEBEE_WRONG_VALUE_VAT,
    CHARGEBEE_WRONG_VALUE_ZIP,
  } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import { substituteLink } from '@/lib/hyperlink';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import InputField from '@/components/form/InputField.vue';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import SelectInput from './form/SelectInput.vue';
  import { countrySelectOptions } from '@/lib/countries';

  // ISO 3166 country codes that should have the VAT field
  const COUNTRIES_WITH_VAT = [
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'AX', // Finland
    'FR', // France
    'DE', // Germany
    'GR', // Greece
    'HU', // Hungary
    'IE', // Ireland
    'IT', // Italy
    'LV', // Latvia
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'NL', // The Netherlands
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
  ];

  export default Vue.extend({
    components: {
      InputField,
      Modal,
      Spinner,
      ModalConfirmActions,
      NotificationBlock,
      SelectInput,
    },
    data() {
      const details: InvoiceDetails = {
        vat_number: '',
        company: '',
        billing_address: {
          addr: '',
          extended_addr: '',
          city: '',
          state: '',
          zip: '',
          country: '',
        },
      };
      return {
        invalidZipCode: false,
        invalidVat: false,
        invalidCountry: false,
        loading: true,
        saving: false,
        unknownFormValidation: false,
        details,
      };
    },
    computed: {
      countries(): HTMLSelectOption[] {
        const locale = this.$store.state.authentication.user.preferences.locale;
        return countrySelectOptions(locale);
      },
      fieldErrorsHtml(): string {
        return substituteLink(
          this.$gettext(
            'One of your fields appears to be incorrect, please update and try again, or contact <a>our support team</a>.'
          ),
          this.contactSupportUrl,
          { external: true }
        );
      },
      hasVatField(): boolean {
        return COUNTRIES_WITH_VAT.includes(
          this.details.billing_address.country
        );
      },
      zipCodeValidationMessage(): string {
        return this.$gettext('Invalid zip code');
      },
      invalidVatValidationMessage(): string {
        return this.$gettext('Invalid VAT number');
      },
      invalidCountryValidationMessage(): string {
        return this.$gettext(
          'Invalid country, please contact our support team'
        );
      },
      businessNameText(): string {
        return this.$gettext('Business name');
      },
      contactSupportUrl(): string {
        return this.$gettext(
          'https://support.startmail.com/hc/en-us/requests/new'
        );
      },
      vatNumberText(): string {
        return this.$gettext('VAT number');
      },
      addressLineOneText(): string {
        return this.$gettext('Address line 1');
      },
      addressLineTwoText(): string {
        return this.$gettext('Address line 2');
      },
      cityText(): string {
        return this.$gettext('City');
      },
      stateText(): string {
        return this.$gettext('State');
      },
      zipCodeText(): string {
        return this.$gettext('Zip code');
      },
      countryText(): string {
        return this.$gettext('Country');
      },
      saveButtonText(): string {
        return this.$gettext('Save');
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      async getInvoiceDetails() {
        this.loading = true;
        await api.chargebee
          .getInvoiceDetails()
          .then((details: InvoiceDetails) => {
            this.details = details;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      onFormSubmit(toggle: Function) {
        this.saving = true;

        this.unknownFormValidation = false;
        this.invalidZipCode = false;
        this.invalidCountry = false;
        this.invalidVat = false;

        return api.chargebee
          .updateInvoiceDetails(this.details)
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Invoice details updated'),
            });

            this.saving = false;
            toggle();

            this.$emit('updateInvoiceDetails');
          })
          .catch((error) => {
            const errorCode = getErrorCode(error);

            switch (errorCode) {
              case CHARGEBEE_WRONG_VALUE:
                this.unknownFormValidation = true;
                break;
              case CHARGEBEE_WRONG_VALUE_ZIP:
                this.invalidZipCode = true;
                break;
              case CHARGEBEE_WRONG_VALUE_COUNTRY:
                this.invalidCountry = true;
                break;
              case CHARGEBEE_WRONG_VALUE_VAT:
                this.invalidVat = true;
                break;
              default:
                if (error.response?.status >= 500) {
                  this.setToastMessage({
                    message: this.$gettext(
                      'Something went wrong. Please try again later.'
                    ),
                  });
                }
                this.saving = false;
                throw error;
            }

            this.saving = false;
          });
      },
      onVatBlur() {
        // Strip country codes from VAT if first two characters are uppercase
        this.details.vat_number = this.details.vat_number.replace(
          /^[A-Z]{2}/,
          ''
        );
      },
    },
  });
