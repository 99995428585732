
  import ChargebeeHosted from '@/components/Chargebee/ChargebeeHosted.vue';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    components: { ChargebeeHosted },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['syncSubscription']),
      async checkoutError() {
        this.setToastMessage({
          message: this.$gettext(
            'Something went wrong. Please try again later.'
          ),
        });
      },
    },
  });
