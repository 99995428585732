
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'DeletedUserConfirmation',
    components: {
      AuthenticationLayout,
    },
    data() {
      return {
        backgroundImage: require('@/assets/img/envelope-illustration.svg'),
      };
    },
  });
