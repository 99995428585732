
  import Vue from 'vue';
  import QueryModal from '@/components/QueryModals/QueryModal.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'GroupSubscriptionCreateAccountNotifications',
    components: {
      QueryModal,
      Spinner,
    },
    props: {
      createGroupSubscriptionNotifications: {
        type: Object,
        default: () => ({
          isOpen: false,
          activeStep: 3,
        }),
      },
    },
    methods: {
      ...mapActions('subscription', ['getGroupSubscriptions']),
      async closeModal() {
        await this.getGroupSubscriptions();
        // not sure how to fix… 😭
        // eslint-disable-next-line vue/no-mutating-props
        this.createGroupSubscriptionNotifications.isOpen = false;
      },
    },
  });
