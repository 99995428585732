
  import Icon from '@/components/Icon/Icon.vue';
  import { PropType } from 'vue';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ProgressSteps',
    components: {
      Icon,
    },
    props: {
      steps: {
        type: Array as PropType<number[]>,
        required: true,
      },
      activeStep: {
        type: Number,
        default: 0,
      },
      errorIcon: {
        type: Array as PropType<number[]>,
        default: () => [],
      },
    },
    computed: {
      activeStepIndex(): number {
        return this.steps.indexOf(this.activeStep);
      },
    },
    methods: {
      stepTitle(step: number): string {
        switch (step) {
          case 0:
            return this.$gettext('Generating access code');
          case 1:
            return this.$gettext('Migration portal');
          case 2:
            return this.$gettext('Checking status');
          case 3:
            return this.$gettext('Migration result');

          default:
            return '';
        }
      },
    },
  });
