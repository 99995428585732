
  import Vue from 'vue';

  export default Vue.extend({
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      value: {
        type: String,
        required: false,
        default: '',
      },
      error: {
        type: String,
        required: false,
        default: '',
      },
    },
    watch: {
      error() {
        (this.$refs.input as HTMLInputElement).setCustomValidity(this.error);
      },
    },
    computed: {
      uid(): string {
        return (this.id || `better-input-${(this as any)._uid}`) as string;
      },
      listeners(): Record<string, Function | Function[]> {
        return {
          ...this.$listeners,
          input: (event: InputEvent) =>
            this.$emit('input', (event.target as HTMLInputElement).value),
        };
      },
    },
  });
