
  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import GroupSubscriptionAccountBanner from '@/components/GroupSubscription/GroupSubscriptionAccountBanner.vue';
  import GroupSubscriptionAccounts from '@/components/GroupSubscription/GroupSubscriptionAccounts.vue';
  import GroupSubscriptionCreateAccountModal from '@/components/GroupSubscription/GroupSubscriptionCreateAccountModal.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import GroupSubscriptionWarning from '@/components/GroupSubscription/GroupSubscriptionWarning.vue';
  import GroupSubscriptionCreateAccountNotifications from '@/components/GroupSubscription/GroupSubscriptionCreateAccountNotifications.vue';

  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import SkeletonList from '@/components/SkeletonList/SkeletonList.vue';
  import api from '@/api';

  export default Vue.extend({
    name: 'GroupSubscriptionPage',
    components: {
      GroupSubscriptionAccountBanner,
      GroupSubscriptionAccounts,
      NotificationBlock,
      GroupSubscriptionCreateAccountModal,
      Icon,
      GroupSubscriptionWarning,
      GroupSubscriptionCreateAccountNotifications,
      SkeletonList,
    },
    data() {
      return {
        isLoading: true,
        createGroupSubscriptionNotifications: {},
        isLoadingFailed: false,
      };
    },
    async created() {
      try {
        await Promise.all([
          this.getGroupSubscriptions(),
          this.getUserCurrency(),
          this.getChargebeePlan(),
        ]);
      } catch {
        this.isLoadingFailed = true;
      } finally {
        this.isLoading = false;
      }

      api.uiEvents.create({
        event_type: 'settings_group_subscription_settings_opened',
      });
    },
    computed: {
      ...mapState('subscription', ['chargebeePlan']),
      ...mapGetters('subscription', [
        'hasMultipleAccounts',
        'isAccountCreateAllowed',
      ]),
      errorMessageText(): string {
        return this.$gettext('Something went wrong. Please try again later.');
      },
    },
    methods: {
      ...mapActions('subscription', [
        'getChargebeePlan',
        'getGroupSubscriptions',
        'getUserCurrency',
      ]),
    },
  });
