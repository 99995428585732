
  /*
    iOS has an issue in PWA / add-to-home-screen views with download links. It
    opens links full-screen on top of the app and does not give users a way to
    get back to the app. This download link has a special code-path for iOS
    safari browsers.
  */

  import { detect } from 'detect-browser';
  import FileSaver from 'file-saver';
  import Spinner from './Spinner/Spinner.vue';
  import { mapActions } from 'vuex';
  import Vue from 'vue';

  export default Vue.extend({
    components: {
      Spinner,
    },
    props: {
      filename: { type: String, required: false, default: undefined },
      showProgress: { type: Boolean, required: false, default: false },
      url: { type: String, required: true },
    },
    data() {
      const standalone = window.matchMedia(
        '(display-mode: standalone)'
      ).matches;
      const name = detect()?.name;
      const safari = name === 'ios' || name === 'safari';

      return {
        downloading: false,
        bugged: safari && standalone,
        progress: 0,
      };
    },
    methods: {
      ...mapActions(['setToastMessage']),

      download(event: Event) {
        if (!this.bugged) {
          /* let the browser handle the download normally */
          this.$emit('done');
          return;
        }

        /* prevent the default behaviour, we will handle the download! */
        event.preventDefault();

        this.downloading = true;
        this.progress = 0;

        const xhr = new XMLHttpRequest();
        xhr.open('GET', this.url);
        xhr.responseType = 'blob';

        xhr.onprogress = (event: ProgressEvent) => {
          if (event.lengthComputable) {
            this.progress = (event.loaded / event.total) * 100;
          }
        };

        xhr.onload = () => {
          FileSaver.saveAs(xhr.response, this.filename);
          this.downloading = false;
          this.$emit('done');
        };

        xhr.onerror = () => {
          this.downloading = false;
          this.$emit('done');

          this.setToastMessage({
            message: this.$gettext(
              'Download error. Please refresh and try again.'
            ),
          });
          this.$emit('done');
        };

        xhr.send();
      },
    },
  });
