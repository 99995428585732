<template>
  <Modal
    :modal-is-open="showSigningModal"
    @modalToggled="resetPgpPassphraseModal"
    :loading="sending"
  >
    <template #header>
      <translate>Enter PGP passphrase to sign email</translate>
      <SupportCenterLink
        article="360006842578"
        class="button button--icon-only modal__title-icon"
      >
        <Icon symbol="info" />
      </SupportCenterLink>
    </template>
    <template #content="modal">
      <form @submit.prevent="submitModal" novalidate>
        <PasswordField
          id="pgp-passphrase"
          :label-text="pgpPassphraseText"
          v-model="pgpPassphrase"
          required
          :focused="true"
          :validate-input="validate"
          :validation-error-message="decryptionErrorMessage"
          :show-custom-validity="decryptionBadPassphrase"
          autocomplete="off"
        />
        <ModalConfirmActions
          :ok-text="signAndSendText"
          @cancelClicked="modal.toggle"
        />
      </form>
    </template>
  </Modal>
</template>

<script>
  import Icon from '@/components/Icon/Icon';
  import Modal from '@/components/Modal';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import PasswordField from '@/components/PasswordField/PasswordField';
  import { mapGetters } from 'vuex';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default {
    name: 'SigningModal',
    components: {
      Icon,
      Modal,
      ModalConfirmActions,
      PasswordField,
      SupportCenterLink,
    },
    props: {
      showSigningModal: {
        type: Boolean,
        default: false,
      },
      decryptionBadPassphrase: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        validate: false,
        pgpPassphrase: '',
      };
    },
    computed: {
      ...mapGetters(['sending']),
      pgpPassphraseText() {
        return this.$gettext('Enter your pgp passphrase');
      },
      decryptionErrorMessage() {
        return this.decryptionBadPassphrase
          ? this.$gettext('PGP passphrase is incorrect')
          : this.$gettext('PGP passphrase is required.');
      },
      signAndSendText() {
        return this.$gettext('Sign and Send');
      },
    },
    watch: {
      pgpPassphrase() {
        this.validate = false;
        this.$emit('resetDecryptionBadPassphrase');
      },
    },
    methods: {
      resetPgpPassphraseModal() {
        this.pgpPassphrase = '';
        this.validate = false;
        this.$emit('modalToggled');
      },
      submitModal(event) {
        this.validate = true;
        if (!event.target.checkValidity()) {
          return false;
        }
        this.$emit('sendSignedMessage', this.pgpPassphrase);
      },
    },
  };
</script>
