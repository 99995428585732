
  import Vue from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import MoveToFolderModal from '@/components/MoveToFolderModal/MoveToFolderModal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Modal from '@/components/Modal.vue';
  import InputField from '@/components/form/InputField.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import getErrorCode from '@/api/get-error-code';
  import { IMAP_FOLDER_EXISTS } from '@/api/error-codes';
  import { AxiosError } from 'axios';
  import Icon from '@/components/Icon/Icon.vue';

  export default Vue.extend({
    components: {
      Icon,
      InputField,
      Modal,
      ModalConfirmActions,
      MoveToFolderModal,
    },
    mixins: [asyncActionsMixin, folderUtilsMixin],
    props: {
      folder: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        newName: '',
      };
    },
    computed: {
      ...mapGetters(['folderById']),
      renameText(): string {
        return this.$gettext('Rename');
      },
      moveText(): string {
        return this.$gettext('Move');
      },
      deleteText(): string {
        return this.$gettext('Delete');
      },
      deleteFolderText(): string {
        return this.$gettextInterpolate(
          this.$gettext('Delete folder %{ folderName }'),
          { folderName: this.folder.display_name },
          true // disableHtmlEscaping (treated as text in template)
        );
      },
      newFolderNameText(): string {
        return this.$gettext('New folder name');
      },
      invalidFolderNameMessage(): string {
        return this.$gettext(
          'Folder names may not contain the following characters: \\ / ~ " .'
        );
      },
      invalidFolderName(): boolean {
        return /[./~"\\]/.test(this.newName);
      },
      newNameFilledIn(): boolean {
        return this.newName !== '';
      },
    },
    methods: {
      ...mapActions(['deleteFolder', 'patchFolder', 'setToastMessage']),
      moveFolder: asyncAction(
        'moving',
        function (
          this: any,
          folderToMoveId: string,
          folderId: string,
          modalScope: any
        ) {
          return this.patchFolder({
            id: folderToMoveId,
            properties: { parent_id: folderId },
          })
            .then((folder: Folder) => {
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext(
                    '%{ folderName } has been moved to %{ parentName }'
                  ),
                  {
                    folderName: this.getFolderName(folder),
                    parentName: folder.parent_id
                      ? this.getFolderName(this.folderById(folder.parent_id))
                      : this.$gettext('main folder'),
                  }
                ),
              });
              this.resetModal(modalScope);
            })
            .catch((err: AxiosError) =>
              this.displayErrorMessage(
                err,
                this.$gettext('Could not move folder')
              )
            );
        }
      ),
      renameFolderClicked: asyncAction(
        'renaming',
        function (this: any, folder: Folder, modalScope: any) {
          if (this.invalidFolderName) {
            return;
          }

          return this.patchFolder({
            id: folder.id,
            properties: { display_name: this.newName },
          })
            .then((newFolder: Folder) => {
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext(
                    '%{ oldName } has been renamed to %{ newName }'
                  ),
                  {
                    oldName: this.getFolderName(folder),
                    newName: this.getFolderName(newFolder),
                  }
                ),
              });
              this.resetModal(modalScope);
            })
            .catch((err: AxiosError) =>
              this.displayErrorMessage(
                err,
                this.$gettext('Could not rename folder')
              )
            );
        }
      ),
      removeFolderClicked: asyncAction(
        'removing',
        function (this: any, folder: Folder, modalScope: any) {
          return this.deleteFolder({ folder })
            .then(() => {
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext(
                    '%{ folderName } has been deleted and messages moved to Trash'
                  ),
                  {
                    folderName: this.getFolderName(folder),
                  }
                ),
              });
              this.resetModal(modalScope);
            })
            .catch((err: AxiosError) =>
              this.displayErrorMessage(
                err,
                this.$gettext('Could not delete folder')
              )
            );
        }
      ),
      resetModal(modalScope: any) {
        modalScope.toggle();
        this.newName = '';
      },
      showRenameModal(folder: Folder, modalScope: any) {
        this.newName = folder.display_name;
        modalScope.toggle();
      },
      displayErrorMessage(err: AxiosError, defaultMessage: string) {
        switch (getErrorCode(err)) {
          case IMAP_FOLDER_EXISTS:
            this.setToastMessage({
              message: this.$gettext('A folder with that name already exists'),
            });
            break;
          default:
            this.setToastMessage({ message: defaultMessage });
            throw err;
        }
      },
    },
  });
