<script>
  import api from '@/api';
  import getErrorCode from '@/api/get-error-code';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { mapGetters } from 'vuex';
  import { getChargebee } from '@/lib/chargebee';

  export default {
    name: 'Chargebee',
    mixins: [asyncActionsMixin],
    props: {
      matomoEventCategory: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        chargebeeInstance: null,
        initializationError: null,
      };
    },
    computed: {
      ...mapGetters('features', ['chargebeeSite']),
    },
    created() {
      if (window.Chargebee) {
        this.chargebeeInstance = window.Chargebee.getInstance();
        this.$emit('initialized');
      } else {
        this.initialize();
      }
    },
    methods: {
      initialize: asyncAction('initializing', async function () {
        try {
          this.chargebeeInstance = await getChargebee(this.chargebeeSite);
          this.$emit('initialized');
        } catch (err) {
          this.initializationError = err;
          throw err;
        }
      }),
      openCheckout() {
        this.openHostedPage(api.chargebee.checkout);
      },
      managePaymentSources() {
        this.openHostedPage(api.chargebee.managePaymentSources);
      },
      openHostedPage(hostedPage) {
        const track = (action) => {
          // Goal tracking can only use 1 event condition, so we construct a
          // specific name to match on.
          this.$matomoTrackEvent(
            this.matomoEventCategory,
            action,
            `${this.matomoEventCategory}-${action}`
          );
        };
        track('checkout-open');
        this.chargebeeInstance.openCheckout({
          hostedPage,
          success: (hostedPageId) => {
            this.$emit('checkout-success', hostedPageId);
            track('checkout-success');
          },
          error: (error) => {
            const errorCode = getErrorCode(error);
            this.$emit('checkout-error', errorCode);
            track('checkout-error');
          },
          step: (currentStep) => {
            track(`checkout-step-${currentStep}`);
          },
          close: () => {
            this.$emit('checkout-close');
            track('checkout-close');
          },
        });
      },
    },
    render() {
      return this.$scopedSlots.default({
        isInitializing: this.isBusy.initializing,
        initializationError: this.initializationError,
        managePaymentSources: this.managePaymentSources,
        openCheckout: this.openCheckout,
        openCheckoutTrial: this.openCheckoutTrial,
      });
    },
  };
</script>
