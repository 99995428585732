
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import LoadingButton from '@/components/LoadingButton.vue';
  import { getDateFormat } from '@/lib/dateTimeFormats';
  import formatCurrency from '@/lib/formatCurrency';
  import { mixin as timeMixin } from '@/lib/time';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import DefaultDialog from '../DefaultDialog.vue';
  import PasswordField from '../PasswordField/PasswordField.vue';
  import TrialStatusCelebrate from './TrialStatusCelebrate.vue';

  export default Vue.extend({
    components: {
      DefaultDialog,
      LoadingButton,
      PasswordField,
      TrialStatusCelebrate,
    },
    mixins: [timeMixin],
    props: {
      subscription: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        authenticationError: '',
        endingTrial: false,
        extendingTrial: false,
        partyParty: false,
        password: '',
        validate: false,
      };
    },
    watch: {
      password() {
        this.authenticationError = '';
        this.validate = false;
      },
    },
    computed: {
      freeTrialHtml(): string {
        return this.$gettextInterpolate(
          this.$gettext(
            'Your subscription is currently in a <strong>free trial period</strong> and will automatically start on <strong>%{ trial_end }</strong>. During the trial, certain features – such as adding additional accounts – are not available. To unlock all features, start your paid subscription now.'
          ),
          { trial_end: this.formattedDate(this.subscription.trial_end) }
        );
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      formatCurrency,
      formattedDate(date: string) {
        const dateFormat = getDateFormat(
          this.$store.state.authentication.user.preferences.date_format
        );
        return (this as any).formatDate(date, dateFormat);
      },
      async extendTrial() {
        this.extendingTrial = true;
        try {
          await api.chargebee.extendTrial();
          this.partyParty = true;
        } catch {
          this.setToastMessage({
            message: this.$gettext('Could not extend trial'),
          });
        } finally {
          this.extendingTrial = false;
          this.$emit('change');
        }
      },
      async endTrial(event: Event, close: Function) {
        this.endingTrial = true;
        try {
          await api.chargebee.endTrial({ password: this.password });
          close();
          this.$emit('change');
        } catch (err) {
          this.validate = true;
          if (
            err instanceof AxiosError &&
            getErrorCode(err) === AUTHENTICATION_FAILURE
          ) {
            this.authenticationError = this.$gettext('Wrong password.');
          } else {
            this.setToastMessage({
              message: this.$gettext('Could not end trial'),
            });
            close();
            this.$emit('change');
          }
        } finally {
          this.endingTrial = false;
        }
      },
    },
  });
