import Vue from 'vue';
import { mapActions } from 'vuex';

const RELOAD_WARNING_BEFOREUNLOAD_ID = 'RELOAD_WARNING_BEFOREUNLOAD_ID';

export default Vue.extend({
  created() {
    // Add function to beforeunload store module in order to removeEventListener in other places too
    this.addBeforeunloadFunction({
      [RELOAD_WARNING_BEFOREUNLOAD_ID]: this.handleClosingTab,
    });

    window.addEventListener('beforeunload', this.handleClosingTab);
  },
  beforeDestroy() {
    // When removing event listener here, no need to keep it in the beforeunload store module
    this.removeBeforeunloadFunction(RELOAD_WARNING_BEFOREUNLOAD_ID);
    window.removeEventListener('beforeunload', this.handleClosingTab);
  },
  methods: {
    ...mapActions(['removeBeforeunloadFunction', 'addBeforeunloadFunction']),
    handleClosingTab(e: BeforeUnloadEvent) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = '';
    },
  },
});
