
  import api from '@/api';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import InputField from '@/components/form/InputField.vue';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    components: { Icon, Modal, InputField, ModalConfirmActions },
    data() {
      return {
        domainName: '',
        error: '',
      };
    },
    props: {
      appHeader: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('authentication', ['canAddDomain']),
      showButton(): boolean {
        if (!this.$store.getters['authentication/isManager']) return false;

        // The button in the app header should not show if the user
        // has no domains b/c in-product purchases should be very
        // explicit. (The page itself shows an explanation, pricing,
        // and the ‘add’ button in that case.)
        if (this.appHeader && !this.$store.getters['authentication/hasDomains'])
          return false;

        return true;
      },
      label(): string {
        return this.$gettext('Domain name');
      },
      addButtonText(): string {
        return this.$gettext('Add');
      },
      placeholderText(): string {
        return this.$gettext('e.g. example.org');
      },
    },
    watch: {
      domainName() {
        this.error = '';
      },
    },
    methods: {
      reset() {
        this.domainName = '';
        this.error = '';
      },
      async addDomain(setLoading: Function, toggle: Function) {
        const alreadyAdded = this.$store.state.authentication.user.domains.some(
          (d: Domain) => d.domain_name === this.domainName.toLowerCase()
        );
        if (alreadyAdded) {
          toggle();
          return;
        }

        setLoading(true);
        try {
          await api.customDomains.create(this.domainName);
          toggle();
        } catch (err) {
          if (err instanceof AxiosError && err.response?.status === 409) {
            this.error = this.$gettext('Domain already claimed');
          } else {
            toggle();
            this.$store.dispatch('setToastMessage', {
              message: this.$gettext('Something went wrong. Try again later.'),
            });
            throw err;
          }
        } finally {
          setLoading(false);
        }
        this.$store.dispatch('authentication/getAuthenticationStatus');
      },
    },
  });
