import Vue from 'vue';
import { translate } from 'vue-gettext';
import { Module } from 'vuex';

export interface Toast {
  message: string;
  timeout?: number;
  timeoutId?: ReturnType<typeof setTimeout>;
  variant: 'error' | 'info' | 'success' | 'warning';
}

interface State {
  toast: Toast | undefined;
}

function defaultMessage({ variant }: { variant: string }) {
  switch (variant) {
    case 'error':
      return translate.gettext('Something went wrong. Please try again later.');
    default:
      return translate.gettext('Action completed');
  }
}

const toastModule: Module<State, any> = {
  namespaced: true,
  state(): State {
    return { toast: undefined };
  },
  actions: {
    // Note: all names are prefixed to make the API self-descriptive
    // when used via mapActions() in components.
    toastNotify(
      { commit, dispatch },
      options: Pick<Toast, 'message' | 'timeout' | 'variant'>
    ) {
      dispatch('toastCancelAutoClose');
      const variant = options.variant ?? 'info';
      const message = options.message ?? defaultMessage({ variant });

      // Warnings and errors stay visible a bit longer, and longer
      // messages also get extra reading time.
      const minTimeout = ['error', 'warning'].includes(variant) ? 5000 : 3000;
      const maxTimeout = 10000;
      let timeout, timeoutId;
      if (options.timeout !== Infinity) {
        timeout =
          options.timeout ??
          Math.max(minTimeout, Math.min(message.length * 50, maxTimeout));
        timeoutId = setTimeout(() => {
          dispatch('toastDismiss');
        }, timeout);
      }
      const toast: Toast = { message, timeout, timeoutId, variant };
      commit('setActive', toast);
    },
    toastNotifyError({ dispatch }, message?: string) {
      dispatch('toastNotify', { message, variant: 'error' });
    },
    toastNotifyInfo({ dispatch }, message?: string) {
      dispatch('toastNotify', { message, variant: 'info' });
    },
    toastNotifySuccess({ dispatch }, message?: string) {
      dispatch('toastNotify', { message, variant: 'success' });
    },
    toastNotifyWarning({ dispatch }, message?: string) {
      dispatch('toastNotify', { message, variant: 'warning' });
    },
    toastCancelAutoClose({ commit, state }) {
      const { toast } = state;
      if (!toast) return;
      if (toast.timeoutId) {
        clearTimeout(toast.timeoutId);
      }
      commit('clearTimeoutId');
    },
    toastDismiss({ commit, dispatch, state }) {
      const { toast } = state;
      if (!toast) return;
      dispatch('toastCancelAutoClose');
      commit('clearActive');
    },
  },
  mutations: {
    setActive(state: State, newToast: Toast) {
      state.toast = newToast;
    },
    clearTimeoutId(state) {
      const { toast } = state;
      if (!toast) return;
      Vue.delete(toast, 'timeoutId');
    },
    clearActive(state) {
      state.toast = undefined;
    },
  },
};
export default toastModule;

// Backwards compatibility API: single global action.
export const legacyToastModule: Module<{}, any> = {
  actions: {
    setToastMessage({ dispatch }, options: { message: string }) {
      dispatch('toast/toastNotify', { message: options.message });
    },
  },
};
