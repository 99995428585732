
  import api from '@/api';
  import {
    AUTHENTICATION_FAILURE,
    IMAP_TOKEN_LIMIT_REACHED,
  } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import completedIllustration from '@/assets/img/completed.svg';
  import errorIllustration from '@/assets/img/empty-state-error.svg';
  import envelopeIllustration from '@/assets/img/empty-state-filters.svg';
  import Icon from '@/components/Icon/Icon.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import ProgressSteps from '@/components/ProgressSteps/ProgressSteps.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import {
    SETTINGS_EMAIL_MIGRATION,
    SETTINGS_IMAP_TOKENS,
  } from '@/router/named-routes';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import SupportCenterLink from '../SupportCenterLink.vue';
  import { Location } from 'vue-router';

  const IMAP_IDENTIFIER = 'data-migration';
  const MIGRATION_STATUS = {
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
    AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
    REVIEW: 'REVIEW',
    QUOTA_ERROR: 'QUOTA_ERROR',
  };

  export default Vue.extend({
    data() {
      return {
        // controls what cards are shown
        activeStep: 0,
        // only controls what step in the progress steps is active
        activeProgressStep: 0,
        checkingStatus: false,
        loading: true,
        errorProgressStep: [] as number[],
        envelopeIllustration,
        completedIllustration,
        errorIllustration,
        password: '',
        authenticationError: '',
        creatingImap: false,
        maskCode: false,
        migrationAccessCode: '',
        migrationId: '',
        navigatedToMigrationPortal: false,
        migrationStatus: {
          type: '',
          status: '',
          total_failed: 0,
          total_imported: 0,
        } as MigrationStatus,
        MIGRATION_STATUS,
      };
    },
    components: {
      ProgressSteps,
      PasswordField,
      LoadingButton,
      Icon,
      Spinner,
      SupportCenterLink,
    },
    async created() {
      this.loadImapTokens();
      try {
        await this.checkStatus();
      } finally {
        this.loading = false;
      }
      api.uiEvents.create({
        event_type: this.$route.query.migration_id
          ? 'settings_migration_opened_with_migration_id'
          : 'settings_migration_opened',
      });
    },
    computed: {
      ...mapGetters('imapTokens', ['imapTokens']),
      ...mapGetters('authentication', ['user']),
      hasAnyFailed(): boolean {
        return Number(this.migrationStatus.total_failed) >= 0;
      },
      hasAnyCompleted(): boolean {
        return typeof this.migrationStatus.total_imported !== 'undefined';
      },
      migrationImapDeviceExists(): boolean {
        return (this as any).imapTokens.some(
          (token: ImapToken) => token.identifier === IMAP_IDENTIFIER
        );
      },
      imapSettingsRoute(): Location {
        return { name: SETTINGS_IMAP_TOKENS };
      },
      passwordLabel(): string {
        return this.$gettext('Please enter your StartMail password');
      },
      migrationErrorParagraph(): string {
        return this.migrationStatus.status === MIGRATION_STATUS.QUOTA_ERROR
          ? this.$gettext(
              'Unfortunately you’ve exceeded your StartMail account capacity. Please visit <a target="_blank" href="https://support.startmail.com/hc/en-us/articles/360007067938">this support article</a> to learn how to resolve this issue.'
            )
          : this.$gettext(
              'Unfortunately something went wrong during the migration process. Please try again or refer to the <a target="_blank" href="https://support.startmail.com/hc/en-us/articles/4593375732125">migration support article</a>.'
            );
      },
      removeImapDeviceHintText(): string {
        return this.$gettext(
          'If you still want to receive emails from your previous email account for 30 days, you should keep the IMAP connection you set up for the migration, otherwise a regular migration of new emails will not happen anymore.'
        );
      },
    },
    watch: {
      password() {
        this.authenticationError = '';
      },
    },
    methods: {
      ...mapActions('authentication', [
        'setEmailMigrationSetupCardAsDone',
        'updatePreferences',
      ]),
      ...mapActions(['setToastMessage']),
      ...mapActions('imapTokens', ['createImapToken', 'loadImapTokens']),
      startMigration() {
        this.activeStep += 1;
        api.uiEvents.create({
          event_type: 'settings_migration_clicked_start_migration',
        });
      },
      async addImapToken() {
        this.creatingImap = true;
        api.uiEvents.create({
          event_type: 'settings_migration_clicked_generate_access_code',
        });
        if (!this.password) {
          this.authenticationError = this.$gettext('Password is required.');
          this.creatingImap = false;
          return;
        }
        try {
          await this.updatePreferences({
            update: {
              imap_access: true,
            },
          });

          const imapToken = await this.createImapToken({
            identifier: IMAP_IDENTIFIER,
            password: this.password,
          });
          this.migrationAccessCode = imapToken.token;
          this.resetFormState();

          this.activeStep += 1;
        } catch (err: any) {
          switch (getErrorCode(err)) {
            case IMAP_TOKEN_LIMIT_REACHED:
              this.setToastMessage({
                message: this.$gettext(
                  'Unable to setup IMAP access for migration. You have reached the maximum number of IMAP devices.'
                ),
              });
              break;

            case AUTHENTICATION_FAILURE:
              this.authenticationError = this.$gettext('Wrong password.');
              this.$nextTick(() => {
                (this.$refs.password as any).focus();
              });
              break;

            default:
              this.setToastMessage({
                message: this.$gettext(
                  'Something went wrong. Please try again later.'
                ),
              });
              throw err;
          }
        } finally {
          this.creatingImap = false;
        }
      },
      toMigrationPortal() {
        window.open('https://migrate.startmail.com/', '_newtab');
        api.uiEvents.create({
          event_type: 'settings_migration_clicked_to_migration_portal',
        });
        this.navigatedToMigrationPortal = true;
        this.maskCode = true;
        this.setEmailMigrationSetupCardAsDone();
        this.activeProgressStep += 1;
      },
      async copyCode() {
        try {
          await navigator.clipboard.writeText(this.migrationAccessCode);
          this.setToastMessage({
            message: this.$gettext('Copied to clipboard'),
          });
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      async checkStatus() {
        this.checkingStatus = true;
        try {
          const queryMigrationId = this.$route.query.migration_id;
          const userPreferencesMigrationId = (this as any).user.preferences
            .migration_status_id;

          this.migrationId = queryMigrationId || userPreferencesMigrationId;

          if (!this.migrationId) return;

          if (
            queryMigrationId &&
            queryMigrationId !== userPreferencesMigrationId
          ) {
            await this.updatePreferences({
              update: {
                migration_status_id: queryMigrationId,
              },
            });
          }

          this.migrationStatus = await api.migrationStatus.get();
          switch (this.migrationStatus.status) {
            // Migration in progress
            case MIGRATION_STATUS.STARTED:
              this.activeProgressStep = 2;
              this.activeStep = 3;
              break;

            // Migration completed
            case MIGRATION_STATUS.COMPLETED:
              this.activeStep = 4;
              this.activeProgressStep = 3;
              break;

            // Migration error occurred
            case MIGRATION_STATUS.AUTHENTICATION_ERROR:
            case MIGRATION_STATUS.QUOTA_ERROR:
            case MIGRATION_STATUS.REVIEW:
              this.errorProgressStep = [3];
              this.activeStep = 5;
              this.activeProgressStep = 3;
              break;
          }
        } catch (err: any) {
          this.errorProgressStep = [3];
          this.activeStep = 5;
          this.activeProgressStep = 3;
          if (err.response?.status === 404) {
            this.setToastMessage({
              message: this.$gettext('Unknown migration'),
            });
            return;
          }
          this.setToastMessage({
            message: this.$gettext(
              'Unable to retrieve and process migration status'
            ),
          });
          throw err;
        } finally {
          this.checkingStatus = false;
        }
      },
      async resetMigration() {
        await this.updatePreferences({
          update: {
            migration_status_id: '',
          },
        });
        this.$router.push({ name: SETTINGS_EMAIL_MIGRATION });

        this.resetFormState();
        this.activeStep = 0;
        this.activeProgressStep = 0;
        this.errorProgressStep = [];
        this.migrationStatus = {
          status: '',
          type: '',
          total_imported: 0,
          total_failed: 1,
        };
      },
      resetFormState() {
        this.password = '';
        this.authenticationError = '';
      },
    },
  });
