<template>
  <div>
    <section class="panel">
      <h2 class="panel__title" v-test:notificationAndWarnings v-translate>
        Notifications and warnings
      </h2>
      <div class="panel__section">
        <FlipSwitch
          class="panel__section-item"
          id="recipientCountWarning"
          v-model="recipientCountWarning"
          v-test:recipientCountWarning
        >
          <translate>
            Always show a notification to move recipients to Bcc when there are
            more than 25 recipients
          </translate>
        </FlipSwitch>
        <FlipSwitch
          class="panel__section-item"
          id="externalLinkWarning"
          v-model="externalLinkWarning"
          v-test:externalLinkWarning
        >
          <translate>Always warn me when clicking an external link</translate>
        </FlipSwitch>
      </div>
    </section>

    <feature-toggle :id="FEATURE_AUTO_REPLY">
      <section class="panel">
        <h2 class="panel__title" v-translate>Auto-reply</h2>
        <div class="panel__section">
          <AutoReplySettings />
        </div>
      </section>
    </feature-toggle>

    <section class="panel">
      <h2 class="panel__title" v-test:composeMessage v-translate>
        Compose message
      </h2>
      <div class="panel__section">
        <div class="panel__section-item">
          <h3 class="panel__section-title" v-test:signature v-translate>
            Signature
          </h3>
          <SignatureManagement />
        </div>
        <div class="panel__section-item">
          <h3
            class="panel__section-title"
            v-test:defaultTextStyling
            v-translate
          >
            Default text styling
          </h3>
          <DefaultTextSettings v-test:defaultTextSettings />
        </div>
      </div>
    </section>

    <RecipientListSettings v-test:recipientListSettings />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import AutoReplySettings from '@/components/AutoReplySettings.vue';
  import DefaultTextSettings from '@/components/DefaultTextSettings/DefaultTextSettings';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import RecipientListSettings from '@/components/RecipientListSettings/RecipientListSettings.vue';
  import SignatureManagement from '@/components/SignatureManagement/SignatureManagement';
  import { FEATURE_AUTO_REPLY } from '@/lib/featureFlags';

  export default {
    components: {
      AutoReplySettings,
      DefaultTextSettings,
      FlipSwitch,
      RecipientListSettings,
      SignatureManagement,
    },
    data() {
      return {
        FEATURE_AUTO_REPLY,
      };
    },
    computed: {
      ...mapState('authentication', ['user']),
      externalLinkWarning: {
        get() {
          return this.user.preferences.external_link_warning === true;
        },
        set(externalLinkWarning) {
          this.setExternalLinkWarning({ externalLinkWarning });
        },
      },
      recipientCountWarning: {
        get() {
          return this.user.preferences.recipient_count_warning === true;
        },
        set(recipientCountWarning) {
          this.setRecipientCountWarning({ recipientCountWarning });
        },
      },
    },
    methods: {
      ...mapActions(['setExternalLinkWarning', 'setRecipientCountWarning']),
    },
  };
</script>
