var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"messages__header",class:{
    'messages__header--setup-guide-maximized':
      !_vm.isSetupGuideCollapsed && _vm.isSetupGuideVisible,
    'messages__header--setup-guide-collapsed':
      _vm.isSetupGuideCollapsed && _vm.isSetupGuideVisible,
    'messages__header--no-setup-guide': !_vm.isSetupGuideVisible,
  }},[_c('AppHeader',{attrs:{"show-navigation":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]},proxy:true},{key:"button",fn:function(){return [_c('div',{staticClass:"app-header__actions"},[(_vm.showDeleteAllButton)?_c('DeleteAll'):_c('router-link',{directives:[{name:"test",rawName:"v-test:composeButton",arg:"composeButton"}],staticClass:"button button--primary button--icon-only-small-breakpoint app-header__action",attrs:{"to":_vm.composeRoute}},[_c('translate',[_vm._v("Compose")]),_c('Icon',{attrs:{"symbol":"pen"}})],1)],1),_c('MailSearchForm')]},proxy:true}])}),(_vm.isSetupGuideVisible)?_c('SetupGuide'):_vm._e(),_c('MessagesActionsHeader'),_c('SetupGuideCompletedModal',{ref:"setupGuideCompletedModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }