
  import Vue from 'vue';

  export default Vue.extend({
    computed: {
      tooltip(): object {
        const el = document.createElement('div');
        el.innerHTML = this.$gettext(
          'This is an official email from StartMail. <a href="https://www.startmail.com/introducing-official-badge/">Learn more</a>'
        );
        const link = el.getElementsByTagName('a')[0];
        link.target = '_blank';
        link.relList.add('noopener', 'noreferrer');
        return {
          content: el.innerHTML,
          delay: { hide: 1000 }, // long enough to reach embedded hyperlink
          html: true,
          popperTriggers: ['hover'],
        };
      },
    },
  });
