<template>
  <div class="sidebar" v-test:sidebar>
    <transition name="sidebar-slide">
      <nav
        class="sidebar__menu"
        :class="{ 'sidebar__menu--open': isOpen }"
        v-test:sidebar
      >
        <header class="sidebar__header">
          <button
            type="button"
            class="button button--clean sidebar__header-close"
            @click="$emit('toggleSidebar')"
            :aria-label="closeMenuText"
            :title="closeMenuText"
          >
            <Icon symbol="remove" />
          </button>

          <router-link
            v-if="mailApp"
            :to="mailRoute"
            class="sidebar__header-link"
            :title="mailTitleText"
          >
            <Logo />
          </router-link>
          <Logo v-else />

          <button
            v-if="mailApp"
            class="sidebar__logout button button--clean"
            @click="onClickLogout"
            type="button"
            v-test:logoutButton
          >
            <span>
              {{ logoutText }}
            </span>
          </button>
        </header>

        <div class="sidebar__main" v-test:sidebar>
          <slot name="navigation" />
          <slot name="main" :isOpen="isOpen" />
          <button
            type="button"
            v-if="mailApp"
            class="button button--primary sidebar__close"
            @click="$emit('toggleSidebar')"
            :aria-label="collapseExpandText"
            v-tooltip="{ content: collapseExpandText, placement: 'right' }"
          >
            <Icon symbol="chevron" />
          </button>
        </div>

        <footer class="sidebar__footer">
          <slot name="footer" :isOpen="isOpen" />
          <template v-if="mailApp">
            <div
              v-if="showStorageBar && isOpen"
              class="sidebar__storage-indicator"
            >
              <StorageBar class="sidebar__storage-indicator__bar" />
              <router-link
                class="sidebar__storage-indicator__label"
                :to="settingsRoute"
                v-translate
                >Manage storage</router-link
              >
            </div>

            <FeedbackModal
              class="sidebar__footer--feedback-modal"
              v-if="showFeedbackButton"
            >
              <template #toggle="{ toggle }">
                <button
                  @click="toggle"
                  class="sidebar__footer-button button sidebar-item__link sidebar-item__link--is-alternative"
                >
                  <Icon symbol="feedback" :title="feedbackText" />
                  <transition name="fade">
                    <span v-if="isOpen">{{ feedbackText }}</span>
                  </transition>
                </button>
              </template>
            </FeedbackModal>
          </template>
        </footer>
      </nav>
    </transition>

    <transition name="fade">
      <div
        class="sidebar__background"
        v-show="isOpen"
        @click="$emit('toggleSidebar')"
      />
    </transition>
  </div>
</template>

<script>
  import { MAIL, SETTINGS_ACCOUNT } from '@/router/named-routes';
  import Icon from '@/components/Icon/Icon';
  import Logo from '@/components/Logo/Logo.vue';
  import StorageBar from '@/components/StorageBar/StorageBar.vue';
  import FeedbackModal from '@/components/FeedbackModal/FeedbackModal.vue';

  /* This component is shared between Mail app and PPM app.
   * PPM app does not have access to Mail state
   * So Mail app state getters, actions should not be included in this component.
   * For getters, they can be passed as prop from SidebarWrapper.js
   * For actions, SidebarWrapper.js can handle the events emitted by this component. */

  export default {
    components: {
      Icon,
      Logo,
      StorageBar,
      FeedbackModal,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      mailApp: {
        // Used to switch elements between MAIL/PPM
        type: Boolean,
        required: false,
      },
      showStorageBar: {
        type: Boolean,
        default: false,
      },
      showFeedbackButton: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      mailRoute() {
        return { name: MAIL };
      },
      mailTitleText() {
        return this.$gettext('Go to Inbox');
      },
      closeMenuText() {
        return this.$gettext('Close menu');
      },
      collapseExpandText() {
        return this.isOpen ? this.$gettext('Collapse') : this.$gettext('Open');
      },
      logoutText() {
        return this.$gettext('Log out');
      },
      settingsRoute() {
        return { name: SETTINGS_ACCOUNT };
      },
      feedbackText() {
        return this.$gettext('Give us feedback');
      },
    },
    methods: {
      async onClickLogout() {
        this.$emit('logout');
      },
    },
  };
</script>

<style src="../SidebarItem/SidebarItem.scss" lang="scss"></style>
<style src="./Sidebar.scss" lang="scss"></style>
