
  import Vue from 'vue';
  import GroupSubscriptionDeleteAccountModal from '@/components/GroupSubscription/GroupSubscriptionDeleteAccountModal.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccounts',
    components: { GroupSubscriptionDeleteAccountModal },
    computed: {
      myEmail(): string {
        return this.$store.state.authentication.user.email;
      },
      accounts(): AccountDetails[] {
        return this.$store.state.subscription.groupSubscription?.accounts.toSorted(
          (a: AccountDetails, b: AccountDetails) => {
            if (a.email === this.myEmail) {
              return -1;
            }
            if (b.email === this.myEmail) {
              return 1;
            }
            if (a.is_manager && !b.is_manager) {
              return -1;
            }
            if (!a.is_manager && b.is_manager) {
              return 1;
            }
            return a.email.localeCompare(b.email);
          }
        );
      },
    },
  });
