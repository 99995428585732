
  import Vue from 'vue';
  import SidebarItem from '@/components/SidebarItem/SidebarItem.vue';
  import * as namedRoutes from '@/router/named-routes';
  import Sidebar from '@/wrappers/SidebarWrapper/SidebarWrapper';

  export default Vue.extend({
    name: 'AliasesSidebar',
    components: {
      SidebarItem,
      Sidebar,
    },
    computed: {
      showDomainAliases(): boolean {
        // If you receive anything on any custom domain, you can see it.
        if (this.$store.getters['aliases/domainAliases'].length > 0)
          return true;

        return (
          this.$store.state.authentication.user.is_manager &&
          this.$store.getters['authentication/verifiedDomains'].length > 0
        );
      },
      navigationItems(): any[] {
        const items = [
          {
            id: 'aliases-personal',
            name: this.$gettext('Personal aliases'),
            icon: 'masks',
            route: { name: namedRoutes.ALIASES_PERSONAL },
          },
          {
            id: 'aliases-burner',
            name: this.$gettext('Burner alias'),
            icon: 'burner',
            route: { name: namedRoutes.ALIASES_BURNER },
          },
        ];
        if (this.showDomainAliases)
          items.push({
            id: 'aliases-domain',
            name: this.$gettext('Domain aliases'),
            icon: 'group-alias',
            route: { name: namedRoutes.ALIASES_DOMAIN },
          });
        return items;
      },
    },
  });
