<template>
  <div class="layout-dropdown">
    <Dropdown
      :class="{ 'dropdown--align-left': layoutSplitState === SPLIT_VERTICAL }"
    >
      <template #button="{ isOpen, toggle }">
        <ActionBarButton
          class="layout-dropdown__button button--auto-hide-label button--small"
          :active="isOpen"
          :dropdown="true"
          icon="layout"
          :title="changeLayoutText"
          :label="changeLayoutText"
          @click="toggle"
          v-test:layoutButton
        />
      </template>
      <template #content="{ toggle }">
        <h4 class="layout-dropdown__heading"><translate>Density</translate></h4>
        <ContextSwitcher
          @change="toggleDensity"
          :options="densityOptions"
          :active="density"
          class="layout-dropdown__density-selector"
        />
        <template v-if="showSplitOptions">
          <h4 class="layout-dropdown__heading">
            <translate>Layout</translate>
          </h4>
          <RadioInput
            class="layout-dropdown__split-selector link-list"
            :options="layoutOptions"
            option-class="link-list__item"
            id="layout"
            v-model="layout"
            @input="toggle"
          />
        </template>
      </template>
    </Dropdown>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  import ActionBarButton from '@/components/ActionBarButton';
  import ContextSwitcher from '@/components/ContextSwitcher/ContextSwitcher';
  import Dropdown from '@/components/Dropdown/Dropdown';
  import RadioInput from '@/components/form/RadioInput';

  import { getItem } from '@/lib/localStorage';

  import {
    DENSITY_DEFAULT,
    DENSITY_COMPACT,
    DENSITY_OPTIONS,
    SPLIT_NONE,
    SPLIT_HORIZONTAL,
    SPLIT_VERTICAL,
  } from '@/store/layout/layout';

  export default {
    name: 'LayoutDropdown',
    components: {
      ActionBarButton,
      Dropdown,
      ContextSwitcher,
      RadioInput,
    },
    props: {
      showSplitOptions: {
        type: Boolean,
        default: true,
        required: false,
      },
    },
    data() {
      return {
        SPLIT_VERTICAL,
      };
    },
    computed: {
      ...mapState({
        layoutSplitState: (state) => state.layout.activeSplit,
        densityState: (state) => state.layout.density,
      }),
      densityOptions() {
        return Array.from(DENSITY_OPTIONS.values()).map(({ value }) => ({
          text: this.translateDensityOption(value),
          value,
        }));
      },
      density: {
        get() {
          return this.densityState;
        },
        set(density) {
          this.setDensity({ density });
        },
      },
      layoutOptions() {
        return [SPLIT_NONE, SPLIT_HORIZONTAL, SPLIT_VERTICAL].map((value) => ({
          name: this.translateLayoutOption(value),
          value,
          className: `message-list__layout-${value
            .toLowerCase()
            .replace(/\s/g, '-')}`,
        }));
      },
      layout: {
        get() {
          return this.layoutSplitState;
        },
        set(split) {
          this.setLayoutSplit({ layoutSplit: split });
        },
      },
      changeLayoutText() {
        return this.$gettext('Change layout');
      },
    },
    async created() {
      const layoutSplit = await getItem('sm-layout-split');
      if (layoutSplit) {
        this.setLayoutSplit({ layoutSplit });
      }

      const density = await getItem('sm-layout-density');
      if (density) {
        this.setDensity({ density });
      }
    },
    methods: {
      ...mapActions('layout', ['setDensity', 'setLayoutSplit']),
      toggleDensity(density) {
        this.setDensity({ density });
      },
      translateLayoutOption(option) {
        switch (option) {
          case SPLIT_VERTICAL:
            return this.$gettext('Vertical split');

          case SPLIT_HORIZONTAL:
            return this.$gettext('Horizontal split');

          case SPLIT_NONE:
            return this.$gettext('No split');
        }
      },
      translateDensityOption(value) {
        switch (value) {
          case DENSITY_DEFAULT:
            return this.$gettext('Default');
          case DENSITY_COMPACT:
            return this.$gettext('Compact');
        }
      },
    },
  };
</script>

<style src="./LayoutDropdown.scss" lang="scss"></style>
