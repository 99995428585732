
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Checkbox from '@/components/form/Checkbox.vue';
  import Vue, { PropType } from 'vue';
  import Icon from '@/components/Icon/Icon.vue';
  import { mapActions, mapGetters } from 'vuex';
  import formatLongNumber from '@/lib/formatLongNumber';
  import LoadingButton from '@/components/LoadingButton.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import { SIGNUP_SUPPORT, SIGNUP_FEEDBACK } from '@/router/named-routes';
  import api from '@/api';
  import reloadWarningMixin from '@/mixins/reload-warning-mixin';
  import SupportCenterLink from '@/components/SupportCenterLink.vue';
  import { LOCAL_STORAGE_KEY } from '@/components/UsernameInput.vue';

  export default Vue.extend({
    name: 'RecoveryCode',
    components: {
      AuthenticationLayout,
      Checkbox,
      Icon,
      LoadingButton,
      Spinner,
      SupportCenterLink,
    },
    mixins: [reloadWarningMixin],
    props: {
      attribution: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
      billingPeriod: {
        type: String,
        required: true,
      },
      country: {
        type: String,
        required: true,
      },
      couponCode: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
      currency: {
        type: String,
        required: true,
      },
      verificationSessionToken: {
        type: String,
        required: false,
        default: undefined,
      },
      password: {
        type: String,
        required: true,
      },
      paypalToken: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
      planType: {
        type: String,
        required: true,
      },
      username: {
        type: String,
        required: true,
      },
      setupIntent: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
    },
    data() {
      return {
        creationRequest: null as Promise<AuthenticationResponseData> | null,
        signedToken: null as SignedData | null,
        submitting: false,
        supportsPrinting: !!window.print,
      };
    },
    computed: {
      ...mapGetters('features', ['featureById']),
      recoveryCode(): null | string {
        if (!this.signedToken) return null;
        return JSON.parse(this.signedToken.data).recovery_code;
      },
      formattedRecoveryCode(): string {
        return this.recoveryCode
          ? formatLongNumber(this.recoveryCode).toUpperCase()
          : '';
      },
      checkboxLabel(): string {
        return this.$gettext('I saved my recovery code');
      },
      backgroundImage(): string {
        return require('@/assets/img/lock-illustration.svg');
      },
      failedActionMessage(): string {
        return this.$gettext(
          'Your account could not be created and the one-time recovery code is no longer valid.'
        );
      },
    },
    async created() {
      this.$matomoTrackEvent('signup', 'recovery-code', 'signup-recovery-code');
      await this.generateRecoveryCode();
      await this.createAccount();
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['processAuthentication']),
      async generateRecoveryCode() {
        try {
          this.signedToken = await api.signup.recoveryCode(this.username);
        } catch (error: any) {
          this.$router.push({
            name: SIGNUP_SUPPORT,
            params: { message: this.failedActionMessage },
          });
          throw error;
        }
      },
      async copyRecoveryCode() {
        try {
          await navigator.clipboard.writeText(this.formattedRecoveryCode);
          this.setToastMessage({
            message: this.$gettext('Copied to clipboard'),
          });
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      printRecoveryCode() {
        window.print();
      },
      async onFormSubmit() {
        this.submitting = true;
        await this.creationRequest;
        this.showNextPage();
      },
      async createAccount() {
        if (!this.signedToken) {
          return;
        }
        const requestBody: SignupRequest = {
          billingPeriod: this.billingPeriod,
          country: this.country,
          currency: this.currency,
          locale: this.$language.current,
          password: this.password,
          planType: this.planType,
          recoveryCode: this.signedToken,
          username: this.username,

          ...(this.attribution && { attribution: this.attribution }),
          ...(this.couponCode && { couponCode: this.couponCode }),
          ...(this.verificationSessionToken && {
            verificationSessionToken: this.verificationSessionToken,
          }),
          ...(this.paypalToken && { paypalToken: this.paypalToken }),
          ...(this.setupIntent && { setupIntent: this.setupIntent }),
        };

        const createAccount = async () => {
          try {
            const response = await api.signup.post(requestBody);
            await this.processAuthentication(response);
            this.$matomoTrackEvent('signup', 'complete', 'signup-complete');
            return response;
          } catch (error: any) {
            await this.$router.push({
              name: SIGNUP_SUPPORT,
              params: { message: this.failedActionMessage },
            });
            throw error;
          } finally {
            // bit of a strange location here, away from where is gets set in
            // UsernameInput.vue ... but we have to remove the (no longer
            // usable) domain verification session at some point and dont want
            // to do it earlier because that could prevent payment method setup
            // retries etc
            //
            // also, if there is an error here, we clear this as well because it
            // may cause the error
            localStorage.removeItem(LOCAL_STORAGE_KEY);
          }
        };
        this.creationRequest = createAccount();
        await this.creationRequest;
      },
      showNextPage(this: any) {
        this.$router.push({ name: SIGNUP_FEEDBACK });
      },
    },
  });
