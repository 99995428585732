
  import Vue from 'vue';
  import axios from 'axios';
  import debounce from '@/lib/debounce';
  import api from '@/api';
  import BetterInput from '@/components/form/BetterInput.vue';
  import Logo from '@/components/Logo/Logo.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import { countrySelectOptions } from '@/lib/countries';
  import * as Sentry from '@sentry/vue';
  import {
    loadStripe,
    SetupIntentResult,
    Stripe,
    StripeCardCvcElement,
    StripeCardExpiryElement,
    StripeCardNumberElement,
    StripeElementStyle,
  } from '@stripe/stripe-js';
  import { singleQueryValue } from '@/lib/url';
  import { mapActions, mapGetters } from 'vuex';
  import SelectInput from '@/components/form/SelectInput.vue';
  import Checkbox from '@/components/form/Checkbox.vue';
  import PayPalButton from '@/components/Signup/PayPalButton.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import nacl from 'tweetnacl';
  import naclutil from 'tweetnacl-util';
  import * as namedRoutes from '@/router/named-routes';
  import formatCurrency from '@/lib/formatCurrency';
  import { getChargebee } from '@/lib/chargebee';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import { substituteLinks } from '@/lib/hyperlink';
  import fontWoff from '@/assets/fonts/proximanova-regular-webfont.woff';
  import fontWoff2 from '@/assets/fonts/proximanova-regular-webfont.woff2';
  import UsernameInput from '@/components/UsernameInput.vue';
  import { FEATURE_IS_CANARY } from '@/lib/featureFlags';

  interface EncryptedData {
    ciphertext: string;
    nonce: string;
    key: string;
  }

  interface Estimate {
    // coupon code is ours
    couponCode?: string;
    // below is a subset of the chargebee response format
    next_invoice_estimate: {
      sub_total: number;
      total: number;
    };
  }

  interface SignupData {
    country: string;
    password: string;
    username: string;
    couponCode?: string;
    domainVerificationSession?: DomainVerificationSession;
  }

  function encrypt(data: SignupData): EncryptedData {
    const utf8Encoder = new TextEncoder();
    const key = nacl.randomBytes(nacl.secretbox.keyLength);
    const nonce = nacl.randomBytes(nacl.secretbox.nonceLength);
    const ciphertext = nacl.secretbox(
      utf8Encoder.encode(JSON.stringify(data)),
      nonce,
      key
    );
    return {
      ciphertext: naclutil.encodeBase64(ciphertext),
      nonce: naclutil.encodeBase64(nonce),
      key: encodeURIComponent(naclutil.encodeBase64(key)),
    };
  }

  function decrypt({
    ciphertext,
    nonce,
    key,
  }: EncryptedData): SignupData | null {
    const plaintext: Uint8Array | null = nacl.secretbox.open(
      naclutil.decodeBase64(ciphertext),
      naclutil.decodeBase64(nonce),
      naclutil.decodeBase64(decodeURIComponent(key))
    );
    if (plaintext === null) {
      return null;
    }
    const utf8Decoder = new TextDecoder();
    const {
      country,
      couponCode,
      domainVerificationSession,
      password,
      username,
    } = JSON.parse(utf8Decoder.decode(plaintext));

    return typeof country === 'string' &&
      typeof password === 'string' &&
      typeof username === 'string' &&
      (typeof couponCode === 'string' || couponCode === undefined) &&
      ((domainVerificationSession &&
        typeof domainVerificationSession === 'object' &&
        typeof domainVerificationSession.domain === 'string' &&
        typeof domainVerificationSession.session_token === 'string' &&
        typeof domainVerificationSession.verification_token === 'string') ||
        domainVerificationSession === undefined)
      ? { country, couponCode, domainVerificationSession, password, username }
      : null;
  }

  export default Vue.extend({
    components: {
      BetterInput,
      Checkbox,
      Logo,
      NotificationBlock,
      PasswordField,
      PayPalButton,
      Spinner,
      SelectInput,
      UsernameInput,
    },
    data() {
      return {
        /* user input */
        agreeToTerms: false,
        billingCountry: '',
        cardholder: '',
        couponCode: singleQueryValue(this.$route.query.deal),
        password: '',
        passwordRepeat: '',
        paymentMethod: null as 'card' | 'paypal' | 'none' | null,
        // this follows paymentMethod but gives us a chance to call
        // mount/unmount on the Stripe Elements and sync Vue with Stripe JS
        showCardFields: true,
        username: '',

        /* payment */
        estimate: null as Estimate | null,
        currency: undefined as 'eur' | 'usd' | undefined,
        stripe: null as Stripe | null,
        cardNumber: null as StripeCardNumberElement | null,
        cardExpiry: null as StripeCardExpiryElement | null,
        cardCvc: null as StripeCardCvcElement | null,
        stripeError: null as string | null,
        paypalError: false,

        /* submission and validation status */
        checkAddressAvailabilityPending: false,
        checkingPasswordScore: false,
        domainVerificationSession: undefined as
          | DomainVerificationSession
          | undefined,
        passwordScore: 0,
        processingSignupForm: false,
        usernameError: '',
      };
    },
    async mounted() {
      if (this.status) {
        await this.loadCurrency();
        await this.finishPaypalSignup();
      } else {
        this.$matomoTrackEvent('signup', 'start', 'signup-start');
        void this.loadCurrency();
      }
      this.loadStripe();
    },
    computed: {
      ...mapGetters('features', ['chargebeeSite', 'featureById']),
      chosenPlanText(): string {
        const plan =
          this.planType === 'personal'
            ? this.$pgettext('signing up for …', 'the personal plan')
            : this.$pgettext('signing up for …', 'the business plan');
        const message =
          this.paymentFrequency === 'monthly'
            ? this.$gettext('You are signing up for %{plan}, invoiced monthly.')
            : this.$gettext('You are signing up for %{plan}, invoiced yearly.');
        return this.$gettextInterpolate(message, { plan });
      },
      status(): string | undefined {
        return singleQueryValue(this.$route.query.status);
      },
      termsLinksHTML(): string {
        return substituteLinks(
          this.$gettext(
            'Please find our <a href=tos>Terms of Service</a> and <a href=privacy>Privacy Policy</a> on our website.'
          ),
          {
            hrefs: {
              tos: this.$gettext('https://www.startmail.com/terms-of-service'),
              privacy: this.$gettext('https://www.startmail.com/privacy/'),
            },
            external: true,
          }
        );
      },
      estimateInput(): any {
        return [
          this.billingCountry,
          this.chargebeeSite,
          this.couponCode,
          this.planId,
        ];
      },
      nextInvoiceZero(): boolean {
        return this.estimate?.next_invoice_estimate?.total === 0;
      },
      hasDiscount(): boolean | undefined {
        return this.estimate
          ? this.estimate.next_invoice_estimate.total <
              this.estimate.next_invoice_estimate.sub_total
          : undefined;
      },
      subTotal(): string | undefined {
        return this.currency && this.estimate
          ? formatCurrency(
              this.estimate.next_invoice_estimate.sub_total,
              this.currency
            )
          : undefined;
      },
      total(): string | undefined {
        return this.currency && this.estimate
          ? formatCurrency(
              this.estimate.next_invoice_estimate.total,
              this.currency
            )
          : undefined;
      },
      countries(): HTMLSelectOption[] {
        let lang = this.$route.query.lang;
        if (lang !== 'en' && lang !== 'de') {
          lang = 'en';
        }
        return countrySelectOptions(lang);
      },
      passwordsMatch(): boolean {
        return this.password === this.passwordRepeat;
      },
      passwordStrengthUserInputs(): string[] {
        const domainToken = this.domainVerificationSession?.verification_token;
        const inputs = [
          this.username,
          'startmail',
          'verification',
          'startmail-verification',
          ...(domainToken ? [domainToken] : []),
          // local-part and domain should not occur in password
          ...this.username.split('@').filter((s) => s),
        ];
        return Array.from(new Set(inputs)).sort(); // uniqueify
      },
      passwordConfirmationText(): string {
        return this.$gettext('Password confirmation');
      },
      passwordErrorMessage(): string {
        return this.$gettext("Passwords don't match");
      },
      planId(): string | undefined {
        return this.currency
          ? `${this.paymentFrequency}-${this.planType}-${this.currency}`
          : undefined;
      },
      paymentFrequency(): string {
        return this.$route.query.billing === 'monthly' ? 'monthly' : 'yearly';
      },
      planType(): string {
        return this.$route.query.plan === 'business' ? 'business' : 'personal';
      },
      trialInformationHtml(): string {
        return this.$gettext(
          // https://github.com/Polyconseil/vue-gettext/issues/75
          'All plans include a <strong>free 7-day trial</strong> and you can cancel or switch plans at any time.'
        );
      },
    },
    watch: {
      estimateInput() {
        if (this.planId) {
          this.refreshEstimate();
        }
      },
      username() {
        this.usernameError = '';
        this.checkAddressAvailabilityPending = true;
        this.debouncedCheckAddressAvailability();
      },
      password() {
        this.checkingPasswordScore = true;
      },
      paymentMethod() {
        this.stripeError = null;
        this.paypalError = false;

        if (this.paymentMethod === 'card') {
          // Let Vue mount the #card-number in the DOM before asking Stripe to
          // target these nodes which would not exist yet without the $nextTick.
          this.showCardFields = true;
          this.$nextTick(() => {
            this.cardNumber?.mount('#card-number');
            this.cardExpiry?.mount('#card-expiry');
            this.cardCvc?.mount('#card-cvc');
            this.cardNumber?.clear();
          });
        } else {
          // First do Stripe's unmount, then let Vue remove the container.
          this.cardNumber?.clear();
          this.cardNumber?.unmount();
          this.cardExpiry?.unmount();
          this.cardCvc?.unmount();
          this.showCardFields = false;
        }
      },
      processingSignupForm() {
        if (this.processingSignupForm) {
          this.stripeError = null;
          this.paypalError = false;
        }
        this.cardNumber?.update({ disabled: this.processingSignupForm });
        this.cardExpiry?.update({ disabled: this.processingSignupForm });
        this.cardCvc?.update({ disabled: this.processingSignupForm });
      },
      nextInvoiceZero() {
        // The "no payment" option is not valid if there is no 100% discount. So
        // if the user removes a 100% discount coupon, then we change the
        // payment method for them.
        if (this.nextInvoiceZero) {
          this.paymentMethod = 'none';
        }
        if (!this.nextInvoiceZero && this.paymentMethod == 'none') {
          this.paymentMethod = 'card';
        }
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      onChangeUsername({
        username,
        domainVerificationSession,
      }: {
        username: string;
        domainVerificationSession?: DomainVerificationSession;
      }) {
        this.username = username;
        this.domainVerificationSession = domainVerificationSession;
      },
      async loadCurrency() {
        // Precedence: URL ‘?currency=’ param, API response, fallback value.
        const validated = (value: any) =>
          ['eur', 'usd'].includes(value) ? (value as 'eur' | 'usd') : undefined;
        const fromUrl = validated(singleQueryValue(this.$route.query.currency));
        const loadFromApi = async () => {
          try {
            const { data } = await axios.get('/api/Currency');
            return validated(data.currency);
          } catch (err) {
            Sentry.captureException(err);
          }
        };
        this.currency = fromUrl ?? (await loadFromApi()) ?? 'usd';
      },
      async loadStripe() {
        const key = this.featureById(FEATURE_IS_CANARY)?.enabled
          ? process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST
          : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_LIVE;
        if (!key) {
          // switch to paypal and bail out, this will hide the credit card
          // payment option on the form
          this.paymentMethod = 'paypal';
          return;
        }
        this.stripe = await loadStripe(key);
        if (this.stripe === null) {
          // switch to paypal and bail out, this will hide the credit card
          // payment option on the form
          this.paymentMethod = 'paypal';
          return;
        }

        const elements = this.stripe.elements({
          locale: this.$language.current.startsWith('de') ? 'de' : 'en',
          fonts: [
            {
              family: 'Proxima Nova',
              src: [fontWoff2, fontWoff] // i.e. url(…) format(…), url(…) format(…)
                .map((path) => {
                  const format = path.split('.').pop();
                  return `url('${path}') format('${format}')`;
                })
                .join(', '),
            },
          ],
          mode: 'setup',
          payment_method_types: ['card'],
          setup_future_usage: 'off_session',
        });

        const style: StripeElementStyle = {
          base: {
            fontFamily: 'Proxima Nova, sans-serif',
            fontSize: '16px',
          },
        };
        this.cardNumber = elements.create('cardNumber', {
          showIcon: true,
          style,
        });
        this.cardExpiry = elements.create('cardExpiry', { style });
        this.cardCvc = elements.create('cardCvc', { style });

        // trigger watcher to mount the fields in the DOM, but only if we havent
        // already selected another payment method
        if (this.paymentMethod === null) {
          this.paymentMethod = 'card';
        }
      },
      async checkAddressAvailability() {
        this.checkAddressAvailabilityPending = true;
        try {
          await api.authentication.availableAddresses({
            requestedEmail: this.username,
          });
          this.usernameError = '';
        } catch (err) {
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              this.usernameError = this.$gettext(
                'This StartMail email address is not available.'
              );
            } else if (err.response?.status === 400) {
              this.usernameError = this.$gettext(
                'This is not a valid StartMail email address.'
              );
            } else {
              throw err;
            }
          } else {
            throw err;
          }
        } finally {
          this.checkAddressAvailabilityPending = false;
        }
      },
      debouncedCheckAddressAvailability: debounce(function (this: any) {
        return this.checkAddressAvailability();
      }, 300),
      onScoreChange({ passwordScore }: { passwordScore: number }) {
        this.passwordScore = passwordScore;
        this.checkingPasswordScore = false;
      },
      async refreshEstimate() {
        const cbInstance = await getChargebee(this.chargebeeSite);
        const payload: Record<string, any> = {
          subscription: { plan_id: this.planId },
        };
        if (this.billingCountry) {
          // for tax calculation, we are not currently showing this though
          payload.billing_address = { country: this.billingCountry };
        }

        const couponCodeCandidates: (string | undefined)[] = [];
        if (this.couponCode) {
          couponCodeCandidates.push(this.couponCode);
          if (!this.couponCode.match(/-(eur|usd|perc)$/)) {
            couponCodeCandidates.push(`${this.couponCode}-perc`);
            if (this.currency) {
              couponCodeCandidates.push(`${this.couponCode}-${this.currency}`);
            }
          }
        }
        couponCodeCandidates.push(undefined);

        for (const couponCode of couponCodeCandidates) {
          payload.coupon_ids = couponCode ? [couponCode] : undefined;
          try {
            const response =
              await cbInstance.estimates.createSubscriptionEstimate(payload);
            this.estimate = { couponCode, ...response.estimate };
            return;
          } catch {
            // eat errors and try the next coupon code candidate. note that
            // chargebee js api unfortunately doesn't provide usable error
            // information here
            continue;
          }
        }

        this.$router.push({ name: namedRoutes.SIGNUP_SUPPORT });
      },
      async onSubmit() {
        if (!(this.$refs.form as HTMLFormElement).reportValidity()) {
          // SUBTLE: Manually calling reportValidity is normally not needed. But
          // without manually calling it, hitting <ENTER> in the credit card
          // form fields bypasses the validation.
          return;
        }

        this.processingSignupForm = true;
        try {
          // check username availability
          await this.checkAddressAvailability();
          if (this.usernameError) {
            return;
          }
          // check password strength
          if (this.checkingPasswordScore || this.passwordScore < 3) {
            return;
          }

          if (
            this.paymentMethod === 'card' ||
            this.paymentMethod === 'paypal'
          ) {
            this.$matomoTrackEvent(
              'signup',
              'checkout-open',
              'signup-checkout-open'
            );
          }

          if (this.paymentMethod === 'card') {
            await this.signupWithCard();
          } else if (this.paymentMethod === 'paypal') {
            await this.signupWithPaypal();
          } else {
            await this.nextStep();
          }
        } finally {
          this.processingSignupForm = false;
        }
      },
      async signupWithCard(): Promise<void> {
        if (
          !this.stripe ||
          !this.cardNumber ||
          !this.cardExpiry ||
          !this.cardCvc ||
          !this.currency
        ) {
          this.setToastMessage({
            message: this.$gettext('Something went wrong'),
          });
          return;
        }

        let result: SetupIntentResult | undefined = undefined;

        try {
          const { data } = await axios.post('/api/Stripe/SetupIntent');
          if (!data.client_secret) {
            throw new Error('bad setup intent client secret received');
          }

          result = await this.stripe.confirmCardSetup(data.client_secret, {
            payment_method: {
              card: this.cardNumber,
              billing_details: {
                name: this.cardholder,
                address: { country: this.billingCountry },
              },
            },
          });
        } catch (error) {
          Sentry.captureException(error);
          this.setToastMessage({
            message: this.$gettext('Something went wrong'),
          });
          return;
        }

        if (result.error) {
          if (result.error.message) {
            this.stripeError = result.error.message;
          } else {
            this.setToastMessage({
              message: this.$gettext('Something went wrong'),
            });
          }
          return;
        }

        this.$matomoTrackEvent(
          'signup',
          'checkout-success',
          'signup-checkout-success'
        );

        await this.nextStep({ setupIntent: result.setupIntent.id });
      },
      async nextStep({
        paypalToken,
        setupIntent,
      }: {
        paypalToken?: string;
        setupIntent?: string;
      } = {}) {
        if (!this.currency) {
          return;
        }

        const attribution = singleQueryValue(this.$route.query.attribution);
        // when we come back from a paypal express checkout, we dont have an
        // estimate
        const couponCode = this.estimate?.couponCode ?? this.couponCode;
        return await this.$router.push({
          name: namedRoutes.SIGNUP_RECOVERY_CODE,
          params: {
            ...(attribution && { attribution }),
            billingPeriod: this.paymentFrequency,
            country: this.billingCountry,
            ...(couponCode && { couponCode }),
            currency: this.currency,
            password: this.password,
            planType: this.planType,
            username: this.username,
            ...(this.domainVerificationSession?.session_token && {
              verificationSessionToken:
                this.domainVerificationSession.session_token,
            }),
            ...(paypalToken && { paypalToken }),
            ...(setupIntent && { setupIntent }),
          },
        });
      },
      async signupWithPaypal(): Promise<void> {
        if (!this.currency) {
          return;
        }
        const signupData: SignupData = {
          country: this.billingCountry,
          couponCode: this.estimate?.couponCode ?? this.couponCode,
          password: this.password,
          username: this.username,
          domainVerificationSession: this.domainVerificationSession,
        };

        const { ciphertext, nonce, key } = encrypt(signupData);
        // maintain the entire url with all parameters like attribution and
        // plan, but override or drop PayPal-specific parameters and add an
        // explicit currency because this currency is used with paypal
        const return_url_base = new URL(window.location.href);
        return_url_base.searchParams.set('currency', this.currency);
        return_url_base.searchParams.set('key', key);
        return_url_base.searchParams.delete('status');
        return_url_base.searchParams.delete('token');
        try {
          const { data } = await axios.post('/api/PayPal/SetExpressCheckout', {
            currency: this.currency,
            return_url_base,
            username: this.username,
          });
          if (data.redirect_url) {
            sessionStorage.setItem('signup-data', ciphertext);
            sessionStorage.setItem('signup-nonce', nonce);
            window.location.assign(data.redirect_url);
          } else {
            throw new Error('bad or missing paypal redirect url');
          }
        } catch (error: any) {
          Sentry.captureException(error);
          this.setToastMessage({ message: 'Something went wrong' });
        }
      },
      async finishPaypalSignup() {
        this.paymentMethod = 'paypal';

        const ciphertext = sessionStorage.getItem('signup-data');
        const nonce = sessionStorage.getItem('signup-nonce');
        const key = singleQueryValue(this.$route.query.key);
        const paypalToken = singleQueryValue(this.$route.query.token);

        if (ciphertext && nonce && key) {
          const signupData = decrypt({ ciphertext, nonce, key });
          if (signupData) {
            ({
              username: this.username,
              password: this.password,
              password: this.passwordRepeat,
              country: this.billingCountry,
              couponCode: this.couponCode,
              domainVerificationSession: this.domainVerificationSession,
            } = signupData);
            if (this.status === 'return' && paypalToken) {
              this.$matomoTrackEvent(
                'signup',
                'checkout-success',
                'signup-checkout-success'
              );
              return await this.nextStep({ paypalToken });
            }
          }
        }

        // remove the paypal params from the URL, this has the side-effect of
        // making the form visible to the user due to the `v-if=status` on the
        // spinner
        this.$router.replace({
          query: {
            ...this.$route.query,
            key: undefined,
            status: undefined,
            token: undefined,
          },
        });
        this.paypalError = true;
        this.setToastMessage({ message: 'Something went wrong' });
      },
    },
  });
