
  import Vue from 'vue';
  import { Location, Route } from 'vue-router';
  import { mapGetters, mapActions, mapState } from 'vuex';
  import Checkbox from '@/components/form/Checkbox.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import OfficialBadge from '@/components/OfficialBadge.vue';
  import { mixin as timeMixin, isToday } from '@/lib/time';
  import moveSelectedMessagesMixin from '@/mixins/move-selected-messages-mixin';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import { MAIL_COMPOSE, MAIL_MESSAGE } from '@/router/named-routes';
  import { SPLIT_NONE } from '@/store/layout/layout';

  interface ResolvedRoute {
    location: Location;
    route: Route;
    href: string;
    normalizedTo: Location;
    resolved: Route;
  }

  export default Vue.extend({
    components: {
      Checkbox,
      Icon,
      OfficialBadge,
    },
    mixins: [timeMixin, moveSelectedMessagesMixin, folderUtilsMixin],
    props: {
      message: {
        type: Object,
        required: true,
      },
      email: {
        type: String,
        required: true,
      },
      showFolder: {
        type: Boolean,
        required: false,
      },
      tooltip: {
        type: String,
        required: true,
      },
      dateFormat: {
        type: String,
        required: true,
      },
      timeFormat: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters(['activeFolder', 'selectedMessages', 'folderById']),
      ...mapGetters('layout', ['isVerticalSplit', 'isCompactView']),
      ...mapState({
        selectedMessagesIds: (state: any) => state.messages.selectedMessagesIds,
        layoutSplitState: (state: any) => state.layout.activeSplit,
      }),
      subject(): string {
        return this.message.subject || this.$gettext('(no subject)');
      },
      timeFormatString(): string {
        return this.timeFormat === '12h' ? 'h:mm A' : 'H:mm';
      },
      date(): string {
        if (isToday(this.message.date)) {
          return (this as any).formatDate(
            this.message.date,
            this.timeFormatString
          );
        } else {
          return (this as any).formatDate(this.message.date, this.dateFormat);
        }
      },
      flagDisplayValue(): string {
        return this.message.flagged
          ? this.$gettext('Unflag message')
          : this.$gettext('Flag message');
      },
      isChecked(): boolean {
        return this.selectedMessagesIds.indexOf(this.message.id) > -1;
      },
      isInReadingPane(): boolean {
        return (
          this.layoutSplitState !== SPLIT_NONE &&
          this.message.id === this.$route.query?.message
        );
      },
      route(): ResolvedRoute {
        if (this.isDraftMessage) {
          return this.$router.resolve({
            name: MAIL_COMPOSE,
            query: {
              draftId: this.message.id,
            },
          });
        } else {
          const { message, ...queryWithoutMessage } = this.$route.query;
          return this.$router.resolve({
            name: MAIL_MESSAGE,
            params: {
              folder: this.message.folder_id,
              message: this.message.id,
            },
            query: queryWithoutMessage,
          });
        }
      },
      messageFolder(): Folder {
        return this.folderById(this.message.folder_id);
      },
      isDraftMessage(): boolean {
        // messageFolder could be undefined when this component is first rendered
        // because the folder network request is not done yet.
        return this.messageFolder?.type === 'drafts';
      },
      folderName(): string {
        return (this as any).getFolderName(this.messageFolder);
      },
      selectText(): string {
        return this.$gettext('Select');
      },
      unselectText(): string {
        return this.$gettext('Unselect');
      },
    },
    methods: {
      ...mapActions([
        'selectMessage',
        'deselectMessage',
        'changeMessagesFlagStatus',
        'loadMessages',
        'setToastMessage',
        'moveMessagesToFolder',
      ]),
      onSelectChanged(selected: boolean, shiftKey: boolean) {
        if (shiftKey) {
          this.$emit('shift-select', { message: this.message, selected });
        } else if (selected) {
          this.selectMessage({ id: this.message.id });
        } else {
          this.deselectMessage({ id: this.message.id });
        }
      },
      onFlagMessageClicked() {
        this.changeMessagesFlagStatus({
          messagesIds: [this.message.id],
          flagged: !this.message.flagged,
        }).catch((error) => {
          const translatedText = this.$gettext(
            'Sorry, we could not %{ flagStatus } your message'
          );
          this.setToastMessage({
            message: this.$gettextInterpolate(translatedText, {
              flagStatus: this.message.flagged ? 'unflag' : 'flag',
            }),
          });
          this.loadMessages();
          throw error;
        });
      },
      onDropped({ folderId, message }: { folderId: string; message: Message }) {
        this.updateRoute();
        if (this.selectedMessages.length > 0) {
          (this as any).moveSelectedMessages({ folderId });
        } else {
          this.moveMessagesToFolder({ messagesIds: [message.id], folderId })
            .then(() => {
              const targetFolder = this.folderById(folderId);
              const folderName = (this as any).getFolderName(targetFolder);
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext('Message moved to %{ folderName }'),
                  { folderName },
                  true
                ),
              });
              this.loadMessages();
            })
            .catch(() => {
              this.setToastMessage({
                message: this.$gettext('We could not move this message'),
              });
              this.loadMessages();
            });
        }
      },
      updateRoute() {
        const { message, ...query } = this.$route.query;
        if (message) {
          this.$router.replace({
            ...this.$route,
            name: this.$route.name || undefined,
            query,
          });
        }
      },
    },
  });
