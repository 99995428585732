
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Captcha from '@/components/Captcha/Captcha.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import InputField from '@/components/form/InputField.vue';
  import formatUsername from '@/lib/formatUsername';
  import AsyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import {
    LOGIN,
    PASSWORD_RECOVERY_NEW_RECOVERY_CODE,
  } from '@/router/named-routes';
  import Vue from 'vue';
  import { Location } from 'vue-router';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'SetNewPassword',
    components: {
      AuthenticationLayout,
      Captcha,
      InputField,
      LoadingButton,
      PasswordField,
    },
    mixins: [AsyncActionsMixin],
    props: {
      user: {
        type: String,
        default: '',
      },
      recoveryCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        captchaToken: '',
        username: this.user,
        password: '',
        passwordRepeat: '',
        code: this.recoveryCode,
        validate: false,
        usernameErrorMessage: '',
        showRequirement: false,
        backgroundImage: require('@/assets/img/lock-illustration.svg'),
      };
    },
    computed: {
      isAuthenticated(): boolean {
        return this.$store.state.authentication.isAuthenticated;
      },
      loginRoute(): Location {
        return { name: LOGIN };
      },
      passwordErrorMessage(): string {
        return this.passwordsMatch
          ? this.$gettext('Password is required.')
          : this.$gettext("Passwords don't match");
      },
      passwordLabel(): string {
        return this.$gettext('New password');
      },
      userInputs(): string[] {
        return this.username?.split('@');
      },
      passwordsMatch(): boolean {
        return this.password === this.passwordRepeat;
      },
      recoveryCodeLabel(): string {
        return this.$gettext('Recovery code');
      },
      recoveryCodeValidationErrorMessage(): string {
        return this.$gettext('Recovery code is required.');
      },
      repeatPasswordLabel(): string {
        return this.$gettext('Repeat password');
      },
      showEmailAddressField(): boolean {
        return !this.user;
      },
      showRecoveryCodeField(): boolean {
        return !this.recoveryCode;
      },
      usernameLabel(): string {
        return this.$gettext('Email address');
      },
      usernameValidationErrorMessage(): string {
        return (
          this.usernameErrorMessage ||
          this.$gettext('Email address is required.')
        );
      },
    },
    watch: {
      username() {
        this.usernameErrorMessage = '';
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['logout']),
      onScoreChange({
        passwordStrengthMeasured,
        passwordScore,
      }: {
        passwordStrengthMeasured: boolean;
        passwordScore: number;
      }) {
        this.showRequirement = passwordStrengthMeasured && passwordScore < 3;
      },
      onUsernameBlur() {
        this.username = formatUsername(this.username);
      },
      recoverPassword: asyncAction(
        'recoveringPassword',
        function (this: any, ev: Event) {
          this.username = formatUsername(this.username);
          this.validate = true;

          if (!(ev.target as HTMLFormElement).checkValidity()) {
            this.$refs.captcha.resetHcaptcha();
            return;
          }

          return api.recovery
            .resetPassword({
              email: this.username,
              recoveryCode: this.code,
              new_password: this.password,
              captchaToken: this.captchaToken,
            })
            .then(async ({ new_recovery_key }) => {
              const newRecoveryCode = new_recovery_key;
              if (this.isAuthenticated) {
                await this.logout({ location: 'noop' });
              }
              if (newRecoveryCode) {
                return this.$router.push({
                  name: PASSWORD_RECOVERY_NEW_RECOVERY_CODE,
                  params: {
                    recoveryCode: newRecoveryCode,
                    email: this.username,
                  },
                });
              } else {
                return this.$router.push({
                  name: LOGIN,
                });
              }
            })
            .catch((error) => {
              this.$refs.captcha.resetHcaptcha();
              if (getErrorCode(error) === AUTHENTICATION_FAILURE) {
                this.setToastMessage({
                  message: this.$gettext(
                    'Email address and recovery code do not match'
                  ),
                });
              } else {
                this.setToastMessage({
                  message: this.$gettext(
                    'Something went wrong. Please try again later.'
                  ),
                });
              }
            });
        }
      ),
      showCaptchaErrorMessage(this: any) {
        this.setToastMessage({
          message: this.$refs.captcha.captchaErrorMessage,
        });
      },
      setCaptchaToken(token: string) {
        this.captchaToken = token;
      },
      resetCaptchaToken() {
        this.captchaToken = '';
      },
    },
  });
