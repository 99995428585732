<template>
  <div v-if="state.loading" :key="`loadingKey-${state.loading}`">
    <p class="panel-user-message">
      <translate>Loading key…</translate>
    </p>
  </div>

  <ErrorView v-else-if="state.error" :error-type="state.error" />

  <div v-else class="key-details">
    <div class="panel">
      <div class="panel__section">
        <div
          class="action-bar panel__section-item panel__section-item--no-padding"
        >
          <div class="action-bar__row">
            <div class="action-bar__row-left">
              <div class="action-bar__group">
                <DeleteConfirmationModal
                  v-if="!privateKey"
                  :ok-button-text="deleteKeyText"
                  :warning-text="deleteWarningText"
                  @delete="onDeleteKeyClicked"
                >
                  <template #DeleteButton="{ toggle }">
                    <ActionBarButton
                      :label="deleteText"
                      :title="deleteKeyText"
                      icon="trash"
                      class="button--subtle"
                      @click="toggle"
                    />
                  </template>
                </DeleteConfirmationModal>

                <template v-if="privateKey">
                  <Dropdown ref="dropdown" class="dropdown--align-left">
                    <template #button="{ toggle, isOpen }">
                      <ActionBarButton
                        :label="downloadText"
                        :title="downloadKeyText"
                        :active="isOpen"
                        class="button--subtle"
                        @click.prevent="toggle"
                        icon="download"
                        :dropdown="true"
                      />
                    </template>
                    <template #content="{ toggle }">
                      <ul class="link-list">
                        <li>
                          <button
                            class="button button--subtle link-list__item"
                            @click="downloadPublicKey"
                          >
                            <span v-translate>Public key</span>
                          </button>
                        </li>
                        <a
                          ref="publicKeyDownload"
                          :key="downloadUrlPublicKey"
                          :href="downloadUrlPublicKey"
                          :download="keyFileName('Public key')"
                          @click="toggle"
                        />
                        <li>
                          <button
                            class="button button--subtle link-list__item"
                            @click="showPrivateKeyDownloadPrompt = true"
                          >
                            <span v-translate>Private key</span>
                          </button>
                        </li>
                        <a
                          ref="privateKeyDownload"
                          :key="downloadUrlPrivateKey"
                          :href="downloadUrlPrivateKey"
                          :download="keyFileName('Private key')"
                        />
                      </ul>
                    </template>
                  </Dropdown>

                  <ChangePasswordModal
                    ref="changePasswordModal"
                    :changing-password="changePGPPassphraseChangingPassword"
                    :current-password-text="
                      changePGPPassphraseCurrentPasswordText
                    "
                    :new-password-text="changePGPPassphraseNewPasswordText"
                    :repeat-password-text="
                      changePGPPassphraseRepeatPasswordText
                    "
                    :validation-error-messages="
                      changePGPPassphraseValidationErrorMessages
                    "
                    :confirm-text="changePGPPassphraseText"
                    @changePassword="changePGPPassphrase"
                    @resetValidation="changePGPPassphraseResetValidation"
                  >
                    <template #toggle="{ toggle }">
                      <ActionBarButton
                        :label="changePGPPassphraseText"
                        icon="key"
                        @click="toggle"
                        v-test:changePGPPassword
                        class="button--subtle"
                      />
                    </template>
                    <template #header>
                      <translate>Change PGP-Passphrase</translate>
                      <SupportCenterLink
                        article="360006842578"
                        class="button button-icon-only modal__title-button"
                      >
                        <Icon symbol="info" />
                      </SupportCenterLink>
                    </template>
                  </ChangePasswordModal>
                </template>
                <template v-else>
                  <button
                    class="button button--subtle"
                    @click="downloadPublicKey"
                  >
                    <Icon symbol="download" />
                    <span v-translate>Download</span>
                  </button>
                  <a
                    ref="publicKeyDownload"
                    :key="downloadUrlPublicKey"
                    :href="downloadUrlPublicKey"
                    :download="keyFileName('Public key')"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="panel__section-item key-details__section-item">
          <h1 class="key-details__title">
            {{ displayName || noNameText }}
            <span v-if="privateKey" class="key-details__key-type" v-translate>
              Private / public key
            </span>
            <span v-else class="key-details__key-type" v-translate>
              Public key
            </span>
          </h1>
        </div>

        <div class="panel__section-item">
          <h2 class="panel__section-title">{{ 'Key ID: ' + key.key_id }}</h2>

          <dl class="key-details__list">
            <div class="dl">
              <template v-for="(userId, index) in userIds">
                <dt :key="index" class="dt">
                  <Icon symbol="user" />
                  {{ userId.display_name || noNameText }}
                </dt>
                <dd :key="`dd_${index}`" class="dd">
                  <router-link
                    :to="composeToRoute(userId.address)"
                    v-test:email
                    class="key-details__email"
                    >{{ userId.address }}</router-link
                  >
                </dd>
              </template>

              <dt class="dt dl--indented">
                <translate>Fingerprint</translate>
              </dt>
              <dd class="dd">{{ formattedFingerprint }}</dd>
            </div>

            <div class="dl">
              <dt class="dt">
                <Icon symbol="clock" />
                <translate>Date</translate>
              </dt>
              <dd class="dd" v-test:creation-date>
                {{ formattedDate(key.creation_date) }}
              </dd>

              <dt class="dt dl--indented">
                <translate>Expires</translate>
              </dt>
              <dd class="dd" v-test:expiry-date>
                <span
                  v-if="key.expiry_date"
                  :class="{ danger: isExpired(key.expiry_date) }"
                >
                  {{ formattedDate(key.expiry_date) }}
                </span>
                <span v-else v-translate>No expiration</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <Modal
      :modal-is-open="showPrivateKeyDownloadPrompt"
      @modalToggled="onModalToggled"
    >
      <template #header>
        <translate>Enter PGP passphrase to download your private key</translate>
      </template>
      <template #content>
        <form
          @submit.prevent="
            downloadPrivateKey();
            toggleDropDown();
          "
          novalidate
          ref="pgpPassphraseForm"
        >
          <PasswordField
            :validate-input="decryptionError !== null"
            :validation-error-message="decryptionErrorMessage"
            :show-custom-validity="decryptionBadPassphrase"
            :focused="true"
            :label="pgpPassPhraseText"
            id="pgp-passphrase"
            v-model="pgpPassphrase"
            v-test:pgpPassphrase
            autocomplete="off"
          />
          <div class="form-field user-message user-message--warning">
            <Icon symbol="warning" class="warning user-message__icon" />
            <p v-translate>
              You should never share your private key with anyone.
            </p>
          </div>
        </form>
        <ModalConfirmActions
          :ok-text="downloadText"
          @okClicked="downloadPrivateKey"
          @cancelClicked="onCancel"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
  import api from '@/api';
  import { PGP_BAD_PASSPHRASE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import ActionBarButton from '@/components/ActionBarButton';
  import ChangePasswordModal from '@/components/ChangePasswordModal';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal';
  import Dropdown from '@/components/Dropdown/Dropdown';
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView';
  import Icon from '@/components/Icon/Icon';
  import Modal from '@/components/Modal';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import PasswordField from '@/components/PasswordField/PasswordField';
  import { getDateFormat } from '@/lib/dateTimeFormats';
  import formatLongNumber from '@/lib/formatLongNumber';
  import { mixin as timeMixin } from '@/lib/time';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { MAIL_COMPOSE, SETTINGS_PGP_KEYRING } from '@/router/named-routes';
  import { mapActions, mapState } from 'vuex';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default {
    name: 'KeyDetails',
    components: {
      ActionBarButton,
      ChangePasswordModal,
      DeleteConfirmationModal,
      Dropdown,
      Icon,
      PasswordField,
      Modal,
      ModalConfirmActions,
      ErrorView,
      SupportCenterLink,
    },
    mixins: [timeMixin, asyncActionsMixin],
    data() {
      return {
        state: { loading: true, error: null },
        pgpPassphrase: '',
        decryptionError: null,
        showPrivateKeyDownloadPrompt: false,
        downloadUrlPublicKey: null,
        downloadUrlPrivateKey: null,
        key: {},
        changePGPPassphraseChangingPassword: false,
        changePGPPassphraseValidationErrorMessages: {},
      };
    },
    computed: {
      ...mapState('authentication', ['user']),
      deleteText() {
        return this.$gettext('Delete');
      },
      deleteKeyText() {
        return this.$gettext('Delete PGP key');
      },
      deleteWarningText() {
        if (this.primaryEmailAddress) {
          const warning = this.$gettextInterpolate(
            this.$gettext(
              'This PGP key will be permanently deleted. Without it, you will no longer be able to send encrypted messages to %{ primaryEmailAddress }.'
            ),
            { primaryEmailAddress: this.primaryEmailAddress },
            true
          );
          const displayNameWarning = this.$gettextInterpolate(
            this.$gettext(
              'This PGP key will be permanently deleted. Without it, you will no longer be able to send encrypted messages to %{ displayName } at %{ primaryEmailAddress }.'
            ),
            {
              displayName: this.displayName,
              primaryEmailAddress: this.primaryEmailAddress,
            },
            true
          );
          return this.displayName ? displayNameWarning : warning;
        } else {
          return this.$gettextInterpolate(
            this.$gettext(
              'This PGP key will be permanently deleted. Without it, you will no longer be able to send encrypted messages to %{ displayName }.'
            ),
            { displayName: this.displayName },
            true
          );
        }
      },
      downloadText() {
        return this.$gettext('Download');
      },
      downloadKeyText() {
        return this.$gettext('Download key');
      },
      noNameText() {
        return this.$gettext('No name');
      },
      displayName() {
        return this.key.user_ids && this.key.user_ids[0].display_name;
      },
      primaryEmailAddress() {
        return this.key.user_ids && this.key.user_ids[0].address;
      },
      userIds() {
        return this.key.user_ids;
      },
      formattedFingerprint() {
        return formatLongNumber(this.key.fingerprint);
      },
      privateKey() {
        return this.key.has_private_key;
      },
      pgpPassPhraseText() {
        return this.$gettext('PGP passphrase');
      },
      decryptionErrorMessage() {
        return this.decryptionBadPassphrase
          ? this.$gettext('PGP passphrase is incorrect')
          : this.$gettext('PGP passphrase is required.');
      },
      decryptionBadPassphrase() {
        return this.decryptionError === PGP_BAD_PASSPHRASE;
      },
      changePGPPassphraseText() {
        return this.$gettext('Change PGP-passphrase');
      },
      changePGPPassphraseCurrentPasswordText() {
        return this.$gettext('Old PGP-Passphrase');
      },
      changePGPPassphraseNewPasswordText() {
        return this.$gettext('New PGP-Passphrase');
      },
      changePGPPassphraseRepeatPasswordText() {
        return this.$gettext('Repeat new PGP-Passphrase');
      },
    },
    watch: {
      pgpPassphrase() {
        this.decryptionError = null;
      },
    },
    created() {
      this.loadKeyDetails();
    },
    methods: {
      ...mapActions(['deleteKey', 'setToastMessage']),
      async loadKeyDetails() {
        try {
          this.key = await api.keys.getKeyDetails({
            fingerprint: this.$route.params.fingerprint,
          });
          this.state = { loading: false, error: null };
        } catch (error) {
          this.state = { loading: false, error: ErrorType.KeyNotFound };
          throw error;
        }
      },
      isExpired(expDate) {
        return this.isBeforeToday(expDate);
      },
      formattedDate(date) {
        const dateFormat = getDateFormat(this.user.preferences.date_format);
        return this.formatDate(date, dateFormat);
      },
      keyFileName(keyType) {
        return this.displayName
          ? this.displayName + ` - ${this.key.key_id} - ${keyType}.asc`
          : `${this.key.key_id} - ${keyType}.asc`;
      },
      downloadKeyUrl(content) {
        return 'data:text/plain;charset=utf-8,' + encodeURIComponent(content);
      },
      onCancel() {
        this.decryptionError = null;
        this.pgpPassphrase = '';
        this.showPrivateKeyDownloadPrompt = false;
      },
      downloadPublicKey() {
        api.keys
          .downloadPublic({
            fingerprint: this.key.fingerprint,
          })
          .then((ascii_armored_key) => {
            this.downloadUrlPublicKey = this.downloadKeyUrl(ascii_armored_key);
            this.$nextTick(() => {
              this.$refs.publicKeyDownload.click();
            });
          });
      },
      downloadPrivateKey() {
        let pgpPassphrase = this.pgpPassphrase;
        api.keys
          .downloadSecret({
            fingerprint: this.key.fingerprint,
            pgpPassphrase: pgpPassphrase,
          })
          .then((ascii_armored_key) => {
            this.downloadUrlPrivateKey = this.downloadKeyUrl(ascii_armored_key);
            this.$nextTick(() => {
              this.$refs.privateKeyDownload.click();
            });
            this.showPrivateKeyDownloadPrompt = false;
            this.decryptionError = null;
            this.pgpPassphrase = '';
          })
          .catch((error) => {
            this.decryptionError = getErrorCode(error);
          });
      },
      onModalToggled(modalOpened) {
        if (!modalOpened) {
          this.onCancel();
        }
      },
      toggleDropDown() {
        if (!this.showPrivateKeyDownloadPrompt) {
          this.$refs.dropdown.toggleDropDown();
        }
      },
      onDeleteKeyClicked: asyncAction('deleting', function () {
        return this.deleteKey({ fingerprint: this.key.fingerprint })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Key permanently deleted'),
            });
          })
          .catch(() => {
            this.setToastMessage({
              message: this.$gettext('Sorry, we could not delete key'),
            });
          })
          .finally(() => {
            this.$router.push({ name: SETTINGS_PGP_KEYRING });
          });
      }),
      composeToRoute(emailAddress) {
        return { name: MAIL_COMPOSE, query: { mailTo: emailAddress } };
      },
      changePGPPassphrase({ currentPassword, newPassword }) {
        this.changePGPPassphraseChangingPassword = true;

        api.keys
          .changePassphrase({
            fingerprint: this.key.fingerprint,
            currentPassphrase: currentPassword,
            newPassphrase: newPassword,
          })
          .then(() => {
            this.$refs.changePasswordModal.toggleModal();
            this.setToastMessage({
              message: this.$gettext('PGP-Passphrase has been changed'),
            });
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.changePGPPassphraseValidationErrorMessages = {
                currentPassword: this.$gettext('Wrong old PGP-Passphrase.'),
              };
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not change your PGP-Passphrase'
                ),
              });
            }
          })
          .finally(() => {
            this.changePGPPassphraseChangingPassword = false;
          });
      },
      changePGPPassphraseResetValidation() {
        this.changePGPPassphraseValidationErrorMessages = {};
      },
    },
  };
</script>

<style src="./KeyDetails.scss" lang="scss"></style>
