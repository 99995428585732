
  import api from '@/api';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import { SETTINGS_MAIL_FILTERS } from '@/router/named-routes';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  const newBlockFilter = (address: string): Filter => {
    return {
      test_combinator: 'all',
      tests: [
        {
          source: 'headers',
          headers: ['from'],
          match: 'contains',
          values: [address],
        },
      ],
      actions: [{ action: 'delete' }],
    };
  };
  const compareToBlockFilter = (filter: Filter, address: string): boolean => {
    // This comparison is written out here because we don't want
    // to include something like the rather complicated
    // lodash.isEqual
    // Just ensure this matches whatever newBlockFilter
    // generates.
    if (
      filter.test_combinator === 'all' &&
      filter.tests.length === 1 &&
      filter.actions.length === 1
    ) {
      const {
        tests: [test],
        actions: [action],
      } = filter;
      return (
        test.source == 'headers' &&
        test.headers.length === 1 &&
        test.headers[0] == 'from' &&
        test.match == 'contains' &&
        test.values.length == 1 &&
        test.values[0] == address &&
        action.action == 'delete'
      );
    }
    return false;
  };

  export default Vue.extend({
    name: 'BlockSenderModal',
    components: {
      Modal,
      ModalConfirmActions,
    },
    props: {
      sender: {
        type: String,
        required: false,
        default: '',
      },
    },
    computed: {
      blockText(): string {
        return this.$gettext('Block');
      },
      filterRoute(): string {
        return this.$router.resolve({ name: SETTINGS_MAIL_FILTERS }).href;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      async blockSender() {
        let entity_tag, filters;
        try {
          ({ entity_tag, filters } = await api.filters.get());
        } catch (error) {
          this.setToastMessage({
            message: this.$gettext(
              'Something went wrong loading filters. Please try again later.'
            ),
          });
          throw error;
        }
        const senderAlreadyBlocked = filters.some((filter) =>
          compareToBlockFilter(filter, this.sender)
        );
        if (senderAlreadyBlocked) {
          this.setToastMessage({
            message: this.$gettext('Sender already blocked'),
          });
          return;
        }
        filters.unshift(newBlockFilter(this.sender));
        try {
          await api.filters.update({
            filters,
            entity_tag,
          });
        } catch (error) {
          this.setToastMessage({
            message: this.$gettext(
              'Something went wrong updating filters. Please try again later.'
            ),
          });
          throw error;
        }
        this.setToastMessage({
          message: this.$gettext('Sender blocked'),
        });
      },
    },
  });
