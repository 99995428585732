
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import InputField from '@/components/form/InputField.vue';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default Vue.extend({
    components: {
      Modal,
      ModalConfirmActions,
      PasswordField,
      InputField,
    },
    data() {
      return {
        email: '',
        password: '',
        wrongEmailAddress: false,
        missingEmailAddress: false,
        wrongPassword: false,
        missingPassword: false,
        validate: false,
        loading: true,
        loadingFailed: false,
      };
    },
    computed: {
      ...mapGetters('subscription', ['hasMultipleAccounts']),
      ...mapState({
        userEmailAddress: (state: any) => state.authentication.user.email,
      }),
      emailValidationErrorMessage(): string {
        return this.email
          ? this.wrongEmailAddressText
          : this.missingEmailAddressText;
      },
      passwordValidationErrorMessage(): string {
        return this.password
          ? this.wrongPasswordText
          : this.missingPasswordText;
      },
      submitButtonText(): string {
        return this.$gettext('Transfer ownership');
      },
      emailLabelText(): string {
        return this.$gettext('Email address of new owner account');
      },
      wrongEmailAddressText(): string {
        return this.$gettext('Wrong email address');
      },
      missingEmailAddressText(): string {
        return this.$gettext('Email address is required');
      },
      wrongPasswordText(): string {
        return this.$gettext('Wrong password');
      },
      missingPasswordText(): string {
        return this.$gettext('Password is required');
      },
      passwordLabelText(): string {
        return this.$gettext('Confirm with your own password');
      },
    },
    watch: {
      email() {
        this.wrongEmailAddress = false;
        this.missingEmailAddress = false;
        this.validate = false;
      },
      password() {
        this.wrongPassword = false;
        this.missingPassword = false;
        this.validate = false;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['logout']),
      async transferOwnership() {
        this.validate = true;
        if (this.email.trim().length === 0) {
          this.missingEmailAddress = true;
        }
        if (
          this.email.trim().toLowerCase() ===
          this.userEmailAddress.toLowerCase()
        ) {
          this.wrongEmailAddress = true;
        }
        if (this.password.length === 0) {
          this.missingPassword = true;
        }
        if (!(this.$refs.form as HTMLFormElement).checkValidity()) {
          return;
        }

        try {
          await api.groupSubscriptions.patchAccount({
            change: {
              is_manager: true,
              username: this.email.trim().toLowerCase(),
            },
            password: this.password,
          });
        } catch (error) {
          if (
            error instanceof AxiosError &&
            getErrorCode(error) === AUTHENTICATION_FAILURE
          ) {
            this.wrongPassword = true;
          } else {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not transfer ownership right now. Please try again later.'
              ),
            });
          }
          return;
        }

        await this.logout({ location: 'login' });
      },
      onModalToggled(modalOpened: boolean) {
        if (!modalOpened) {
          this.validate = false;
          this.password = '';
          this.email = '';
          this.wrongEmailAddress = false;
          this.missingEmailAddress = false;
          this.wrongPassword = false;
          this.missingPassword = false;
        }
      },
    },
  });
