
  import AddDomain from '@/components/AddDomain.vue';
  import DomainBlock from '@/components/DomainBlock.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  enum State {
    Loading,
    Loaded,
    Error,
  }

  export default Vue.extend({
    components: {
      AddDomain,
      DomainBlock,
      NotificationBlock,
      Spinner,
    },
    async created() {
      try {
        await this.loadAliases(); // need this for catch-all status only
        this.state = State.Loaded;
      } catch (error) {
        this.state = State.Error;
      }
    },
    data() {
      return {
        State,
        state: State.Loading,
        monthlyPrice: 100,
        yearlyPrice: 1000,
      };
    },
    computed: {
      domainNames(): string[] {
        if (this.state !== State.Loaded) return [];
        return (this.$store.state.authentication.user.domains as Domain[])
          .map((domain) => domain.domain_name)
          .sort();
      },
      // Note: no v-translate in the template b/c HTML snippets don't work with
      // scoped CSS; see https://github.com/Polyconseil/vue-gettext/issues/75
      getStartedHtml(): string {
        return this.$gettextInterpolate(
          this.$gettext(
            '<strong>Add your own domain(s) to your StartMail account and make a professional appearance by using domain aliases.</strong> You must already own the domain you want to use. Read our <a target="_blank" href="%{url}">support center article</a> or get started by clicking <strong>‘Add domain’</strong>.'
          ),
          { url: this.supportArticleUrl }
        );
      },
      helpHtml(): string {
        const text = this.$gettext(
          'To send and receive email with your own domains, your DNS records must be set up correctly. Visit your domain registrar to configure your DNS settings as indicated below. Keep in mind that while DNS updates usually take only a few minutes to propagate, sometimes it may take up to 24 hours. For a step-by-step guide, visit our <a target="_blank" href="%{url}">support center article</a>.'
        );
        return this.$gettextInterpolate(text, { url: this.supportArticleUrl });
      },
      supportArticleUrl(): string {
        return this.$gettextInterpolate(
          this.$gettext(
            'https://support.startmail.com/hc/en-us/articles/%{ article }'
          ),
          { article: '16174638984989' }
        );
      },
    },
    methods: {
      ...mapActions('aliases', ['loadAliases']),
    },
  });
