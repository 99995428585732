var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"setup-guide-fade"}},[_c('div',{staticClass:"setup-guide",class:{ 'hide-setup-content': _vm.isSetupGuideCollapsed }},[_c('div',{staticClass:"setup-guide__header"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isSetupGuideCollapsed ? _vm.expandText : _vm.collapseText,
          placement: 'right',
          disabled: _vm.isMobileView,
        }),expression:"{\n          content: isSetupGuideCollapsed ? expandText : collapseText,\n          placement: 'right',\n          disabled: isMobileView,\n        }"}],staticClass:"button button--subtle button--small setup-guide__title-button",attrs:{"type":"button"},on:{"click":_vm.toggleSetupGuide}},[_c('Icon',{attrs:{"symbol":"guides-tutorial","size":"large"}}),_c('translate',[_vm._v("Set up guide")]),_c('Icon',{attrs:{"symbol":!_vm.isSetupGuideCollapsed ? 'arrow-up' : 'arrow-down'}})],1),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.cancelText,
          placement: 'left',
          disabled: _vm.isMobileView,
        }),expression:"{\n          content: cancelText,\n          placement: 'left',\n          disabled: isMobileView,\n        }"}],staticClass:"button button--icon-only setup-guide__close-button",attrs:{"type":"button"},on:{"click":_vm.closeSetupGuide}},[_c('Icon',{attrs:{"symbol":"remove-outline"}}),_c('span',{staticClass:"visually-hidden",domProps:{"textContent":_vm._s(_vm.cancelText)}})],1)]),_c('transition',{attrs:{"name":"setup-guide-fade"}},[(!_vm.isSetupGuideCollapsed)?_c('div',{ref:"body",staticClass:"setup-guide__body"},_vm._l((_vm.cards),function(cardId){return _c('SetupCard',{directives:[{name:"test",rawName:"v-test:setupGuide",value:(cardId),expression:"cardId",arg:"setupGuide"}],key:cardId,attrs:{"id":cardId,"completed":_vm.isCardCompleted(cardId)}})}),1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }