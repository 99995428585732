
  import Vue from 'vue';
  import ContactSupport from '@/components/ContactSupport/ContactSupport.vue';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';

  export default Vue.extend({
    name: 'Support',
    components: {
      ContactSupport,
      AuthenticationLayout,
    },
    computed: {
      title(): string {
        return this.$route.params.title;
      },
      message(): string {
        return this.$route.params.message;
      },
    },
  });
