
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';
  import AliasList from '@/components/AliasList/AliasList.vue';
  import AliasesTopBar from '@/components/AliasManagement/AliasesTopBar.vue';
  import EmptyView from '@/components/EmptyView.vue';
  import {
    AliasFilterEvent,
    SortOption,
  } from '@/components/AliasManagement/AliasesTopBar.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import * as namedRoutes from '@/router/named-routes';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default Vue.extend({
    name: 'CustomAliases',
    components: {
      AliasList,
      AliasesTopBar,
      EmptyView,
      Icon,
      SupportCenterLink,
    },
    props: {
      type: {
        type: String as PropType<string>,
        required: true,
        validator(value: any) {
          return ['personal', 'group'].includes(value);
        },
      },
    },
    data() {
      return {
        active: false,
        inactive: false,
        domains: [] as string[],
        sortBy: SortOption.CreationDateDesc.toString(),
      };
    },
    computed: {
      aliases(): Alias[] {
        return this.type === 'personal'
          ? this.$store.getters['aliases/personalAliases']
          : this.$store.getters['aliases/domainAliases'];
      },
      visibleAliases(): Alias[] {
        return this.aliases
          .filter((alias) => {
            // include this alias if it matches the active-check *AND* the domain check
            return (
              // check the active field if requested
              (this.active === this.inactive ||
                (alias.enabled === this.active &&
                  alias.enabled !== this.inactive)) &&
              // check the domain if requested
              (this.domains.length === 0 ||
                this.domains.some((domain) =>
                  alias.alias.endsWith(`@${domain}`)
                ))
            );
          })
          .sort((a, b) => {
            switch (this.sortBy) {
              case SortOption.AliasEmailAsc.toString():
                return a.alias.localeCompare(b.alias);
              case SortOption.AliasEmailDesc.toString():
                return b.alias.localeCompare(a.alias);
              case SortOption.CreationDateAsc.toString():
                return (
                  new Date(a.created).getTime() - new Date(b.created).getTime()
                );
              default:
                return (
                  new Date(b.created).getTime() - new Date(a.created).getTime()
                );
            }
          });
      },
      domainAliasesMovedHtml(): string {
        // Note: <translate> with a nested <router-link> in the
        // template won't work correctly.
        const el = document.createElement('div');
        el.innerHTML = this.$gettext(
          'Looking for your domain aliases? Find them on the <a>domain aliases page</a>.'
        );
        const { href } = this.$router.resolve({
          name: namedRoutes.ALIASES_DOMAIN,
        });
        el.getElementsByTagName('a')[0].href = href;
        return el.innerHTML;
      },
    },

    methods: {
      ...mapActions('aliases', ['loadAliases']),
      setFilter({ active, inactive, domains, sortBy }: AliasFilterEvent) {
        this.active = active;
        this.inactive = inactive;
        this.domains = domains;
        this.sortBy = sortBy.toString();
      },
    },
  });
