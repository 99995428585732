
  import { isAxiosError } from 'axios';
  import { mapActions } from 'vuex';
  import api from '@/api';
  import getErrorCode from '@/api/get-error-code';
  import Vue from 'vue';
  import DefaultDialog from '../DefaultDialog.vue';
  import LoadingButton from '../LoadingButton.vue';
  import PasswordField from '../PasswordField/PasswordField.vue';
  import NotificationBlock from '../NotificationBlock/NotificationBlock.vue';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';

  export default Vue.extend({
    components: {
      DefaultDialog,
      LoadingButton,
      PasswordField,
      NotificationBlock,
    },
    data() {
      return {
        authenticationError: false,
        loading: false,
        password: '',
        validatePassword: false,
      };
    },
    computed: {
      passwordLabelText(): string {
        return this.$gettext('Your account password');
      },
      passwordValidationText(): string {
        return this.authenticationError
          ? this.$gettext('Wrong password')
          : this.$gettext('Something went wrong');
      },
    },
    watch: {
      password() {
        this.validatePassword = false;
        this.authenticationError = false;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      resetPasswordField() {
        this.password = '';
        this.authenticationError = false;
        this.validatePassword = false;
      },
      async removeRecoveryEmail(closeDialog: Function) {
        this.loading = true;
        try {
          await api.recovery.disableRecoveryAddress({
            password: this.password,
          });
          this.setToastMessage({
            message: this.$gettext('Recovery by email disabled successfully'),
          });
          closeDialog();
        } catch (error) {
          if (
            isAxiosError(error) &&
            getErrorCode(error) === AUTHENTICATION_FAILURE
          ) {
            this.authenticationError = true;
            return;
          }
          this.setToastMessage({
            message: this.$gettext(
              'Sorry, we could not disable recovery by email. Please try again later.'
            ),
          });
          closeDialog();
          this.resetPasswordField();
        } finally {
          this.loading = false;
        }
      },
    },
  });
