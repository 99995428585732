
  import Vue from 'vue';
  import { Location } from 'vue-router';
  import { mapActions, mapGetters } from 'vuex';
  import Icon from '@/components/Icon/Icon.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import { MAIL_FOLDER } from '@/router/named-routes';

  export default Vue.extend({
    name: 'FolderItem',
    components: {
      Icon,
    },
    mixins: [asyncActionsMixin, folderUtilsMixin],
    props: {
      item: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      count: {
        type: Number,
        default: 0,
      },
      showActions: {
        type: Boolean,
        default: false,
      },
      showCurrentTag: {
        type: Boolean,
        required: false,
        default: false,
      },
      shownInSidebar: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        newName: '',
      };
    },
    computed: {
      ...mapGetters('layout', ['isCompactView']),
      ...mapGetters(['folderById', 'sidebarIsOpen']),
      showFullName(): boolean {
        return this.shownInSidebar ? this.sidebarIsOpen : true;
      },
      isActive(): boolean {
        return this.$route.params.folder === this.item.id;
      },
      folderRoute(): Location {
        return { name: MAIL_FOLDER, params: { folder: this.item.id } };
      },
      folderName(): string {
        return (this as any).getFolderName(this.item);
      },
      icon(): string {
        return (this as any).getIcon(this.item);
      },
    },
    methods: {
      ...mapActions(['setToastMessage', 'loadMessages', 'closeMobileSidebar']),
      getUrl(this: any, route: Object): Location {
        return this.$router.resolve(route).resolved.fullPath;
      },
      reloadMessages: asyncAction(
        'reloadingMessages',
        function (this: any, folderName: String) {
          return this.loadMessages().then(() => {
            this.closeMobileSidebar();
            this.setToastMessage({
              message: this.$gettextInterpolate(
                this.$gettext('%{ folder } is updated'),
                {
                  folder: folderName,
                }
              ),
            });
          });
        }
      ),
    },
  });
