
  import { mapActions, mapGetters, mapState } from 'vuex';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import ActionBarButton from '@/components/ActionBarButton.vue';
  import Checkbox from '@/components/form/Checkbox.vue';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Modal from '@/components/Modal.vue';
  import Pagination from '@/components/Pagination/Pagination.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import LayoutDropdown from '@/components/LayoutDropdown/LayoutDropdown.vue';

  import { MAIL_COMPOSE } from '@/router/named-routes';
  import contactGroupMixin from '@/mixins/contact-group-mixin';
  import Vue from 'vue';
  import { AxiosError } from 'axios';

  export default Vue.extend({
    components: {
      ActionBarButton,
      Icon,
      Checkbox,
      DeleteConfirmationModal,
      Modal,
      ModalConfirmActions,
      Pagination,
      LayoutDropdown,
    },
    mixins: [asyncActionsMixin, contactGroupMixin],
    data() {
      return {
        composeModalIsOpen: false,
      };
    },
    computed: {
      ...mapState({
        paginationTotal: (state) => (state as any).pagination.total,
      }),
      ...mapGetters([
        'contacts',
        'contactUserGroups',
        'selectedPrimaryEmailAddresses',
        'selectedContacts',
        'activeContactGroup',
        'allPrimaryAddresses',
      ]),
      allContactsSelected(): boolean {
        return this.selectedContacts.length === this.contacts.length;
      },
      selectedContactsLength(): number {
        return this.selectedContacts.length;
      },
      bulkSelectButtonText(): string {
        return this.allContactsSelected
          ? this.$gettext('Deselect all contacts')
          : this.$gettext('Select all contacts');
      },
      selectedContactsText(): string {
        const n = this.selectedContacts.length;
        const translated = this.$ngettext(
          '%{ n } contact',
          '%{ n } contacts',
          n
        );
        return this.$gettextInterpolate(translated, { n });
      },
      goBackText(): string {
        return this.$gettext('Go back');
      },
      removeContactsWarningText(): string {
        const n = this.selectedContacts.length;
        return this.$ngettext(
          'The selected contact will be permanently deleted. It is not possible to undo this action.',
          'The selected contacts will be permanently deleted. It is not possible to undo this action.',
          n
        );
      },
      selectAllText(): string {
        return this.selectedContacts.length > 0
          ? this.$gettext('Deselect all')
          : this.$gettext('Select All');
      },
      addToGroupText(): string {
        return this.$gettext('Add to group');
      },
      deleteContactText(): string {
        return this.$ngettext(
          'Delete contact',
          'Delete contacts',
          this.selectedContacts.length
        );
      },
      deleteSelectedContactsText(): string {
        return this.$ngettext(
          'Delete selected contact',
          'Delete selected contacts',
          this.selectedContacts.length
        );
      },
      deleteText(): string {
        return this.$gettext('Delete');
      },
      favoriteText(): string {
        return this.allSelectedContactAreFavourites
          ? this.$gettext('Un-favorite')
          : this.$gettext('Favorite');
      },
      allSelectedContactAreFavourites(): boolean {
        return (this.selectedContacts as Contact[]).every(
          (contact) => contact.favorite
        );
      },
      groupComposeButtonText(): string {
        return (
          this.composeText +
          ' - ' +
          (this as any).getContactGroupName(this.activeContactGroup) +
          ` (${this.paginationTotal})`
        );
      },
      groupComposeTitle(): string {
        return this.$gettext('Send message to all the contacts in this group');
      },
      confirmationText(): string {
        return this.noPrimaryEmailAddress
          ? this.$gettext('Close')
          : this.composeText;
      },
      composeText(): string {
        return this.$gettext('Compose');
      },
      selectedContactsComposeText(): string {
        return this.composeText + ` (${this.selectedContactsLength})`;
      },
      contactsCountWithoutPrimaryEmailAddress(): number {
        return this.paginationTotal - (this as any).allPrimaryAddresses.length;
      },
      selectedContactsWarning(): string {
        const primaryAddressLength = this.selectedPrimaryEmailAddresses.length;
        const contactsWithoutPrimaryAddress =
          this.selectedContactsLength -
          this.selectedPrimaryEmailAddresses.length;
        if (primaryAddressLength) {
          const translated = this.selectedContactModalText(
            contactsWithoutPrimaryAddress
          );
          return this.$gettextInterpolate(translated, {
            number: contactsWithoutPrimaryAddress,
          });
        } else {
          const translated = this.selectedContactModalText(
            this.selectedContactsLength
          );
          return (
            this.$gettextInterpolate(translated, {
              number: this.selectedContactsLength,
            }) + this.youCanAddEmailAddressText
          );
        }
      },
      groupContactsWarning(): string {
        const primaryAddressLength = this.allPrimaryAddresses.length;
        const contactsWithoutPrimaryAddress =
          this.paginationTotal - this.allPrimaryAddresses.length;
        if (primaryAddressLength) {
          const translated = this.contactGroupModalText(
            contactsWithoutPrimaryAddress
          );
          return this.$gettextInterpolate(translated, {
            number: contactsWithoutPrimaryAddress,
          });
        } else {
          const translated = this.contactGroupModalText(this.paginationTotal);
          return (
            this.$gettextInterpolate(translated, {
              number: this.paginationTotal,
            }) + this.youCanAddEmailAddressText
          );
        }
      },
      noPrimaryEmailAddress(): boolean {
        return (
          (this.selectedContactsLength &&
            this.selectedPrimaryEmailAddresses.length === 0) ||
          this.allPrimaryAddresses.length === 0
        );
      },
      youCanAddEmailAddressText(): string {
        return this.$gettext(
          ' You can add an email address by clicking on the contact and then Edit.'
        );
      },
    },
    methods: {
      ...mapActions([
        'setToastMessage',
        'getContactsForGroup',
        'deselectAllContacts',
        'selectAllContacts',
        'deleteSelectedContacts',
        'moveSelectedContacts',
        'toggleSelectedContactsFavoriteStatus',
      ]),
      onBulkSelectClicked() {
        if (this.allContactsSelected) {
          this.deselectAllContacts();
        } else {
          this.selectAllContacts();
        }
      },
      onComposeClicked() {
        if (
          this.selectedPrimaryEmailAddresses.length ===
          this.selectedContacts.length
        ) {
          this.goToComposeRoute('selectedContacts');
        } else {
          this.composeModalIsOpen = true;
        }
      },
      onGroupComposeClicked() {
        if (this.allPrimaryAddresses.length === this.contacts.length) {
          this.goToComposeRoute('group');
        } else {
          this.composeModalIsOpen = true;
        }
      },
      async goToComposeRoute(sendTo: string) {
        return await this.$router.push({
          name: MAIL_COMPOSE,
          params: { backRoute: (this as any).$route },
          query: { sendTo },
        });
      },
      contactsExistsInGroup(group: ContactGroup) {
        return (this.selectedContacts as Contact[]).every((contact) =>
          contact.contact_group_ids.includes(group.id)
        );
      },
      selectedContactModalText(number: number): string {
        return this.$ngettext(
          this.selectedContactsLength === 1
            ? 'The selected contact cannot be added as a recipient because it does not have an email address.'
            : '1 of the selected contacts cannot be added as a recipient because it does not have an email address.',
          '%{ number } of the selected contacts cannot be added as recipients because they do not have an email address.',
          number
        );
      },
      contactGroupModalText(number: number): string {
        return this.$ngettext(
          '1 contact cannot be added as a recipient because it does not have an email address.',
          '%{ number } contacts cannot be added as recipients because they do not have an email address.',
          number
        );
      },
      onMoveContactGroupClicked: asyncAction(
        'moving',
        function (this: any, group: ContactGroup, modalScope: any) {
          return this.moveSelectedContacts({ contactGroupId: group.id })
            .then((movedContacts: Contact[]) => {
              modalScope.toggle();

              const numberOfContacts = movedContacts.length;
              const groupName = group.name;
              const translatedText = this.$ngettext(
                'Contact added to %{ groupName }',
                '%{ numberOfContacts } contacts added to %{ groupName }',
                numberOfContacts
              );
              const toastMessage = this.$gettextInterpolate(translatedText, {
                numberOfContacts,
                groupName,
              });
              this.setToastMessage({ message: toastMessage });
            })
            .catch((error: AxiosError) => {
              this.setToastMessage({
                message: this.$ngettext(
                  'Sorry, we could not add your selected contact to the contact group',
                  'Sorry, we could not add your selected contacts to the contact group',
                  this.selectedContacts.length
                ),
              });
              throw error;
            });
        }
      ),
      onDeleteClicked: asyncAction('deleting', function (this: any) {
        return this.deleteSelectedContacts()
          .then((deletedContacts: Contact[]) => {
            const numberOfContacts = deletedContacts.length;
            const translatedText = this.$ngettext(
              'Contact deleted',
              '%{ numberOfContacts } contacts deleted',
              numberOfContacts
            );
            const toastMessage = this.$gettextInterpolate(translatedText, {
              numberOfContacts,
            });
            this.setToastMessage({ message: toastMessage });
            this.getContactsForGroup({
              id: this.$route.params.group,
              query: this.$route.query.query,
            });
          })
          .catch((error: AxiosError) => {
            this.setToastMessage({
              message: this.$ngettext(
                'Sorry, we could not delete your contact',
                'Sorry, we could not delete your contacts',
                this.selectedContacts.length
              ),
            });
            throw error;
          });
      }),
      onFavoriteClicked() {
        this.toggleSelectedContactsFavoriteStatus().catch(
          (error: AxiosError) => {
            this.setToastMessage({
              message: this.$ngettext(
                'Sorry, we could not favorite your contact',
                'Sorry, we could not favorite your contacts',
                this.selectedContacts.length
              ),
            });
            throw error;
          }
        );
      },
      onSelectionChanged() {
        if (this.allContactsSelected || this.selectedContactsLength) {
          this.deselectAllContacts();
        } else {
          this.selectAllContacts();
        }
      },
      onModalToggled(modalOpened: boolean) {
        if (!modalOpened) {
          this.composeModalIsOpen = false;
        }
      },
    },
  });
