<template>
  <button
    class="button loading-button"
    :class="{ 'loading-button--loading': loading }"
    :disabled="loading || disabled"
    :form="form"
    :key="loading"
    :type="type"
    @click="$emit('click', $event)"
  >
    <slot />
    <Spinner v-if="loading" class="loading-button__spinner" />
  </button>
</template>

<script>
  import Spinner from '@/components/Spinner/Spinner';

  export default {
    components: {
      Spinner,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'button',
      },
      form: {
        type: String,
        default: undefined,
      },
    },
  };
</script>

<style scoped lang="css">
  .loading-button {
    display: flex;
    gap: 0.25rem;
  }
</style>
