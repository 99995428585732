<template>
  <AppHeader
    :show-back-button="true"
    :back-text="activeContactGroupName"
    @backClicked="onBackClicked"
  >
    <template #button>
      <ContactSearchForm />
    </template>
  </AppHeader>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ContactSearchForm from '@/components/SearchForm/ContactSearchForm';
  import contactGroupMixin from '@/mixins/contact-group-mixin';
  import { CONTACTS_GROUP } from '@/router/named-routes';
  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';

  export default {
    name: 'ContactDetailHeader',
    components: {
      AppHeader,
      ContactSearchForm,
    },
    mixins: [contactGroupMixin],
    computed: {
      ...mapGetters(['activeContactGroup']),
      activeContactGroupName() {
        return this.getContactGroupName(this.activeContactGroup);
      },
    },
    methods: {
      onBackClicked() {
        this.$router.push({
          name: CONTACTS_GROUP,
          params: { group: this.activeContactGroup.id },
        });
      },
    },
  };
</script>
