
  import DeleteAll from '@/components/DeleteAll/DeleteAll.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import MessagesActionsHeader from '@/components/MessagesActionsHeader/MessagesActionsHeader.vue';
  import SetupGuideCompletedModal from '@/components/QueryModals/SetupGuideCompletedModal.vue';
  import MailSearchForm from '@/components/SearchForm/MailSearchForm.vue';
  import SetupGuide from '@/components/SetupGuide/SetupGuide.vue';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import { MAIL_COMPOSE } from '@/router/named-routes';
  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';
  import Vue from 'vue';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    components: {
      AppHeader,
      DeleteAll,
      Icon,
      MailSearchForm,
      MessagesActionsHeader,
      SetupGuide,
      SetupGuideCompletedModal,
    },
    mixins: [folderUtilsMixin],

    computed: {
      ...mapGetters(['activeFolder']),
      ...mapGetters('authentication', [
        'isSetupGuideVisible',
        'isSetupGuideCompletedSeen',
        'setupGuideCards',
        'isSetupGuideCollapsed',
      ]),
      showDeleteAllButton(): boolean {
        return (
          !this.activeFolder.parent_id &&
          (this.activeFolder.type === 'trash' ||
            this.activeFolder.type === 'junk')
        );
      },
      composeRoute(): any {
        return {
          name: MAIL_COMPOSE,
          query: {
            ...this.$route.query,
            folder: this.activeFolder.id,
          },
        };
      },
      headerTitle(): string {
        return (this as any).getFolderName(this.activeFolder);
      },
      showSetupGuideCompletedModal(): boolean {
        return (
          this.isSetupGuideVisible &&
          this.setupGuideCards.length &&
          !this.setupGuideCards.some(
            (card: SetupGuideCard) => !card.is_completed
          ) &&
          !this.isSetupGuideCompletedSeen
        );
      },
    },
    methods: {
      toggleSetupGuideCompletedModal() {
        if (this.showSetupGuideCompletedModal) {
          // Show the setupguide modal 3 seconds after the user navigates
          // back to the inbox view. In order to have a festive UX
          setTimeout(() => {
            (this.$refs.setupGuideCompletedModal as any)?.openModal();
          }, 3000);
        }
      },
    },
    watch: {
      showSetupGuideCompletedModal() {
        this.toggleSetupGuideCompletedModal();
      },
    },
    mounted() {
      this.toggleSetupGuideCompletedModal();
    },
  });
