
  import Checkbox from '@/components/form/Checkbox.vue';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'FlipSwitch',
    components: {
      Checkbox,
    },
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      checked: {
        type: Boolean,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
      hasHiddenLabel: {
        type: Boolean,
      },
      controlled: {
        type: Boolean,
        default: false,
      },
    },
  });
