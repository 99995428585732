
  import { isAxiosError } from 'axios';
  import { mapActions } from 'vuex';
  import api from '@/api';
  import getErrorCode from '@/api/get-error-code';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import Vue from 'vue';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';

  export default Vue.extend({
    components: {
      DefaultDialog,
      LoadingButton,
      NotificationBlock,
      PasswordField,
    },
    data() {
      return {
        authenticationError: false,
        loading: false,
        password: '',
        validatePassword: false,
      };
    },
    computed: {
      passwordLabelText(): string {
        return this.$gettext('Your account password');
      },
      passwordValidationText(): string {
        return this.authenticationError
          ? this.$gettext('Wrong password')
          : this.$gettext('Something went wrong');
      },
    },
    watch: {
      password() {
        this.validatePassword = false;
        this.authenticationError = false;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      reset() {
        this.authenticationError = false;
        this.password = '';
        this.validatePassword = false;
      },
      resetPasswordField() {
        this.password = '';
        this.authenticationError = false;
        this.validatePassword = false;
      },
      async removeRecoveryCode(closeDialog: Function) {
        this.loading = true;
        try {
          await api.recovery.disableRecoveryKey({ password: this.password });
          this.setToastMessage({
            message: this.$gettext('Recovery by code disabled successfully'),
          });
          closeDialog();
        } catch (error) {
          if (
            isAxiosError(error) &&
            getErrorCode(error) === AUTHENTICATION_FAILURE
          ) {
            this.authenticationError = true;
            return;
          }
          this.setToastMessage({
            message: this.$gettext(
              'Sorry, we could not disable recovery by code. Please try again later.'
            ),
          });
          closeDialog();
        } finally {
          this.loading = false;
        }
      },
    },
  });
