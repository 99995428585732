
  import AppHeader from '@/components/AppHeader/AppHeader.vue';
  import ChargebeeHosted from '@/components/Chargebee/ChargebeeHosted.vue';
  import Sidebar from '@/wrappers/SidebarWrapper/SidebarWrapper';
  import { MAIL } from '@/router/named-routes';
  import { mapActions, mapGetters } from 'vuex';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'SubscriptionExpiredPage',
    components: {
      AppHeader,
      ChargebeeHosted,
      Sidebar,
    },
    computed: {
      ...mapGetters(['sidebarIsOpen']),
      buttonText() {
        return this.$gettext('Renew subscription');
      },
      supportLink() {
        return this.$gettext('https://support.startmail.com/');
      },
      renewSubscriptionText() {
        return this.$gettext(
          'Your subscription has expired. Renew your subscription to access your StartMail account.'
        );
      },
    },
    methods: {
      ...mapActions('authentication', ['logout', 'syncSubscription']),
      ...mapActions(['toggleSidebar', 'setToastMessage']),
      async checkoutError(this: any) {
        await this.syncSubscription();
        this.setToastMessage({
          message: this.$gettext(
            'We were unable to process your payment, please try again.'
          ),
        });
      },
      async onClickLogout(this: any) {
        await this.logout();
      },
      async goToMail() {
        await (this as any).syncSubscription();
        this.$router.push({ name: MAIL });
      },
    },
  });
