
  import RecordListItem from '@/components/RecordList/RecordListItem/RecordListItem.vue';
  import SkeletonList from '@/components/SkeletonList/SkeletonList.vue';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    components: { RecordListItem, SkeletonList },
    props: {
      records: {
        type: Array as PropType<DomainDNSRecord[]>,
        required: true,
      },
      fromVerifiedDomain: {
        type: Boolean,
        required: false,
        default: false,
      },
      checkingStatus: {
        type: Boolean,
        required: true,
      },
    },
  });
