
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import StatusBanner from '@/components/StatusBanner/StatusBanner.vue';
  import Checkbox from '@/components/form/Checkbox.vue';
  import InputField from '@/components/form/InputField.vue';
  import { getCookie, setCookie } from '@/lib/cookie';
  import { FEATURE_RECOVERY, FEATURE_SIGNUP } from '@/lib/featureFlags';
  import formatUsername from '@/lib/formatUsername';
  import { getItem, removeItem, setItem } from '@/lib/localStorage';
  import isSupportedBrowser from '@/lib/supportedBrowser';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import TotpForm from '@/pages/Login/TotpForm/TotpForm.vue';
  import { MAIL, PASSWORD_RECOVERY_INITIATE } from '@/router/named-routes';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { Location } from 'vue-router';
  import { mapActions } from 'vuex';

  // Key used to store username in local storage.
  const SM_USERNAME_KEY = 'sm-username';

  // User should be reminded about setting a recovery method only on 2nd login.
  const SHOW_RECOVERY_MODAL = 2;

  // Show account change modal on sub account first login
  const SHOW_SUBACCOUNT_MODAL = 1;

  // Steps involved in Login process.
  const STEP_LOGIN_FORM = 0;
  const STEP_TOTP_FORM = 1;

  export default Vue.extend({
    components: {
      AuthenticationLayout,
      Checkbox,
      Icon,
      InputField,
      LoadingButton,
      PasswordField,
      TotpForm,
      StatusBanner,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        username: ['development', 'test'].includes(
          process.env.NODE_ENV as string
        )
          ? 'dev@local.startmail.org'
          : '',
        password: ['development', 'test'].includes(
          process.env.NODE_ENV as string
        )
          ? 'gesundheit'
          : '',
        loginError: '',
        showLoginError: false,
        step: STEP_LOGIN_FORM,
        STEP_LOGIN_FORM,
        STEP_TOTP_FORM,
        FEATURE_RECOVERY,
        FEATURE_SIGNUP,
        rememberUsername: false,
        validateInput: false,
        userNameError: '',
        loginCount: 3,
        // This will be true if a query `?session-expired` is part of the url
        // (if a query without a value is part of the url it will be `null`)
        fromSessionExpired: this.$route.query['session-expired'] === null,
        backgroundImage: require('@/assets/img/paper-plane-illustration.svg'),
        is_manager: false,
      };
    },
    computed: {
      authenticationCodeText(): string {
        return this.$gettext('Authentication code');
      },
      blogLink(): string {
        return this.$gettext('https://www.startmail.com/blog/');
      },
      passwordText(): string {
        return this.$gettext('Password');
      },
      passwordRecoveryRoute(): Location {
        return {
          name: PASSWORD_RECOVERY_INITIATE,
          params: { email: this.username },
        };
      },
      rememberUsernameLabel(): string {
        return this.$gettext('Remember my email address');
      },
      signupUrl(): string {
        return this.$gettext('https://www.startmail.com/signup/');
      },
      usernameText(): string {
        return this.$gettext('Email address');
      },
      usernameValidationErrorMessage(): string {
        return this.$gettext('Email address is required.');
      },
    },
    watch: {
      username() {
        this.loginError = '';
      },
      password() {
        this.loginError = '';
      },
    },
    async created() {
      const rememberedUsername = await getItem(SM_USERNAME_KEY);
      if (rememberedUsername) {
        this.rememberUsername = true;
        this.username = rememberedUsername;
      }
    },
    methods: {
      ...mapActions('authentication', ['login']),
      onUsernameBlur() {
        this.username = formatUsername(this.username);
      },
      onFormSubmit: asyncAction(
        'authenticating',
        async function (this: any, ev: Event) {
          this.showLoginError = false;
          this.username = formatUsername(this.username);

          this.validateInput = true;
          // run client side validation before calling api
          if (!(ev.target as HTMLFormElement).checkValidity()) {
            return;
          }

          if (this.rememberUsername) {
            await setItem(SM_USERNAME_KEY, this.username);
          } else {
            await removeItem(SM_USERNAME_KEY);
          }

          return this.login({ email: this.username, password: this.password })
            .then(({ user }: { user: User }) => {
              this.is_manager = user.is_manager;
              if (user.totp_enabled) {
                this.step = STEP_TOTP_FORM;
              } else {
                this.processUserLogin(user.login_count);
              }
            })
            .catch((error: AxiosError) => {
              const errorCode = getErrorCode(error);

              if (errorCode === AUTHENTICATION_FAILURE) {
                this.loginError = this.$gettext(
                  'Email address and password do not match'
                );
                window.setTimeout(() => {
                  this.$refs.password.focus();
                }, 0);
              } else {
                this.showLoginError = true;
                throw error;
              }
            });
        }
      ),
      reRoute() {
        const originalRoute = this.$route.params
          .originalRoute as unknown as Location;
        if (!this.is_manager && this.loginCount === SHOW_SUBACCOUNT_MODAL) {
          this.$router.push({
            name: MAIL,
            query: { modal: 'welcome-team-account' },
          });
        } else if (originalRoute) {
          this.$router.push({
            name: originalRoute.name,
            params: originalRoute.params,
            query: originalRoute.query,
          });
        } else if (this.loginCount === SHOW_RECOVERY_MODAL) {
          this.showUpdateRecoveryModal();
        } else if (this.showUnsupportedBrowserWarningModal()) {
          this.$router.push({
            name: MAIL,
            query: { modal: 'unsupported-browser-warning' },
          });
        } else {
          this.$router.push({ name: MAIL });
        }
      },
      processUserLogin(loginCount: number) {
        this.loginCount = loginCount;
        this.reRoute();
      },
      showUpdateRecoveryModal() {
        /* Show recovery setup modal, but only if recovery is not already set up. */
        api.recovery
          .getRecoveryStatus()
          .then((recoveryInformation) => {
            if (recoveryInformation.recovery_address_confirmed) {
              this.$router.push({ name: MAIL });
            } else {
              this.$router.push({
                name: MAIL,
                query: { modal: 'update-recovery' },
              });
            }
          })
          .catch((error: AxiosError) => {
            this.$router.push({ name: MAIL });
            throw error;
          });
      },
      showUnsupportedBrowserWarningModal() {
        if (
          !getCookie('sm-unsupported-browser-warning') &&
          !isSupportedBrowser()
        ) {
          setCookie('sm-unsupported-browser-warning', 'show', 24 * 2);
          return true;
        }
      },
    },
  });
