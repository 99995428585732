
  import Vue, { PropType } from 'vue';
  import { mapState } from 'vuex';
  import formatCurrency from '@/lib/formatCurrency';
  import numToOrdinal from '@/lib/numToOrdinal';
  import RibbonBanner from '@/components/RibbonBanner.vue';

  export interface PlanDetail {
    id: number;
    minMembers: number;
    maxMembers: number;
    pricePerMonth: number;
    pricePerYear: number;
    isDiscount: boolean;
    discountAmount: number;
  }
  export default Vue.extend({
    name: 'GroupSubscriptionPlanCard',
    components: { RibbonBanner },
    props: {
      planDetail: { type: Array as PropType<PlanDetail[]>, required: true },
      isModal: { type: Boolean, default: false },
    },
    data() {
      return {
        isYearly: false,
      };
    },
    created() {
      this.isYearly = this.chargebeePlan?.billing_period === 'yearly';
    },
    computed: {
      ...mapState('subscription', ['currency', 'chargebeePlan']),
      perMonthText(): string {
        return this.$gettext('per month');
      },
      perYearText(): string {
        return this.$gettext('per year');
      },
    },
    methods: {
      formatCurrency,
      getOrdinalText(value: number): string {
        return value.toString() + numToOrdinal(value, this.$language.current);
      },
      getNumberOfAccountsHtml(plan: PlanDetail): string {
        const message =
          plan.minMembers == plan.maxMembers
            ? this.$gettext('%{ ordinal } account')
            : plan.maxMembers !== -1
              ? this.$gettext('%{ ordinalFrom }–%{ ordinalTo } account')
              : this.$gettext('%{ ordinal } account and beyond');
        const context = {
          ordinal: this.getOrdinalText(plan.minMembers),
          ordinalFrom: this.getOrdinalText(plan.minMembers),
          ordinalTo:
            plan.maxMembers !== -1 ? this.getOrdinalText(plan.maxMembers) : '',
        };
        return this.$gettextInterpolate(
          message,
          context,
          true // disableHtmlEscaping (b/c <sup> tags in ordinal text)
        );
      },
      getSaveText(plan: PlanDetail): string {
        const amount = formatCurrency(plan.discountAmount, this.currency);
        return this.$gettextInterpolate(
          this.$pgettext('money', 'Save %{ amount }'),
          { amount }
        );
      },
    },
  });
