import { render, staticRenderFns } from "./ComposeForm.vue?vue&type=template&id=f274ad0c"
import script from "./ComposeForm.vue?vue&type=script&lang=js"
export * from "./ComposeForm.vue?vue&type=script&lang=js"
import style0 from "./ComposeForm.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports