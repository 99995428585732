
  import api from '@/api';
  import * as CustomDomainErrorCodes from '@/api/custom-domains-error-codes';
  import { INVALID_DOMAIN } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import { substituteLink } from '@/lib/hyperlink';
  import * as Sentry from '@sentry/vue';
  import { isAxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import DefaultDialog from './DefaultDialog.vue';
  import BetterInput from './form/BetterInput.vue';
  import LoadingButton from './LoadingButton.vue';

  const defaultDomain =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
      ? 'local.startmail.org'
      : 'startmail.com';

  export const LOCAL_STORAGE_KEY = 'sm-signup-domain';

  export default Vue.extend({
    components: { BetterInput, DefaultDialog, Icon, LoadingButton },
    props: {
      error: { type: String, required: false, default: '' },
    },
    data() {
      return {
        localpart: '',
        domain: defaultDomain,
        state: 'split' as 'combined' | 'split',

        // custom domain verification dialog
        customDomain: '',
        customDomainError: '',
        loading: false,
        dialogState: 'initial' as 'initial' | 'pending-dns' | 'success',
        dnsTxtRecordValue: '',

        /* domain stuff */
        domainVerificationSession: undefined as
          | DomainVerificationSession
          | undefined,
        domainStatus: null as DomainStatus | null,
      };
    },
    mounted() {
      this.loadDomainSession();

      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          this.refreshDomainVerificationStatus();
        }
      });
    },
    beforeDestroy() {
      document.removeEventListener(
        'visibilitychange',
        this.refreshDomainVerificationStatus
      );
    },
    computed: {
      defaultDomain() {
        return defaultDomain;
      },
      dnsVerificationHTML(): string {
        return substituteLink(
          this.$gettext(
            'To use your domain name with StartMail, you must verify control of it. Log in to your domain’s registrar in a separate browser window and configure your DNS settings to include the record below. After adding the record, come back to this page to check the status. DNS updates usually take a few minutes to propagate but can take up to 24 hours. For a step-by-step guide, visit our <a>support center article</a>.'
          ),
          this.$gettext(
            'https://support.startmail.com/hc/en-us/articles/4403911432209'
          ),

          { external: true }
        );
      },
      username() {
        return this.localpart && this.domain
          ? `${this.localpart}@${this.domain}`
          : '';
      },
      txtRecordValue(): string | undefined {
        return this.domainVerificationSession
          ? `startmail-verification=${this.domainVerificationSession.verification_token}`
          : undefined;
      },
      verificationRecordStatus(): DomainDNSRecord | undefined {
        return this.domainStatus?.dns_records.find(
          (record) => record.verification
        );
      },
      statusHint(): string {
        switch (this.verificationRecordStatus?.error) {
          case CustomDomainErrorCodes.MISSING_VERIFICATION_RECORD:
            return this.$gettext('Please add the TXT record for verification.');
          case CustomDomainErrorCodes.INVALID_VERIFICATION_CODE:
            return this.$gettext(
              'Please correct the verification TXT record value.'
            );
          case CustomDomainErrorCodes.TIMEOUT:
            return this.$gettext(
              'A DNS timeout occurred. Wait a few minutes and then check again.'
            );
          default:
            return '';
        }
      },
    },
    watch: {
      username() {
        this.emit();
      },
      customDomain() {
        this.customDomainError = '';
      },
      domainVerificationSession() {
        this.emit();
      },
      error() {
        (this.$refs.localpart as HTMLInputElement).setCustomValidity(
          this.error ?? ''
        );
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      emit() {
        this.$emit('change', {
          username: this.username,
          domainVerificationSession: this.domainVerificationSession,
        });
      },
      async openDomainModal(showModal: Function) {
        showModal();
        if (this.customDomain) {
          await this.startVerification();
        }
      },
      onFocusIn() {
        this.state = 'split';
      },
      onFocusOut(event: FocusEvent) {
        if (
          event.relatedTarget === this.$refs.localpart ||
          event.relatedTarget === this.$refs.domain ||
          this.localpart === ''
        ) {
          // focus stays in the thing, so ignore the event
          return;
        }
        this.state = 'combined';
      },
      onInputLocalPart(event: Event) {
        const element = event.target as HTMLInputElement;
        element.value = element.value.replace(/@/g, '');
        this.localpart = element.value;
      },
      onPasteLocalPart(event: ClipboardEvent) {
        const paste = event.clipboardData?.getData('text');
        if (paste?.includes('@')) {
          event.preventDefault();
        }
      },
      async onSubmit(closeDialog: Function) {
        if (this.dialogState === 'initial') {
          await this.startVerification();
        } else if (this.dialogState === 'pending-dns') {
          await this.refreshDomainVerificationStatus();
        } else if (this.dialogState === 'success') {
          // `domain` is part of the username input itself, `customDomain` is
          // part of the dialog to change the domain
          this.domain = this.customDomain;
          closeDialog();
        }
      },

      async startVerification() {
        this.loading = true;
        this.customDomainError = '';
        try {
          if (this.domainVerificationSession?.domain !== this.customDomain) {
            this.domainVerificationSession =
              await api.domainVerification.createSession(this.customDomain);
          }
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(this.domainVerificationSession)
          );
          this.dialogState = 'pending-dns';
          await this.refreshDomainVerificationStatus();
        } catch (error: any) {
          if (isAxiosError(error) && error.status === 409) {
            this.customDomainError = this.$gettext('Domain not available');
          } else if (
            isAxiosError(error) &&
            getErrorCode(error) === INVALID_DOMAIN
          ) {
            this.customDomainError = this.$gettext('Invalid domain');
          } else {
            this.setToastMessage({ message: 'Something went wrong' });
            throw error;
          }
        } finally {
          this.loading = false;
        }
      },
      async refreshDomainVerificationStatus() {
        if (this.domainVerificationSession) {
          this.loading = true;
          try {
            [this.domainStatus] = await Promise.all([
              api.domainVerification.getStatus(
                this.domainVerificationSession.session_token
              ),
              new Promise((resolve) => setTimeout(resolve, 2000)),
            ]);
            this.dialogState = this.domainStatus.verified
              ? 'success'
              : 'pending-dns';
          } catch (error: any) {
            if (isAxiosError(error) && error.status === 404) {
              this.customDomain = '';
              localStorage.removeItem(LOCAL_STORAGE_KEY);
            }
            this.domainStatus = null;
            this.setToastMessage({
              message: 'Failed to refresh domain status',
            });
          } finally {
            this.loading = false;
          }
        }
      },
      async copyTxtRecordValue() {
        if (!this.txtRecordValue) {
          return;
        }
        let message;
        try {
          await navigator.clipboard.writeText(this.txtRecordValue);
          message = this.$gettext('Copied to clipboard');
        } catch {
          message = this.$gettext('Could not copy to clipboard');
        }
        await this.setToastMessage({ message });
      },
      async loadDomainSession() {
        const nonEmptyString = (x: any) => typeof x === 'string' && x.length;
        try {
          const stored = localStorage.getItem(LOCAL_STORAGE_KEY);
          if (!stored) return;
          const { domain, session_token, verification_token } =
            JSON.parse(stored);
          if (
            [domain, session_token, verification_token].every((v) =>
              nonEmptyString(v)
            )
          ) {
            this.customDomain = domain;
            this.domainVerificationSession = {
              domain,
              session_token,
              verification_token,
            };
            await this.refreshDomainVerificationStatus();
            if (this.dialogState === 'success') {
              this.domain = domain;
            } else {
              (this.$refs.dialog as any).showModal();
            }
          } else {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            return;
          }
        } catch (error) {
          this.customDomain = '';
          this.domainVerificationSession = undefined;
          Sentry.captureException(error);
        }
      },
    },
  });
