
  import Vue from 'vue';
  import AliasesSidebar from '@/components/AliasesSidebar/AliasesSidebar.vue';

  export default Vue.extend({
    components: {
      AliasesSidebar,
    },
    created() {
      this.$store.dispatch('aliases/loadAliases');
      if (this.$store.state.authentication.user.is_manager) {
        this.$store.dispatch('subscription/getGroupSubscriptions');
      }
    },
    computed: {
      title(): string {
        return this.$route.meta?.headerTitle
          ? this.$route.meta.headerTitle(this)
          : this.$route.meta?.title(this);
      },
    },
  });
