import '@/main.scss';

import Vue from 'vue';

import FloatingVue from 'floating-vue';
import GetTextPlugin from 'vue-gettext';
import MatomoPlugin from '@/plugins/matomo';
import PeriodicUpdatePlugin from '@/plugins/periodic-update';
import { configureSentry } from '@/plugins/error-logger';

import router from '@/router';
import store from '@/store';

import App from '@/App.vue';

import { configureSmDirectives } from '@/directives';
import registerServiceWorker from '@/serviceWorker';

import FeatureToggle from '@/components/FeatureToggle/FeatureToggle.vue';

import translations from '@/translations.json';

registerServiceWorker();

configureSmDirectives(Vue);

Vue.use(FloatingVue);
// Avoid touching the container (screen) boundaries. Value is $spacing-default;
// see corresponding max-width declaration in the CSS styling.
FloatingVue.options.themes.tooltip.overflowPadding = 16;

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_US: 'English (US)',
    de_DE: 'Deutsch',
  },
  defaultLanguage: 'en_US',
  silent: true,
  translations,
});

Vue.use(MatomoPlugin);

Vue.use(PeriodicUpdatePlugin);

// Configure Sentry (production only)
if (!['development', 'test'].includes(process.env.NODE_ENV as string)) {
  configureSentry(Vue);
}

Vue.config.productionTip = false;

Vue.component('FeatureToggle', FeatureToggle);

new Vue({
  el: '#app',
  components: { App },
  data: {
    isDevelopment: process.env.NODE_ENV === 'development',
    version: process.env.VUE_APP_VERSION,
  },
  router,
  store,
  render(h) {
    return h('App');
  },
});

if (process.env.NODE_ENV === 'production') {
  /* eslint-disable no-console */
  console.log(`StartMail version: ${process.env.VUE_APP_VERSION}`);
  console.log(
    "%cWarning! This is a browser feature for developers. If you were asked to enter anything here, somebody might want to take over your account. Stop if you don't know what it does.",
    'font-size: 200%; color: #f00;'
  );
  /* eslint-enable no-console */
}
