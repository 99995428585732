
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import Vue from 'vue';
  import { mapActions, mapState } from 'vuex';

  export default Vue.extend({
    name: 'SessionTimeout',
    components: {
      Modal,
      SelectInput,
      PasswordField,
      ModalConfirmActions,
    },
    data() {
      return {
        timeoutOptions: [
          {
            value: String(30 * 60),
            name: `30 ${this.$gettext('minutes')}`,
          },
          {
            value: String(60 * 60),
            name: `1 ${this.$gettext('hour')}`,
          },
          {
            value: String(4 * 60 * 60),
            name: `4 ${this.$gettext('hours')}`,
          },
          {
            value: String(8 * 60 * 60),
            name: `8 ${this.$gettext('hours')}`,
          },
          {
            value: String(12 * 60 * 60),
            name: `12 ${this.$gettext('hours')}`,
          },
          {
            value: String(24 * 60 * 60),
            name: `24 ${this.$gettext('hours')}`,
          },
        ],
        timeoutValue: String(
          this.$store.state.authentication.user.preferences
            .session_timeout_seconds
        ),
        passwordValue: '',
        currentPasswordValidationErrorMessage: '',
        loading: false,
        modalIsOpen: false,
        validate: false,
      };
    },
    computed: {
      ...mapState({
        timeoutInSeconds: (state): string =>
          (state as any).authentication.user.preferences
            .session_timeout_seconds,
      }),
      timeoutSelectLabelText(): string {
        return this.$gettext('Log me out after');
      },
      passwordLabelText(): string {
        return this.$gettext('Password');
      },
      okText(): string {
        return this.$gettext('Change logout period');
      },
      timeoutParagraphText(): string {
        return this.$gettextInterpolate(
          this.$gettext(
            'You will be automatically logged out %{ timeoutLabel } after you logged in.'
          ),
          {
            timeoutLabel: this.secondsToLabel(Number(this.timeoutInSeconds)),
          }
        );
      },
      headerText(): string {
        return this.$gettext('Automatic logout');
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['getPreferences']),
      secondsToLabel(seconds: number): string {
        const option = this.timeoutOptions.find(
          (option) => Number(option.value) === seconds
        );
        if (!option?.name)
          throw new Error(
            'Timout seconds do match any seconds for timeout options options: could not map seconds to a label'
          );
        return option?.name ? option.name : '';
      },
      async submitForm(toggle: () => void) {
        this.loading = true;
        if (!this.passwordValue) {
          this.validate = true;
          this.currentPasswordValidationErrorMessage = this.$gettext(
            'Password is required.'
          );
          this.loading = false;
          return;
        }
        this.resetValidation();
        try {
          await api.authentication.setSessionDuration({
            password: this.passwordValue,
            session_duration: Number(this.timeoutValue),
          });
          this.getPreferences();
          this.setToastMessage({
            message: this.$gettextInterpolate(
              this.$gettext(
                'Automatic logout period changed to %{ newTimeout }'
              ),
              {
                newTimeout: this.secondsToLabel(Number(this.timeoutValue)),
              }
            ),
          });
          toggle();
        } catch (error: any) {
          const loginErrorCode = getErrorCode(error);
          if (loginErrorCode === AUTHENTICATION_FAILURE) {
            this.validate = true;
            this.currentPasswordValidationErrorMessage =
              this.$gettext('Wrong password.');
            this.loading = false;
            (this as any).$refs.password.focus();
          } else {
            this.setToastMessage({
              message: this.$gettext(
                'Unable to change logout period, please try again'
              ),
            });
            this.loading = false;
            throw error;
          }
        }
      },
      resetValidation() {
        this.validate = false;
        this.currentPasswordValidationErrorMessage = '';
      },
      resetModal() {
        this.loading = false;
        this.resetValidation();
        this.passwordValue = '';
      },
    },
    watch: {
      passwordValue() {
        this.resetValidation();
      },
    },
  });
