
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';
  import CustomAliasModal from './CustomAliasModal.vue';
  import Icon from '@/components/Icon/Icon.vue';

  export default Vue.extend({
    components: {
      CustomAliasModal,
      Icon,
    },
    props: {
      type: {
        type: String as PropType<string>,
        required: true,
        validator(value: any) {
          return ['personal', 'group'].includes(value);
        },
      },
    },
    computed: {
      addButtonLabel(): string {
        return this.type === 'personal'
          ? this.$gettext('Create personal alias')
          : this.$gettext('Create domain alias');
      },
      createAllowed(): boolean {
        return (
          this.type === 'personal' ||
          this.$store.getters['authentication/isManager'] // domain aliases
        );
      },
    },
    methods: {
      ...mapActions('aliases', ['loadAliases']),
    },
  });
