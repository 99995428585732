
  import api from '@/api';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import InputField from '@/components/form/InputField.vue';
  import { MAIL, SETTINGS_DOMAINS } from '@/router/named-routes';
  import Vue from 'vue';
  import { mapState } from 'vuex';

  export default Vue.extend({
    name: 'Feedback',
    components: {
      AuthenticationLayout,
      InputField,
    },
    data() {
      return {
        backgroundImage: require('@/assets/img/envelope-illustration.svg'),
        details: '',
      };
    },
    computed: {
      ...mapState('signup', ['domainToken']),
      feedbackLabel(): string {
        return this.$gettext('How did you first learn about StartMail?');
      },
    },
    mounted() {
      this.$matomoTrackEvent('signup', 'recovery-code', 'signup-recovery-code');
    },
    methods: {
      onSubmit() {
        if (this.details.trim()) {
          try {
            api.signup.feedback({
              source: 'other',
              details: this.details.trim(),
              language: this.$language.current,
            });
          } catch (error) {
            // ignore
          }
        }
        this.gotoInbox();
      },
      async gotoInbox() {
        const name = this.domainToken ? SETTINGS_DOMAINS : MAIL;
        await this.$router.push({ name, query: { modal: 'welcome' } });
      },
    },
  });
