let cbSite: string | null = null;
let loadChargebeePromise: Promise<any> | null = null;

function loadChargebee(site: string): Promise<any> {
  if (!loadChargebeePromise) {
    const publishableKey =
      site === 'startmail'
        ? process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_LIVE
        : process.env.VUE_APP_CHARGEBEE_PUBLISHABLE_KEY_TEST;

    loadChargebeePromise = new Promise<any>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://js.chargebee.com/v2/chargebee.js';
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    }).then(() => {
      return (window as any).Chargebee.init({ publishableKey, site });
    });
  }
  return loadChargebeePromise;
}

export async function getChargebee(site: string) {
  if (cbSite && cbSite !== site) {
    throw new Error(
      `Attempt to switch from Chargebee site "${cbSite}" to "${site}"`
    );
  }
  cbSite = site;
  const cbInstance = await loadChargebee(site);
  await cbInstance.load('functions');
  return cbInstance;
}
