
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { MAIL, SETTINGS_ACCOUNT } from '@/router/named-routes';
  import Vue from 'vue';
  import { mapState } from 'vuex';

  export default Vue.extend({
    name: 'RecoveryEmailConfirmation',
    components: {
      AuthenticationLayout,
      LoadingButton,
      PasswordField,
    },
    mixins: [asyncActionsMixin],
    props: {
      queryUsername: {
        type: String,
        default: '',
      },
      confirmationKey: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        loginError: '',
        password: '',
        showConfirmation: false,
        recoveryEmail: null as string | null,
        confirmationPending: false,
        backgroundImage: require('@/assets/img/lock-illustration.svg'),
        MAIL,
        SETTINGS_ACCOUNT,
      };
    },
    computed: {
      ...mapState('authentication', ['user']),
      authenticatedUsername(): string | undefined {
        return this.user?.email;
      },
      username(): string {
        const retval = this.queryUsername || this.authenticatedUsername;
        if (!retval) {
          throw new Error(
            'RecoveryEmailConfirmation cannot find a username. This should not happen.'
          );
        }
        return retval;
      },
      passwordText(): string {
        return this.$gettext('Your password');
      },
    },
    watch: {
      password() {
        this.loginError = '';
      },
    },
    async created() {
      await this.getRecoveryStatus();
    },
    methods: {
      onConfirm: asyncAction('confirming', function (this: any, ev: Event) {
        // run client side validation before calling api
        const target = ev.target as HTMLFormElement;
        if (!target.checkValidity()) {
          return;
        }

        return api.recovery
          .confirmRecoveryAddress({
            username: this.username,
            confirmationKey: this.confirmationKey,
            password: this.password,
          })
          .then(() => {
            /* TODO this may return a new_recovery_key and we are not showing
             * that to the user!!! We would instead not let them turn on email
             * recovery if the package is not available in their vaults. They
             * would then be forced by us to first regenerate their recovery
             * code. This way they are not surprised by a new recovery code when
             * they confirm their email address.
             */
            this.showConfirmation = true;
          })
          .catch((error) => {
            const loginErrorCode = getErrorCode(error);
            if (loginErrorCode === AUTHENTICATION_FAILURE) {
              this.loginError = this.$gettext('Wrong password.');
              window.setTimeout(() => {
                this.$refs.password.focus();
              }, 0);
            } else {
              this.loginError = this.$gettext(
                'Sorry, we could not confirm your recovery email. Please try again later.'
              );
              throw error;
            }
          });
      }),
      async getRecoveryStatus(): Promise<void> {
        const recoveryInformation = await api.recovery.getRecoveryStatus({
          username: this.username,
          confirmationKey: this.confirmationKey,
        });
        this.confirmationPending =
          !!recoveryInformation.recovery_address &&
          !recoveryInformation.recovery_address_confirmed;
        this.recoveryEmail = recoveryInformation.recovery_address;
      },
    },
  });
