
  import { isAxiosError } from 'axios';
  import { mapActions } from 'vuex';
  import api from '@/api';
  import getErrorCode from '@/api/get-error-code';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import InputField from '@/components/form/InputField.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import Vue from 'vue';
  import {
    ALIAS_USED_AS_RECOVERY_EMAIL,
    AUTHENTICATION_FAILURE,
    UNEXPECTED_RESPONSE_FROM_ACCOUNT_SERVER,
  } from '@/api/error-codes';

  export default Vue.extend({
    name: 'UpdateRecoveryEmailModal',
    components: {
      DefaultDialog,
      InputField,
      LoadingButton,
      NotificationBlock,
      PasswordField,
    },
    data() {
      return {
        isValidated: false,
        password: '',
        recoveryAddress: '',
        validationError: null as null | number,
        showConfirmation: false,
        submitting: false,
      };
    },
    computed: {
      newEmailText(): string {
        return this.$gettext('New recovery email address');
      },
      passwordLabelText(): string {
        return this.$gettext('Your account password');
      },
      isAuthenticationError(): boolean {
        return this.validationError === AUTHENTICATION_FAILURE;
      },
      emailValidationText(): string {
        if (this.validationError === null || this.isAuthenticationError)
          return '';

        if (this.validationError === ALIAS_USED_AS_RECOVERY_EMAIL) {
          return this.$gettext(
            'Alias email addresses cannot be used for recovery.'
          );
        } else if (!this.recoveryAddress) {
          return this.$gettext('An email address is required.');
        }
        // If none of the previous checks are true, that means the entered value is in wrong format. And validationError was not
        // set as form validation failed and the request to api was not made.
        return this.$gettext('This is an invalid email address.');
      },
      passwordValidationText(): string {
        return this.validationError === AUTHENTICATION_FAILURE
          ? this.$gettext('Wrong password.')
          : '';
      },
    },

    watch: {
      recoveryAddress() {
        this.isValidated = false;
        this.validationError = null;
      },
      password() {
        this.isValidated = false;
        this.validationError = null;
      },
    },

    methods: {
      ...mapActions(['setToastMessage']),
      resetModal() {
        this.isValidated = false;
        this.password = '';
        this.recoveryAddress = '';
        this.showConfirmation = false;
        this.validationError = null;
      },
      async onSubmit() {
        this.isValidated = true;

        try {
          await api.recovery.enableRecoveryAddress({
            recoveryAddress: this.recoveryAddress,
            password: this.password,
          });
          this.showConfirmation = true;
        } catch (error) {
          if (!isAxiosError(error)) throw error;
          this.validationError = getErrorCode(error);
          if (
            !this.validationError ||
            this.validationError === UNEXPECTED_RESPONSE_FROM_ACCOUNT_SERVER
          ) {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not send a confirmation email. Please try again later.'
              ),
            });
          }
        }
      },
    },
  });
