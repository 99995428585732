
  import api from '@/api';
  import axios from 'axios';
  import formatCurrency from '@/lib/formatCurrency';
  import Vue, { PropType } from 'vue';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import { PlanCard } from '@/components/Settings/PlanChooser.vue';
  import { mapActions, mapGetters } from 'vuex';
  import getErrorCode from '@/api/get-error-code';
  import { CHARGEBEE_PAYMENT_FAILED } from '@/api/error-codes';
  import LoadingButton from '@/components/LoadingButton.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import { getChargebee } from '@/lib/chargebee';
  import { mixin as timeMixin } from '@/lib/time';
  import Spinner from '@/components/Spinner/Spinner.vue';

  interface InvoiceEstimate {
    sub_total: number;
    total: number;
    credits_applied: number;
    amount_paid: number;
    amount_due: number;
    currency_code: string;
  }

  interface ChargebeeEstimate {
    invoice_estimate?: InvoiceEstimate;
    next_invoice_estimate?: InvoiceEstimate;
    subscription_estimate: {
      next_billing_at: number;
      status: string;
    };
  }

  export default Vue.extend({
    components: {
      DefaultDialog,
      LoadingButton,
      NotificationBlock,
      PasswordField,
      Spinner,
    },
    mixins: [timeMixin],
    props: {
      card: { type: Object as PropType<PlanCard>, required: true },
      legacy: { type: Boolean as PropType<boolean>, required: true },
    },
    data() {
      return {
        estimate: null as ChargebeeEstimate | null,
        loading: false,
        password: '',
        wrongPassword: false,
      };
    },
    watch: {
      password() {
        this.wrongPassword = false;
      },
    },
    computed: {
      ...mapGetters('features', ['chargebeeSite']),
      ...mapGetters('authentication', ['isInTrial']),
      buttonText(): string {
        if (this.card.current) {
          return this.$gettext('Your current plan');
        } else if (!this.card.available) {
          return this.$gettext('Too many domains');
        } else {
          return this.$gettextInterpolate(
            this.$gettext('Switch to %{ plan }'),
            { plan: this.card.plan.name }
          );
        }
      },
      invoiceEstimate(): InvoiceEstimate | undefined {
        return (
          this.estimate?.invoice_estimate ??
          this.estimate?.next_invoice_estimate
        );
      },
      wrongPasswordText(): string {
        return this.$gettext('Wrong password.');
      },
      passwordLabelText(): string {
        return this.$gettext('Confirm with your account password');
      },
      planId(): string {
        return `${this.card.variant.period}-${this.card.plan.type}-${this.card.variant.currency}`.toLowerCase();
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      formatCurrency,
      async loadEstimate(): Promise<void> {
        this.estimate = null;
        this.password = '';
        this.wrongPassword = false;

        const cbInstance = await getChargebee(this.chargebeeSite);
        const id =
          this.$store.state.authentication?.user?.chargebee_subscription_id;
        if (!id) {
          return;
        }

        ({ estimate: this.estimate } =
          await cbInstance.estimates.updateSubscriptionEstimate({
            prorate: true,
            subscription: { id, plan_id: this.planId },
          }));
      },
      async switchPlan(event: Event, close: Function): Promise<void> {
        this.loading = true;
        try {
          await api.chargebee.changePlan({
            password: this.password,
            plan_id: this.planId,
          });
          close();
          this.setToastMessage({
            message: this.$gettext('Plan changed successfully'),
          });
          this.$emit('change');
        } catch (err) {
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 401) {
              this.wrongPassword = true;
              return;
            } else if (getErrorCode(err) === CHARGEBEE_PAYMENT_FAILED) {
              this.setToastMessage({
                message: this.$gettext(
                  'Payment failed. Check payment methods.'
                ),
              });
              close();
              return;
            }
          }
          this.setToastMessage({
            message: this.$gettext('Something went wrong. Try again later.'),
          });
          close();
          throw err;
        } finally {
          this.loading = false;
        }
      },
    },
  });
