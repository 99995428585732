
  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';
  import AccountDetails from '@/components/AccountDetails/AccountDetails.vue';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import StorageBar from '@/components/StorageBar/StorageBar.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import SessionTimeout from '@/components/SessionTimeout/SessionTimeout.vue';
  import TotpSetUpModal from '@/components/TotpSetUpModal/TotpSetUpModal.vue';
  import Icon from '@/components/Icon/Icon.vue';

  import { mixin as timeMixin } from '@/lib/time';
  import {
    DATE_FORMAT_OPTIONS,
    TIME_FORMAT_OPTIONS,
    getTimeFormat,
    getDateFormat,
  } from '@/lib/dateTimeFormats';

  export default Vue.extend({
    name: 'AccountSettings',
    components: {
      AccountDetails,
      FlipSwitch,
      StorageBar,
      SelectInput,
      SessionTimeout,
      TotpSetUpModal,
      Icon,
    },
    mixins: [timeMixin],
    data() {
      return {
        languageOptions: Object.keys(this.$language.available).map(
          (languageCode) => ({
            name: this.$language.available[languageCode],
            value: languageCode,
          })
        ),
      };
    },
    mounted() {
      this.$nextTick(() => {
        if (
          this.redirectedFromSetupCard &&
          this.$refs.twoFactorAuthentication
        ) {
          let twoFactorPanelElement = this.$refs
            .twoFactorAuthentication as HTMLDivElement;
          twoFactorPanelElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      });
    },
    computed: {
      ...mapState({
        darkMode: (state: any) => state.settings.darkMode,
      }),
      ...mapGetters('authentication', ['user']),
      languageLabel() {
        return this.$gettext('Language');
      },
      timeFormat: {
        get(): string {
          return getTimeFormat(this.user.preferences.time_format);
        },
        async set(format: string): Promise<void> {
          await this.updatePreferences({
            update: {
              time_format: format,
            },
          });
          this.getPreferences();
        },
      },
      timeLabel(): string {
        return this.$gettext('Time Format');
      },
      timeSettingOptions(): HTMLSelectOption[] {
        return TIME_FORMAT_OPTIONS;
      },
      dateFormat: {
        get(): string {
          return getDateFormat(this.user.preferences.date_format);
        },
        async set(format: string): Promise<void> {
          await this.updatePreferences({
            update: {
              date_format: format,
            },
          });
          this.getPreferences();
        },
      },
      dateLabel(): string {
        return this.$gettext('Date Format');
      },
      dateSettingOptions(): HTMLSelectOption[] {
        return DATE_FORMAT_OPTIONS;
      },
      language: {
        get(): string {
          return this.$language.current;
        },
        set(language: string): void {
          this.setLanguage({ language });
        },
      },
      accountStorageLink(): string {
        return this.$gettextInterpolate(
          this.$gettext(
            'https://support.startmail.com/hc/en-us/articles/%{ article }'
          ),
          { article: '360007067938' }
        );
      },
      redirectedFromSetupCard(): boolean {
        return window.location.hash.includes('two-factor-authentication');
      },
    },
    methods: {
      ...mapActions(['setLanguage', 'setDarkMode']),
      ...mapActions('authentication', ['getPreferences', 'updatePreferences']),
    },
  });
