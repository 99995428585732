
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import { LOGIN } from '@/router/named-routes';
  import Vue from 'vue';
  import { Location } from 'vue-router';

  export default Vue.extend({
    name: 'Confirmation',
    components: {
      AuthenticationLayout,
      Icon,
    },
    computed: {
      loginRoute(): Location {
        return { name: LOGIN };
      },
      seriously(): string {
        // this is a computed so that we can render it directly as a text node
        // without rendering another html element which leads to annoying space
        // handling (no spaces between the spans)
        return this.$gettext(
          'We take security seriously, and therefore our password recovery is a manual process.'
        );
      },
      supportArticleLink(): string {
        return this.$gettext(
          'https://support.startmail.com/hc/en-us/articles/360007388898'
        );
      },
    },
  });
