<template>
  <Modal
    ref="modal"
    v-bind="$attrs"
    :has-header="false"
    dialog-class="welcome"
    :modal-is-open="isOpen"
    narrow
    @modalToggled="emitToggleEvent"
  >
    <template #content>
      <div class="welcome__img">
        <slot name="image" />
      </div>
      <div class="welcome__content">
        <h4 class="spacing-bottom">
          <slot name="header" />
        </h4>
        <slot name="content" />
      </div>
    </template>
  </Modal>
</template>

<script>
  import Vue from 'vue';
  import Modal from '@/components/Modal.vue';

  export default Vue.extend({
    name: 'QueryModal',
    components: {
      Modal,
    },
    props: {
      initializeOpen: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isOpen: this.initializeOpen,
      };
    },
    methods: {
      closeModal() {
        this.$refs.modal.toggleModal();
      },
      openModal() {
        this.$refs.modal.toggleModal();
      },
      emitToggleEvent(modalOpenValue) {
        this.$emit('modalToggled', modalOpenValue);
      },
    },
  });
</script>

<style lang="scss">
  .welcome__img {
    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .welcome__content {
    display: flex;
    flex-direction: column;
    padding: $spacing-default $spacing-double;
  }

  .welcome {
    .modal__content,
    .modal__content-inner {
      padding: 0;
    }
  }
</style>
