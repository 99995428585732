
  import { WEB_DB_CONTACT_GROUP_ALREADY_EXISTS } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import InputField from '@/components/form/InputField.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'ContactGroupActions',
    components: {
      Icon,
      InputField,
      Modal,
      ModalConfirmActions,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        name: '',
        validateContactGroupName: false,
        contactGroupAlreadyExists: false,
      };
    },
    computed: {
      addText(): string {
        return this.$gettext('Add contact group');
      },
      newContactGroupNameText(): string {
        return this.$gettext('New contact group name');
      },
      contactGroupErrorMessage(): string {
        return this.contactGroupAlreadyExists
          ? this.$gettext('Contact group with that name already exists')
          : this.$gettext('Contact group name is required.');
      },
    },
    watch: {
      name() {
        this.contactGroupAlreadyExists = false;
        this.validateContactGroupName = false;
      },
    },
    methods: {
      ...mapActions(['addContactGroup', 'setToastMessage']),
      createContactGroup: asyncAction(
        'adding',
        function (this: any, ev: Event, toggle: Function) {
          this.validateContactGroupName = true;
          const target = ev.target as HTMLFormElement;
          if (!target.checkValidity()) {
            return;
          }
          return this.addContactGroup({ contactGroup: { name: this.name } })
            .then((contactGroup: any) => {
              toggle();
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext('Contact group %{ groupName } created'),
                  { groupName: contactGroup.name }
                ),
              });
            })
            .catch((err: AxiosError) => {
              if (getErrorCode(err) === WEB_DB_CONTACT_GROUP_ALREADY_EXISTS) {
                this.contactGroupAlreadyExists = true;
                return;
              }
              toggle();
              this.setToastMessage({
                message: this.$gettext('Could not add contact group'),
              });
              throw err;
            });
        }
      ),
      resetData() {
        this.name = '';
        this.validateContactGroupName = false;
      },
    },
  });
