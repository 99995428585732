
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import { LOGIN } from '@/router/named-routes';
  import formatLongNumber from '@/lib/formatLongNumber';

  export default Vue.extend({
    name: 'NewRecoveryCode',
    components: {
      AuthenticationLayout,
      Icon,
    },
    props: {
      recoveryCode: {
        type: String,
        required: true,
      },
      email: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        showPrintButton: !!window.print,
        backgroundImage: require('@/assets/img/lock-illustration.svg'),
      };
    },
    computed: {
      formattedRecoveryCode(): string {
        return formatLongNumber(this.recoveryCode);
      },
    },
    created(this: any): void {
      // When users reload the page, or otherwise manage to get an empty
      // recoveryCode prop, then we push them to login because there is
      // nothing we can do for them here anymore.
      if (!this.recoveryCode || !this.recoveryCode.length) {
        this.$router.push({ name: LOGIN });
      }
    },
    methods: {
      ...mapActions(['setToastMessage']),
      async copyRecoveryCode() {
        try {
          await navigator.clipboard.writeText(this.recoveryCode);
          this.setToastMessage({
            message: this.$gettext('Your recovery code is copied'),
          });
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      printRecoveryCode() {
        window.print();
      },
      async onFormSubmit(this: any) {
        return this.$router.push({ name: LOGIN });
      },
    },
  });
