var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DefaultDialog',{attrs:{"narrow":"","closable":!_vm.loading},scopedSlots:_vm._u([{key:"showModal",fn:function({ showModal }){return [_c('button',{staticClass:"button",class:{
        'button--primary': !_vm.card.current && _vm.card.available,
        'button--accent': _vm.card.current || !_vm.card.available,
      },attrs:{"disabled":_vm.card.current || !_vm.card.available},domProps:{"textContent":_vm._s(_vm.buttonText)},on:{"click":function($event){_vm.loadEstimate();
        showModal();}}})]}},{key:"header",fn:function(){return [_c('translate',[_vm._v("Switch plan")])]},proxy:true},{key:"default",fn:function({ close }){return [_c('form',{attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.switchPlan($event, close)}}},[_c('div',{staticClass:"contents"},[(_vm.isInTrial)?_c('p',{directives:[{name:"translate",rawName:"v-translate"}],key:"a"},[_vm._v(" You can switch plans freely during your trial period. ")]):_c('p',{directives:[{name:"translate",rawName:"v-translate"}],key:"b"},[_vm._v(" You are about to switch plans. You will only be charged for the difference in price between your current and your new plan, and only for the remainder of your current term. ")]),(!_vm.invoiceEstimate)?_c('Spinner',{staticClass:"spinner"}):[_c('dl',[_c('dt',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"new-plan"},[_vm._v("New plan")]),(_vm.estimate)?_c('dd',[(_vm.card.plan.type === 'personal')?[_c('translate',{attrs:{"translate-context":"plan"}},[_vm._v("Personal")])]:(_vm.card.plan.type === 'business')?[_c('translate',{attrs:{"translate-context":"plan"}},[_vm._v("Business")])]:_vm._e(),_vm._v(" "+_vm._s(' ')+" "),(_vm.card.variant.period === 'yearly')?[_c('translate',{attrs:{"translate-context":"billing period"}},[_vm._v(" (yearly) ")])]:(_vm.card.variant.period === 'monthly')?[_c('translate',{attrs:{"translate-context":"billing period"}},[_vm._v(" (monthly) ")])]:_vm._e()],2):_vm._e(),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"date"},[_vm._v("Invoice date")]),_c('dd',{domProps:{"textContent":_vm._s(_vm.formatDate(_vm.invoiceEstimate.date * 1000, 'll'))}}),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Price (prorated)")]),_c('dd',{domProps:{"textContent":_vm._s(
                _vm.formatCurrency(
                  _vm.invoiceEstimate.sub_total,
                  _vm.invoiceEstimate.currency_code
                )
              )}}),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Discount")]),_c('dd',{domProps:{"textContent":_vm._s(
                _vm.formatCurrency(
                  _vm.invoiceEstimate.total - _vm.invoiceEstimate.sub_total,
                  _vm.invoiceEstimate.currency_code
                )
              )}}),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"total"},[_vm._v("Total")]),_c('dd',{staticClass:"total",domProps:{"textContent":_vm._s(
                _vm.formatCurrency(
                  _vm.invoiceEstimate.total,
                  _vm.invoiceEstimate.currency_code
                )
              )}}),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Credits applied")]),_c('dd',{domProps:{"textContent":_vm._s(
                _vm.formatCurrency(
                  _vm.invoiceEstimate.credits_applied,
                  _vm.invoiceEstimate.currency_code
                )
              )}}),_c('dt',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"amount-due"},[_vm._v("Amount due")]),_c('dd',{staticClass:"amount-due",domProps:{"textContent":_vm._s(
                _vm.formatCurrency(
                  _vm.invoiceEstimate.amount_due,
                  _vm.invoiceEstimate.currency_code
                )
              )}})]),(_vm.isInTrial)?_c('NotificationBlock',{attrs:{"variant":"info"}},[_c('translate',[_vm._v(" You will not be charged until the end of your free trial period. ")])],1):_vm._e(),(_vm.legacy)?_c('NotificationBlock',{attrs:{"variant":"info"}},[_c('translate',[_vm._v(" After switching to a new plan, you will not be able to switch back to your legacy plan. ")])],1):_vm._e(),_c('PasswordField',{attrs:{"id":`password-${_vm.card.plan.type}`,"autocomplete":"current-password","required":"","label-text":_vm.passwordLabelText,"show-custom-validity":_vm.wrongPassword,"validate-input":_vm.wrongPassword,"validation-error-message":_vm.wrongPasswordText},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]],2),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"button",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return close.apply(null, arguments)}}},[_c('translate',[_vm._v("Cancel")])],1),_c('LoadingButton',{staticClass:"button button--primary",attrs:{"type":"submit","disabled":!_vm.estimate,"loading":_vm.loading}},[(_vm.isInTrial || _vm.invoiceEstimate?.amount_due === 0)?_c('translate',{key:"1"},[_vm._v(" Confirm ")]):_c('translate',{key:"2"},[_vm._v("Confirm and pay")])],1)],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }