
  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';
  import formatCurrency from '@/lib/formatCurrency';
  import RibbonBanner from '@/components/RibbonBanner.vue';
  import GroupSubscriptionPlanCard, {
    PlanDetail,
  } from '@/components/GroupSubscription/GroupSubscriptionPlanCard.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccountBanner',
    components: {
      GroupSubscriptionPlanCard,
      RibbonBanner,
    },
    data() {
      return {
        isAddAccountModalOpen: false,
        accountPrice: '',
        accountDiscount: '',
        open: false,
        availablePlans: [] as PlanDetail[],
      };
    },
    created() {
      this.setBannerPricing();
      this.initializePlan();
    },
    computed: {
      ...mapState('subscription', [
        'chargebeePlan',
        'currency',
        'groupSubscription',
      ]),
      ...mapGetters('subscription', [
        'isAccountCreateAllowed',
        'nextAccountTier',
        'numberOfAccounts',
      ]),
      okText(): string {
        return this.$gettext('Create email account');
      },
      youCanAddText(): string {
        const message =
          this.chargebeePlan?.billing_period === 'yearly'
            ? this.$gettext(
                'You can add another account for %{ price } per year.'
              )
            : this.$gettext(
                'You can add another account for %{ price } per month.'
              );
        return this.$gettextInterpolate(message, { price: this.accountPrice });
      },
      saveText(): string {
        return this.$gettextInterpolate(
          this.$pgettext('money', 'Save %{ amount }'),
          { amount: this.accountDiscount }
        );
      },
    },
    watch: {
      groupSubscription: {
        handler() {
          this.setBannerPricing();
        },
        deep: true,
      },
    },
    methods: {
      formatCurrency,
      setBannerPricing() {
        if (!this.nextAccountTier) {
          return;
        }

        this.accountPrice = this.formatCurrency(
          this.nextAccountTier.price,
          this.currency
        );

        const addOns =
          this.chargebeePlan.addons && this.chargebeePlan.addons.length
            ? this.chargebeePlan.addons[0].price
            : 0;
        const basePrice = this.chargebeePlan.tiers[0].price + addOns;

        this.accountDiscount = this.formatCurrency(
          basePrice - this.nextAccountTier.price,
          this.currency
        );
      },
      buildPlanDetail(
        id: number,
        plan: PlanTier,
        addonPrice: number,
        isDiscount: boolean,
        currentYearlyPrice: number
      ) {
        const totalPrice = plan.price + addonPrice;
        return {
          id: id,
          minMembers: plan.starting_unit,
          maxMembers: plan.ending_unit ?? -1,
          pricePerMonth:
            this.chargebeePlan?.billing_period === 'yearly'
              ? Math.ceil(totalPrice / 12)
              : totalPrice,
          pricePerYear: totalPrice,
          isDiscount: isDiscount,
          discountAmount: isDiscount ? currentYearlyPrice - totalPrice : 0,
        };
      },
      initializePlan() {
        let basePrice = this.chargebeePlan?.tiers[0].price;
        if (this.chargebeePlan?.addons && this.chargebeePlan?.addons.length) {
          basePrice = basePrice + this.chargebeePlan?.addons[0].price;
        }
        this.availablePlans =
          this.chargebeePlan?.tiers.map((plan: PlanTier, index: number) => {
            if (plan.starting_unit === 1 && plan.ending_unit === 1) {
              const addonPrice =
                this.chargebeePlan?.addons && this.chargebeePlan?.addons.length
                  ? this.chargebeePlan?.addons[0].price
                  : 0;
              return this.buildPlanDetail(index, plan, addonPrice, false, 0);
            } else {
              return this.buildPlanDetail(index, plan, 0, true, basePrice);
            }
          }) ?? [];
      },
    },
  });
