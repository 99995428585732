<template>
  <li :class="{ 'nested-folders-item': hasChildren }">
    <FolderItem
      :item="folder"
      :is-loading="isLoading"
      :count="getCount({ folder, isOpen })"
      :show-current-tag="showCurrentTag"
      :shown-in-sidebar="shownInSidebar"
      :class="{
        'folder-item--has-children': !!hasChildren,
        'folder-item--is-opened': isOpen,
      }"
      @folderItemClicked="$emit('folderItemClicked', folder)"
    >
      <template #settingsActionsForFolder="{ item }">
        <slot name="settingsActionsForFolder" :item="item" />
      </template>
    </FolderItem>

    <template v-if="hasChildren">
      <button
        type="button"
        class="button button--clean button--icon-only nested-folders-item__toggle-child"
        :class="{ 'nested-folders-item__toggle-child--active': isOpen }"
        @click="toggleExpansion()"
        :aria-label="toggleNestedFolderText"
        :aria-expanded="String(isOpen)"
        :aria-controls="`collapsible-${folder.id}`"
        v-test:nestedFolderItemToggle
      >
        <Icon symbol="arrow-right" />
      </button>

      <transition name="collapsible-slide-in">
        <ul v-show="isOpen" class="nested-folders-item__child-folders">
          <NestedFoldersItem
            v-for="childFolder in folder.children"
            :key="childFolder.id"
            :force-expanded="forceExpanded"
            :folder="childFolder"
            :salt="salt"
            :opened-path="childOpenedPath"
            :shown-in-sidebar="shownInSidebar"
            :show-current-tag="showCurrentTag"
            :preserve-expansion="preserveExpansion"
            @folderItemClicked="
              $emit(
                'folderItemClicked',
                arguments.length ? arguments[0] : childFolder
              )
            "
          >
            <template #settingsActionsForFolder="{ item }">
              <slot name="settingsActionsForFolder" :item="item" />
            </template>
          </NestedFoldersItem>
        </ul>
      </transition>
    </template>
  </li>
</template>

<script>
  import { getItem, setItem } from '@/lib/localStorage';
  import folderUtilsMixin from '@/mixins/folder-utils-mixin';
  import FolderItem from '@/components/FolderItem/FolderItem';
  import Icon from '@/components/Icon/Icon';

  export default {
    name: 'NestedFoldersItem',
    components: {
      Icon,
      FolderItem,
      // import component like this, because the component is recursive see: https://vuejs.org/v2/guide/components.html#Recursive-Components
      NestedFoldersItem: () =>
        import('@/components/NestedFoldersItem/NestedFoldersItem'),
    },
    mixins: [folderUtilsMixin],
    props: {
      folder: {
        type: Object,
        required: true,
      },
      forceExpanded: {
        type: Boolean,
        required: false,
        default: false,
      },
      preserveExpansion: {
        type: Boolean,
        required: false,
        default: false,
      },
      openedPath: {
        type: Array,
        required: false,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      salt: {
        type: String,
        required: false,
        default: '',
      },
      showCurrentTag: {
        type: Boolean,
        default: false,
      },
      shownInSidebar: {
        type: Boolean,
        default: false,
      },
      sidebarIsOpen: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    computed: {
      toggleNestedFolderText() {
        return this.isOpen
          ? this.$gettext('Close nested folders')
          : this.$gettext('Open nested folders');
      },
      childOpenedPath() {
        return this.openedPath.slice(1);
      },
      hasChildren() {
        return this.folder.children && this.folder.children.length;
      },
    },
    async mounted() {
      if (this.forceExpanded) {
        this.isOpen = true;
      } else if (this.preserveExpansion && this.salt) {
        this.isOpen = (await getItem(this.folder.id, this.salt)) ?? false;
      }
    },
    methods: {
      async toggleExpansion() {
        this.isOpen = !this.isOpen;
        if (this.preserveExpansion && this.salt) {
          await setItem(this.folder.id, this.isOpen, this.salt);
          await setItem(this.salt, true);
        }
      },
      getCount({ isOpen, folder }) {
        const countKey =
          folder.type === 'drafts' ? 'messages_count' : 'unread_messages_count';
        const countRecursive = (folder) =>
          folder[countKey] + countInFolders(folder.children);
        const countInFolders = (folders) =>
          folders.reduce(
            (unread, folder) => unread + countRecursive(folder),
            0
          );

        return isOpen ? folder[countKey] : countRecursive(folder);
      },
    },
  };
</script>

<style src="./NestedFoldersItem.scss" lang="scss"></style>
