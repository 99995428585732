/**
 * Add a single external hyperlink to a HTML snippet.
 *
 * This is intended to simplify translatable messages with an external
 * link, e.g. a link to a support center article.
 *
 * The input HTML should contain a single <a> tag, without any
 * attributes, e.g. ‘Oops! Check the <a>support article</a>.’
 *
 * @see substituteLinks
 */
export function substituteLink(
  innerHTML: string,
  href: string,
  { external = false } = {}
) {
  const el = document.createElement('div');
  el.innerHTML = innerHTML;
  el.getElementsByTagName('a')[0].setAttribute('href', 'href');
  return substituteLinks(el.innerHTML, { hrefs: { href }, external });
}

/**
 * Add external hyperlinks to a HTML snippet.
 *
 * This is intended to simplify translatable messages with external
 * links.
 *
 * The input HTML should contain ‘<a href=xyz>’ tags without further
 * attributes; the ‘xyz’ reference must be present in the ‘hrefs’
 * parameter which maps the shorthand values to the the full
 * (translated) target urls.
 *
 * @see substituteLink
 */
export function substituteLinks(
  innerHTML: string,
  {
    hrefs = {},
    external = false,
  }: {
    hrefs?: Record<PropertyKey, string>;
    external?: boolean;
  } = {}
) {
  const el = document.createElement('div');
  el.innerHTML = innerHTML;
  for (const link of el.getElementsByTagName('a')) {
    // Note: .getAttribute() gets the raw value, unlike .href
    const key = link.getAttribute('href');
    if (!key) throw new Error('missing href');
    link.href = hrefs[key]; // substitute using mapping
    if (external) {
      link.target = '_blank';
      link.relList.add('noopener', 'noreferrer');
    }
  }
  return el.innerHTML;
}
