export const BAD_SPF_MULTIPLE_RECORDS = 'bad_spf_multiple_records';
export const BAD_SPF_WITH_REDIRECT = 'bad_spf_with_redirect';
export const CNAME_NOT_ALLOWED = 'cname_not_allowed';
export const INVALID_DKIM_CNAME = 'invalid_dkim_cname';
export const INVALID_VERIFICATION_CODE = 'invalid_verification_code';
export const MISSING_DKIM_CNAME = 'missing_dkim_cname';
export const MISSING_MX = 'missing_mx';
export const MISSING_SPF_CHUNK = 'missing_spf_chunk';
export const MISSING_SPF_RECORD = 'missing_spf_record';
export const MISSING_VERIFICATION_RECORD = 'missing_verification_record';
export const NXDOMAIN = 'nxdomain';
export const OUTDATED_MX_ERROR = 'outdated_mx_error';
export const OUTDATED_MX_WARNING = 'outdated_mx_warning';
export const THIRDPARTY_MX = 'thirdparty_mx';
export const TIMEOUT = 'timeout';
