
  import Icon from '@/components/Icon/Icon.vue';
  import type { Toast } from '@/store/toast';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    components: { Icon },
    computed: {
      toast(): Toast | undefined {
        return this.$store.state.toast.toast;
      },
    },
    methods: {
      ...mapActions('toast', ['toastCancelAutoClose', 'toastDismiss']),
    },
    watch: {
      ['toast.timeoutId'](newValue) {
        if (newValue !== undefined && this.$el instanceof HTMLElement) {
          // Ensure CSS animations (re)trigger, e.g. when a new toast
          // replaces another.
          this.$el.getAnimations({ subtree: true }).forEach((animation) => {
            animation.currentTime = 0;
          });
        }
      },
    },
  });
