
  import Vue, { PropType } from 'vue';
  import Logo from '@/components/Logo/Logo.vue';

  export default Vue.extend({
    components: {
      Logo,
    },
    props: {
      empty: {
        type: Boolean,
        default: false,
      },
      backgroundImage: {
        type: [String, Object],
        default: () => require('@/assets/img/envelope-illustration.svg'),
      },
      backgroundImagePosition: {
        type: String as PropType<string>,
        default: 'bottom-right',
        validator: (value: string) => {
          return ['bottom-left', 'bottom-center', 'bottom-right'].includes(
            value
          );
        },
      },
      wide: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    created() {
      const element = document.querySelector('.scroll-to');
      if (element) {
        element.scrollTo(0, 1);
      }
    },
  });
