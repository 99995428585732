
  import * as CustomDomainErrorCodes from '@/api/custom-domains-error-codes';
  import Icon from '@/components/Icon/Icon.vue';
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'RecordListItem',
    components: { Icon },
    props: {
      record: {
        type: Object as PropType<DomainDNSRecord>,
        required: true,
      },
      checkingStatus: {
        type: Boolean,
        required: true,
      },
      fromVerifiedDomain: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      copyText(): string {
        return this.$gettext('Copy to clipboard');
      },
      errorMessage(this: any): string {
        if (
          this.checkingStatus ||
          !this.record.error ||
          this.isCompletedVerificationRecord
        ) {
          return '';
        }
        return this.getStatusHint(this.record);
      },
      isCompletedVerificationRecord(): boolean {
        return this.fromVerifiedDomain && this.record.verification;
      },
      statusLabelText(): string {
        if (this.checkingStatus) {
          return this.$gettext('Checking DNS…');
        } else if (this.record.error && !this.isCompletedVerificationRecord) {
          return this.getStatusText(this.record.error);
        }
        return this.$gettext('Correct');
      },
      statusLabelClass(): string {
        if (this.checkingStatus) return 'warning';
        if (this.isCompletedVerificationRecord) return 'success';
        if (!this.record.acceptable) return 'danger';
        if (this.record.error) return 'warning';
        return 'success';
      },
      priorityText(): string {
        const priority =
          this.record.suggestion_prio || this.record.current_prio;
        return priority
          ? this.$gettextInterpolate(
              this.$gettext('Priority = %{ priority }'),
              { priority }
            )
          : '';
      },
      value(): string {
        return this.record.suggestion_text || this.record.current_text || '';
      },
      nameHostText(): string {
        return this.record.label || this.$gettext('@ (or leave blank)');
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      async copy(value: string) {
        try {
          await navigator.clipboard.writeText(value);
          this.setToastMessage({
            message: this.$gettext('Value copied to clipboard'),
          });
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      getStatusText(error: string): string {
        switch (error) {
          case CustomDomainErrorCodes.BAD_SPF_WITH_REDIRECT:
          case CustomDomainErrorCodes.INVALID_VERIFICATION_CODE:
          case CustomDomainErrorCodes.MISSING_DKIM_CNAME:
          case CustomDomainErrorCodes.MISSING_MX:
          case CustomDomainErrorCodes.MISSING_SPF_RECORD:
          case CustomDomainErrorCodes.MISSING_VERIFICATION_RECORD:
          case CustomDomainErrorCodes.NXDOMAIN:
            return this.$gettext('Missing');

          case CustomDomainErrorCodes.MISSING_SPF_CHUNK:
            return this.$gettext('Incorrect');

          case CustomDomainErrorCodes.OUTDATED_MX_WARNING:
            return this.$gettext('Outdated');

          case CustomDomainErrorCodes.BAD_SPF_MULTIPLE_RECORDS: // TODO
          case CustomDomainErrorCodes.INVALID_DKIM_CNAME:
          case CustomDomainErrorCodes.OUTDATED_MX_ERROR:
          case CustomDomainErrorCodes.THIRDPARTY_MX:
            return this.$gettext('Remove');

          case CustomDomainErrorCodes.CNAME_NOT_ALLOWED: // TODO
          case CustomDomainErrorCodes.TIMEOUT: // TODO
          default:
            return this.$gettext('Error');
        }
      },
      getStatusHint(record: DomainDNSRecord): string {
        switch (record.error) {
          case CustomDomainErrorCodes.MISSING_VERIFICATION_RECORD:
            return this.$gettext('Please add the TXT record for verification.');
          case CustomDomainErrorCodes.INVALID_VERIFICATION_CODE:
            return this.$gettext(
              'Please correct the verification TXT record value.'
            );

          case CustomDomainErrorCodes.MISSING_SPF_RECORD:
            return this.$gettext('Please add the TXT record for SPF.');
          case CustomDomainErrorCodes.BAD_SPF_MULTIPLE_RECORDS:
            return this.$gettext(
              'You have multiple TXT records with SPF values. Make sure you have only one with the value above.'
            );
          case CustomDomainErrorCodes.MISSING_SPF_CHUNK:
          case CustomDomainErrorCodes.BAD_SPF_WITH_REDIRECT:
            return this.$gettext(
              'Please make sure your SPF record includes the StartMail specific section.'
            );
          case CustomDomainErrorCodes.MISSING_DKIM_CNAME:
            return this.$gettext('Please add the DKIM CNAME record.');
          case CustomDomainErrorCodes.CNAME_NOT_ALLOWED:
            return this.$gettext(
              'A CNAME record exists for your domain, which prohibits adding MX records. Please remove this record from your DNS.'
            );
          case CustomDomainErrorCodes.MISSING_MX:
            return this.$gettext('Please add the MX record.');
          case CustomDomainErrorCodes.OUTDATED_MX_WARNING:
          case CustomDomainErrorCodes.OUTDATED_MX_ERROR:
          case CustomDomainErrorCodes.THIRDPARTY_MX:
          case CustomDomainErrorCodes.INVALID_DKIM_CNAME:
            return this.$gettext(this.$gettext('Please remove this record.'));

          case CustomDomainErrorCodes.NXDOMAIN:
            return this.$gettext(
              'This domain does not exist. Please check that the domain is correct.'
            );

          case CustomDomainErrorCodes.TIMEOUT:
            return this.$gettext(
              'A DNS timeout occurred. Wait a few minutes and then check again.'
            );

          default:
            return '';
        }
      },
    },
  });
