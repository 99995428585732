
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import * as namedRoutes from '@/router/named-routes';
  import { substituteLink } from '@/lib/hyperlink';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionWarning',
    components: {
      NotificationBlock,
    },
    data() {
      return {
        namedRoutes,
      };
    },
    computed: {
      ...mapGetters('subscription', ['accountCreateNotAllowedReasons']),
      reasons(): string[] {
        return (this as any).accountCreateNotAllowedReasons as string[]; // sigh typescript
      },
      messageHtml(): string {
        let message;
        const href = '/mail/compose?mailTo=support@startmail.com';
        if (this.reasons.includes('is_trial')) {
          message = this.$gettext(
            "You can't purchase additional accounts while still in trial. To use this feature, first start your paid subscription."
          );
        } else if (this.reasons.includes('has_unpaid_invoices')) {
          message = this.$gettext(
            "You can't purchase additional accounts if you have unpaid invoices. Please check your payment details."
          );
        } else if (this.reasons.includes('has_no_payment_method')) {
          message = this.$gettext(
            "You can't purchase additional accounts because you don't have any payment methods stored."
          );
        } else {
          message = this.$gettext(
            "We're sorry, but you can't purchase additional accounts right now. If you have questions please contact us at <a>support@startmail.com</a>."
          );
        }
        if (message.includes('<a>')) {
          message = substituteLink(message, href);
        }
        return message;
      },
      showSubscriptionPageLink(): boolean {
        // These rejection reasons are actionable via the subscription settings.
        const actionableReasons = [
          'is_trial',
          'has_unpaid_invoices',
          'has_no_payment_method',
        ];
        return this.reasons.some((r) => actionableReasons.includes(r));
      },
    },
  });
