import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      contactCategoryOptions: [
        {
          value: 'home',
          name: this.$gettext('Home'),
        },
        {
          value: 'work',
          name: this.$gettext('Work'),
        },
        {
          value: 'other',
          name: this.$gettext('Other'),
        },
      ],
    };
  },
  methods: {
    getContactCategoryName(value: string) {
      const category = this.contactCategoryOptions.find(
        (category) => category.value === value
      );
      return category?.name ?? '';
    },
  },
});
