
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'Checkbox',
    inheritAttrs: false,
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      checked: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasHiddenLabel: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      indeterminate: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
      validate: {
        type: Boolean,
        default: false,
      },
      validationErrorMessage: {
        type: String,
        default: '',
      },
      controlled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        shiftKeyPressed: false,
        valid: false,
      };
    },
    computed: {
      native(): HTMLInputElement {
        return this.$refs.native as HTMLInputElement;
      },
      isInvalid(): boolean {
        return this.validate && !this.valid;
      },
    },
    watch: {
      checked() {
        this.valid = this.native.checkValidity();
      },
    },
    mounted() {
      this.valid = this.native.checkValidity();
    },
    methods: {
      onClick(event: MouseEvent) {
        if (this.native.disabled) {
          return;
        }
        // default browser behaviour is to select a range of text so we have to prevent that
        document.getSelection()?.removeAllRanges();
        this.shiftKeyPressed = event.shiftKey;
        this.$emit('change', !this.checked, this.shiftKeyPressed); // shift + click in ff does not trigger change, so we fire change programmatically
        this.shiftKeyPressed = false;
      },
      onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        if (this.indeterminate) {
          target.checked = false;
        } else if (this.controlled) {
          target.checked = this.checked;
        }
        this.$emit('change', target.checked);
      },
    },
  });
