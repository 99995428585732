
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ContactSupport',
    props: {
      title: {
        type: String,
        required: false,
        default: '',
      },
      message: {
        type: String,
        required: false,
        default: '',
      },
    },
    computed: {
      contactFormLink(): string {
        return this.$gettext(
          'https://support.startmail.com/hc/en-us/requests/new'
        );
      },
      defaultTitle(): string {
        return this.$gettext('Oops, something went wrong');
      },
      defaultMessage(): string {
        return this.$gettext('Your account could not be created.');
      },
    },
  });
