
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import Icon from '@/components/Icon/Icon.vue';
  import InputField from '@/components/form/InputField.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import MultiSelectInput from '@/components/MultiSelectInput/MultiSelectInput.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import contactCategoryOptionsMixin from '@/mixins/contact-category-options-mixin';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import TextAreaField from '@/components/form/TextAreaField.vue';

  export default Vue.extend({
    components: {
      Icon,
      InputField,
      SelectInput,
      MultiSelectInput,
      ModalConfirmActions,
      TextAreaField,
    },
    mixins: [contactCategoryOptionsMixin, asyncActionsMixin],
    props: {
      toggleModal: {
        type: Function,
        required: true,
      },
      beforeModalClose: {
        type: Function,
        required: true,
      },
      setModalLoading: {
        type: Function,
        required: true,
      },
      contact: {
        // The input contact data to edit using the form, this must not be
        // changed. Instead it is used to initialise the form fields.
        type: Object,
        required: false,
        default: null,
      },
    },
    data() {
      // Initialise contactData to a copy of the input contact or to an empty
      // partial contact. When initialising an empty (new) contact, then set the
      // favorite and contact_group_ids fields based on the currently selected
      // contact group.
      let contactData: Contact = {
        id: '',
        display_name: '',
        name: '',
        company_name: '',
        contact_group_ids: [],
        email_addresses: [],
        phone_number: '',
        job_title: '',
        website: '',
        address: '',
        notes: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        po_box: '',
        always_show_external_images: false,
        always_sign: false,
        always_encrypt: false,
        favorite: false,
      };

      if (this.contact) {
        contactData = {
          ...contactData,
          // NB: do not remove, this is so we don't modify the vuex state from
          // within this form
          ...JSON.parse(JSON.stringify(this.contact)),
        };
      } else {
        const groupId = this.$store.state.contactGroups.activeContactGroupId;
        const userGroups = this.$store.getters.contactUserGroups;
        const aUserGroupIsActive = userGroups.some(
          (group: ContactGroup) => group.id === groupId
        );

        // new contacts are created as members of the currently active group
        contactData.contact_group_ids = aUserGroupIsActive ? [groupId] : [];
        contactData.favorite = groupId === 'favorites';
      }

      // Make sure at least one set of email address input fields is visible.
      if (!contactData.email_addresses?.length) {
        contactData.email_addresses = [
          {
            fingerprints: [],
            is_primary: true,
            label: 'home',
            value: '',
          },
        ];
      }

      return {
        contactData,
        formIsValidated: false,
        hasUnsavedChanges: false,
      };
    },
    computed: {
      contactGroupOptions(): HTMLSelectOption[] {
        return (this.$store.getters.contactUserGroups as ContactGroup[]).map(
          (group) => ({
            value: group.id,
            name: group.name,
          })
        );
      },
      emailText(): string {
        return this.$gettext('Email address');
      },
      fullNameText(): string {
        return this.$gettext('Full name');
      },
      companyNameText(): string {
        return this.$gettext('Company name');
      },
      phoneNumberText(): string {
        return this.$gettext('Phone number');
      },
      selectLabelText(): string {
        return this.$gettext('Select label');
      },
      emailAddressesPrimaryText(): string {
        return this.$gettext('Primary');
      },
      cityText(): string {
        return this.$gettext('City');
      },
      stateText(): string {
        return this.$gettext('State/Province');
      },
      countryText(): string {
        return this.$gettext('Country');
      },
      emailAddressLabelText(): string {
        return this.$gettext('Label');
      },
      zipCodeText(): string {
        return this.$gettext('Zip code');
      },
      saveButtonText(): string {
        return this.contactData?.id
          ? this.$gettext('Save contact')
          : this.$gettext('Add contact');
      },
      emailPlaceholderText(): string {
        return this.$gettext('e.g. someone@example.org');
      },
      websitePlaceholderText(): string {
        return this.$gettext('e.g. www.example.org');
      },
      removeEmailAddressText(): string {
        return this.$gettext('Remove this email address');
      },
      contactGroupsText(): string {
        return this.$gettext('Contact groups');
      },
      contactGroupsPlaceholder(): string {
        return this.$gettext('Select contact group');
      },
      jobTitleText(): string {
        return this.$gettext('Job title');
      },
      websiteText(): string {
        return this.$gettext('Website');
      },
      notesText(): string {
        return this.$gettext('Notes');
      },
      addressText(): string {
        return this.$gettext('Address');
      },
      addressLineOneText(): string {
        return this.$gettext('Address line 1');
      },
      addressLineTwoText(): string {
        return this.$gettext('Address line 2');
      },
      invalidEmailErrorMessage(): string {
        return this.$gettext('Incorrect email address');
      },
    },
    beforeCreate() {
      // Load Contact group as well so that Contact groups dropdown will be
      // filled in with Contact group names while opened from message -
      // recipient badge.
      if (!this.$store.state.contactGroupsLoaded) {
        this.$store.dispatch('getContactGroups');
      }
    },
    created() {
      this.beforeModalClose(this.onBeforeModalClose);
    },
    methods: {
      ...mapActions(['addContact', 'setToastMessage', 'updateContact']),
      onChangePrimary(emailAddress: any) {
        this.contactData.email_addresses?.forEach((email) => {
          email.is_primary = false;
        });
        emailAddress.is_primary = true;
      },
      onInput() {
        this.hasUnsavedChanges = true;
      },
      onBeforeModalClose({ preventDefault }: { preventDefault: () => void }) {
        if (!this.hasUnsavedChanges) {
          return;
        }

        if (
          !window.confirm(
            this.$gettext(
              'You have unsaved changes. Do you want to cancel anyway?'
            )
          )
        ) {
          preventDefault();
        }
      },
      onFormSubmit: asyncAction('saving', async function (this: any) {
        // Remove empty email address "rows".
        this.contactData.email_addresses =
          this.contactData.email_addresses.filter(
            (email: EmailAddress) => email.value
          );

        // Set the top email address primary if there is no other primary.
        if (
          this.contactData.email_addresses.length &&
          !this.contactData.email_addresses.some(
            (email: EmailAddress) => email.is_primary
          )
        ) {
          this.contactData.email_addresses[0].is_primary = true;
        }

        this.formIsValidated = true;

        if (!(this.$refs.contactForm as HTMLFormElement).checkValidity()) {
          const modal = document.querySelector('.modal__content');
          if (modal) {
            modal.scrollTo(0, 1);
          }
          return false;
        }

        this.setModalLoading(true);

        try {
          if (this.contactData.id) {
            const contact = await this.updateContact({
              id: this.contactData.id,
              contact: this.contactData,
            });
            this.setToastMessage({
              message: this.$gettext('Contact has been updated'),
            });
            this.$emit('saved', { contact });
          } else {
            const contact = await this.addContact({
              contact: this.contactData,
            });
            this.setToastMessage({
              message: this.$gettext('Contact has been added'),
            });
            this.$emit('saved', { contact });
          }

          this.beforeModalClose(null);
          this.toggleModal();
        } catch (err) {
          this.setToastMessage({
            message: this.$gettext('Sorry, we could not save your contact'),
          });
        } finally {
          this.setModalLoading(false);
        }
      }),
      removeEmailAddress(index: number) {
        this.contactData.email_addresses?.splice(index, 1);
      },
      onCancelClicked() {
        this.toggleModal();
      },
      addEmailAddress() {
        const homeLabelUsed = this.contactData.email_addresses?.some(
          ({ label }) => label === 'home'
        );
        const workLabelUsed = this.contactData.email_addresses?.some(
          ({ label }) => label === 'work'
        );
        const label = homeLabelUsed
          ? workLabelUsed
            ? 'other'
            : 'work'
          : 'home';

        if (!this.contactData.email_addresses) {
          this.contactData.email_addresses = [];
        }
        this.contactData.email_addresses.push({
          label,
          value: '',
          fingerprints: [],
          is_primary: false,
        });
      },
    },
  });
