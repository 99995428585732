
  import Vue from 'vue';
  import Checkbox from '@/components/form/Checkbox.vue';
  import Modal from '@/components/Modal.vue';

  export default Vue.extend({
    name: 'ExternalLinkWarning',
    components: {
      Checkbox,
      Modal,
    },
    props: {
      externalLinkWarning: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        externalSource: '',
        hideExternalLinkWarning: false,
      };
    },
    computed: {
      hideExternalLinkWarningLabel(): string {
        return this.$gettext('Do not show this message again');
      },
      modal(): any {
        return this.$refs.modal;
      },
    },
    watch: {
      hideExternalLinkWarning(value: boolean) {
        this.$root.$emit('setExternalLinkWarning', {
          externalLinkWarning: !value,
        });
      },
    },
    methods: {
      catchExternalLink(ev: Event) {
        let link: HTMLAnchorElement | undefined = ev
          .composedPath() // works with shadowDOM
          .find(
            (el) => (el as HTMLElement).tagName === 'A'
          ) as HTMLAnchorElement;

        if (
          link &&
          link.protocol.startsWith('http') &&
          window.location.hostname !== link.hostname &&
          this.externalLinkWarning
        ) {
          ev.preventDefault();
          this.externalSource = link.href;
          if (!this.modal?.isOpen) {
            this.modal.toggleModal();
          }
        }
      },
    },
  });
