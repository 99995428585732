
  import Icon from '@/components/Icon/Icon.vue';
  import InputField from '@/components/form/InputField.vue';
  import { CONTACTS_SEARCH, MAIL_SEARCH } from '@/router/named-routes';
  import {
    HIDE_SEARCH_INPUT,
    SHOW_SEARCH_INPUT,
  } from '@/store/search/search-mutation-types';
  import { UI_MEDIAQUERIES } from '@/ui-mediaqueries';
  import Mousetrap from 'mousetrap';
  import Vue from 'vue';
  import { mapMutations } from 'vuex';
  const desktopMediaQuery = window.matchMedia(
    UI_MEDIAQUERIES.SINGLE_LINE_BREAKPOINT
  );
  const mediaQuery = window.matchMedia(UI_MEDIAQUERIES.SINGLE_LINE_BREAKPOINT);

  export default Vue.extend({
    name: 'SearchInputField',
    components: {
      Icon,
      InputField,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      initialQuery: {
        type: String,
        required: false,
        default: '',
      },
    },
    data() {
      return {
        searchQuery: this.initialQuery,
        desktopMode: desktopMediaQuery.matches,
      };
    },
    computed: {
      searchToggled(): boolean {
        return this.$store.state.search.searchToggled;
      },
      searchMode(): boolean {
        return (
          this.$route.name === MAIL_SEARCH ||
          this.$route.name === CONTACTS_SEARCH
        );
      },
      searchButtonLabel(): string {
        return this.$pgettext('action button', 'Search');
      },
      clearSearchButtonLabel(): string {
        return this.$gettext('Clear search box');
      },
    },
    watch: {
      $route() {
        this.matchSearchQueryToRoute();
      },
    },
    created() {
      if (this.searchQuery) {
        this.showSearchInput();
      } else {
        this.onMediaQueryChanged(mediaQuery);
      }
      this.$nextTick(() => {
        // $nextTick required to fix order of bind/unbind calls. When switching
        // between Mail and Contacts settings, the destroyed/beforeDestroy hooks
        // run after the created hook for the new component instance. This means
        // the unbind happens after the bind and the handler ends up not being
        // bound! (The mediaquery event listeners are unaffected by this race
        // condition.)
        Mousetrap.bind('/', this.showSearchField);
      });

      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', this.onMediaQueryChanged);
        desktopMediaQuery.addEventListener('change', this.handleResize);
      } else {
        mediaQuery.addListener(this.onMediaQueryChanged);
        desktopMediaQuery.addListener(this.handleResize);
      }
    },
    destroyed() {
      Mousetrap.unbind('/');
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', this.onMediaQueryChanged);
        desktopMediaQuery.removeEventListener('change', this.handleResize);
      } else {
        mediaQuery.removeListener(this.onMediaQueryChanged);
        desktopMediaQuery.removeListener(this.handleResize);
      }
    },
    methods: {
      ...mapMutations({
        hideSearchInput: HIDE_SEARCH_INPUT,
        showSearchInput: SHOW_SEARCH_INPUT,
      }),
      showSearchField(event?: Event) {
        event?.preventDefault();
        this.showSearchInput();
        this.$nextTick(() => {
          // Need $nextTick or otherwise the element isn't shown yet
          // ($refs.input === undefined)! Note this is not an HTMLInputElement,
          // but a InputField vue component
          (this.$refs.input as any)?.focus();
        });
      },
      onBlur() {
        if (this.searchQuery.length === 0) {
          this.hideSearchInput();
        }
      },
      abortSearch() {
        // If there is text in the search box, then reset the search and prepare
        // the UI for a new one. If the box was empty, then unfocus it.
        if (this.searchQuery.length > 0) {
          this.searchQuery = '';
          (this.$refs.input as any)?.focus();
        } else {
          (this.$refs.input as any)?.blur();
        }
      },
      matchSearchQueryToRoute() {
        if (this.searchMode) {
          this.showSearchInput();
          if (
            this.$route.query.query &&
            !Array.isArray(this.$route.query.query)
          ) {
            this.searchQuery = this.$route.query.query;
          }
        } else {
          this.hideSearchInput();
          this.searchQuery = '';
        }
      },
      onMediaQueryChanged(e: MediaQueryListEvent | MediaQueryList) {
        e.matches ? this.showSearchInput() : this.hideSearchInput();
      },
      handleResize(ev: MediaQueryListEvent) {
        this.desktopMode = ev.matches;
      },
    },
  });
