
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { Location } from 'vue-router/types/router';
  import { mapActions } from 'vuex';
  import api from '@/api';
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Captcha from '@/components/Captcha/Captcha.vue';
  import InputField from '@/components/form/InputField.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import formatUsername from '@/lib/formatUsername';
  import AsyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import {
    LOGIN,
    PASSWORD_RECOVERY_CONFIRMATION,
    PASSWORD_RECOVERY_SET_NEW_PASSWORD,
  } from '@/router/named-routes';

  export default Vue.extend({
    name: 'Initiate',
    components: {
      AuthenticationLayout,
      Captcha,
      InputField,
      LoadingButton,
    },
    mixins: [AsyncActionsMixin],
    props: {
      email: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        captchaToken: '' as string,
        username: this.email,
        usernameErrorMessage: '',
        validate: false,
        recoveryMethod: '',
        backgroundImage: require('@/assets/img/lock-illustration.svg'),
      };
    },
    computed: {
      loginRoute(): Location {
        return { name: LOGIN };
      },
      usernameLabel(): string {
        return this.$gettext('Your StartMail email address');
      },
      usernameValidationErrorMessage(): string {
        return (
          this.usernameErrorMessage ||
          this.$gettext('Email address is required.')
        );
      },
    },
    watch: {
      username() {
        this.usernameErrorMessage = '';
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      onUsernameBlur() {
        this.username = formatUsername(this.username);
      },
      requestRecoveryEmail: asyncAction(
        'requestingRecoveryEmail',
        function (this: any, ev: Event) {
          this.username = formatUsername(this.username);
          this.validate = true;

          if (!(ev.target as HTMLFormElement).checkValidity()) {
            this.$refs.captcha.resetHcaptcha();
            return;
          }

          const email = this.username;
          return api.recovery
            .requestRecoveryEmail({ email, captchaToken: this.captchaToken })
            .then(() => {
              this.$router.push({ name: PASSWORD_RECOVERY_CONFIRMATION });
            })
            .catch((err: AxiosError) => {
              this.$refs.captcha.resetHcaptcha();

              if (err.response && err.response.status === 403) {
                this.usernameErrorMessage = this.$gettext(
                  'Email address unknown'
                );
                return;
              }

              this.setToastMessage({
                message: this.$gettext(
                  'Something went wrong. Please try again later.'
                ),
              });
            });
        }
      ),
      showCaptchaErrorMessage(this: any) {
        this.setToastMessage({
          message: this.$refs.captcha.captchaErrorMessage,
        });
      },
      setCaptchaToken(token: string) {
        this.captchaToken = token;
      },
      showRecoveryByCodeForm() {
        this.$router.push({
          name: PASSWORD_RECOVERY_SET_NEW_PASSWORD,
          params: { email: this.username },
        });
      },
      showRecoveryByEmailForm() {
        this.recoveryMethod = 'by-email';
      },
      resetCaptchaToken() {
        this.captchaToken = '';
      },
    },
  });
