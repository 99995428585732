
  import Vue from 'vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import { SETTINGS_DOMAINS } from '@/router/named-routes';

  export default Vue.extend({
    name: 'CompleteDomainModal',
    components: {
      Modal,
      ModalConfirmActions,
      Icon,
    },
    props: {
      isOpen: { type: Boolean, default: false },
      title: { type: String, default: '' },
      paragraph: { type: String, default: '' },
    },
    data() {
      return {
        toggleModal: () => {},
      };
    },
    methods: {
      close() {
        this.$emit('modalToggled');
      },
    },
    computed: {
      domainsSettingsRoute(): { name: string } {
        return {
          name: SETTINGS_DOMAINS,
        };
      },
      primaryButtonText(): string {
        return this.$gettext('Complete your domain setup');
      },
      headerText(): string {
        return this.$gettext('Enable IMAP');
      },
      okText(): string {
        return this.$gettext('OK');
      },
    },
  });
