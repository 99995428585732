
  import Vue from 'vue';
  import api from '@/api';
  import { mapActions, mapGetters } from 'vuex';
  import Icon from '@/components/Icon/Icon.vue';
  import SetupCard from '@/components/SetupCard/SetupCard.vue';

  export enum CardId {
    ImportEmails = 'import_emails',
    EnableIMAP = 'enable_imap_access',
    EnableTotp = 'enable_totp',
    AddAlias = 'add_alias',
  }
  const mobileMediaQuery = window.matchMedia('screen and (max-width: 849px)');

  export default Vue.extend({
    components: {
      Icon,
      SetupCard,
    },

    data() {
      return {
        cards: [
          CardId.ImportEmails,
          CardId.EnableIMAP,
          CardId.EnableTotp,
          CardId.AddAlias,
        ],
        isMobileView: mobileMediaQuery.matches,
      };
    },
    async created() {
      await this.getPreferences();
    },
    async mounted() {
      window.addEventListener('resize', this.setBodyHeightStyle);
      await this.$nextTick();

      this.updateBodyHeightStyleAfterFontsLoaded();
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setBodyHeightStyle);
    },
    computed: {
      ...mapGetters('authentication', [
        'setupGuideCards',
        'isSetupGuideCollapsed',
      ]),
      ...mapGetters(['sidebarIsOpen']),
      cancelText(): string {
        return this.$gettext('Cancel');
      },
      expandText(): string {
        return this.$gettext('Expand');
      },
      collapseText(): string {
        return this.$gettext('Collapse');
      },
    },
    methods: {
      ...mapActions('authentication', {
        setSetupGuideVisibility: 'setSetupGuideVisibility',
        getPreferences: 'getPreferences',
        setSetupGuideCollapsed: 'setSetupGuideCollapsed',
      }),
      updateBodyHeightStyleAfterFontsLoaded() {
        // document.fonts is an experimenatal feature, and TS is complaining about
        // 'fonts' being undefined. Thus we need to cast document as any.
        // We decided to use this feature, because it is supported by all major browsers
        if ((document as any).fonts) {
          // Wait for the font to be loaded in order to calculate the right height and
          // not the height with the temporary default system font (can be a different
          // height after font is being loaded).
          (document as any).fonts.ready.then(() => {
            this.setBodyHeightStyle();
          });
        } else {
          // if no browser support for document.fonts, just calculate the height with
          // what we have got at this point in time
          this.setBodyHeightStyle();
        }
      },
      setBodyHeightStyle() {
        const bodyHeight = (this.$refs.body as any)?.clientHeight;
        document.documentElement.style.setProperty(
          '--setup-guide-body-height',
          `${bodyHeight ? bodyHeight : 0}px`
        );
      },
      async toggleSetupGuide() {
        this.setSetupGuideCollapsed(!this.isSetupGuideCollapsed);
        // If the window was resized while the setup guide body was closed
        // the body height variable needs to be set when it is opened again.
        // nextTick makes sure that the element is already drawn in order to
        // calculate the height
        await this.$nextTick();
        this.setBodyHeightStyle();
        api.uiEvents.create({
          event_type: this.isSetupGuideCollapsed
            ? 'setup_guide_toggled_closed'
            : 'setup_guide_toggled_opened',
        });
      },
      closeSetupGuide() {
        this.setSetupGuideVisibility(false);
        api.uiEvents.create({
          event_type: 'setup_guide_closed',
        });
      },
      isCardCompleted(id: string) {
        const card = this.setupGuideCards.find((card: any) => card.id === id);
        return card?.is_completed || false;
      },
    },
    watch: {
      sidebarIsOpen() {
        // setTimeout because height of the body is measured, but
        // should be measured after the sidebar animation is done
        setTimeout(() => {
          this.setBodyHeightStyle();
        }, 300);
      },
    },
  });
