
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import api from '@/api';
  import Icon from '@/components/Icon/Icon.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import InputField from '@/components/form/InputField.vue';
  import TextAreaField from '@/components/form/TextAreaField.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import * as Sentry from '@sentry/vue';
  import moment from 'moment';
  import SupportCenterLink from './SupportCenterLink.vue';

  const regions = [
    'Africa',
    'America',
    'Antarctica',
    'Asia',
    'Atlantic',
    'Australia',
    'Europe',
    'Indian',
    'Pacific',
  ];

  const { browserTimezone, timezones } = (() => {
    try {
      return {
        browserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timezones: Intl.supportedValuesOf('timeZone')
          .filter((timeZone: string) =>
            regions.includes(timeZone.split('/', 1)[0])
          )
          .sort()
          .map((timeZone: string) => {
            return { name: timeZone.replaceAll('_', ' '), value: timeZone };
          }),
      };
    } catch (err) {
      Sentry.captureException(err);
      return {
        browserTimezone: 'Europe/Amsterdam',
        timezones: ['Europe/Amsterdam'],
      };
    }
  })();

  export default Vue.extend({
    components: {
      Icon,
      InputField,
      LoadingButton,
      SelectInput,
      Spinner,
      SupportCenterLink,
      TextAreaField,
    },
    data() {
      return {
        settingsLoaded: false,
        enabled: false,
        saving: false,
        type: 'permanent',
        body: '',
        intervalStart: '',
        intervalEnd: '',
        weeklyStartTime: '',
        weeklyEndTime: '',
        weeklyDays: [] as number[], // could be a set with newer Vue (vuejs#1957)
        timezone: '',
      };
    },
    async mounted() {
      const data = await api.autoReplySettings.get();

      this.type = data.enabled ? data.type : 'never';
      this.body = data.body;
      this.intervalStart = data.interval_start || '';
      this.intervalEnd = data.interval_end || '';
      this.weeklyStartTime = data.weekly_start || '';
      this.weeklyEndTime = data.weekly_end || '';
      this.weeklyDays = data.weekly_days || [];
      this.timezone = data.timezone || browserTimezone || '';

      this.settingsLoaded = true;
    },
    computed: {
      autoReplyEnabledLabel() {
        return this.$gettext('Send auto-replies');
      },
      autoReplyTypeLabel() {
        return this.$gettext('When should auto-replies be sent?');
      },
      autoReplyBodyLabel() {
        return this.$gettext('Message text');
      },
      autoReplyBodyPlaceholder() {
        return this.$gettext('A message that gets sent as the auto-reply');
      },
      autoReplyTypeOptions() {
        return [
          { name: this.$gettext('Never'), value: 'never' },
          { name: this.$gettext('Always'), value: 'permanent' },
          { name: this.$gettext('Fixed duration'), value: 'interval' },
          { name: this.$gettext('Weekly schedule'), value: 'weekly' },
        ];
      },
      startDateLabel() {
        return this.$pgettext('date range', 'Start date');
      },
      endDateLabel() {
        return this.$pgettext('date range', 'End date');
      },
      startTimeLabel() {
        return this.$pgettext('time range', 'Start time');
      },
      endTimeLabel() {
        return this.$pgettext('time range', 'End time');
      },
      timezoneLabel() {
        return this.$gettext('Timezone');
      },
      timezones() {
        return timezones;
      },
      minIntervalEnd(): string | null {
        if (!this.intervalStart) return null;
        return moment(this.intervalStart)
          .add(1, 'minute')
          .format('YYYY-MM-DDTHH:mm');
      },
      minWeeklyEndTime(): string | null {
        if (!this.weeklyStartTime) return null;
        const format = 'HH:mm';
        return moment(this.weeklyStartTime, format)
          .add(1, 'minute')
          .format(format);
      },
      weekdayNames() {
        return [
          this.$gettext('Monday'),
          this.$gettext('Tuesday'),
          this.$gettext('Wednesday'),
          this.$gettext('Thursday'),
          this.$gettext('Friday'),
          this.$gettext('Saturday'),
          this.$gettext('Sunday'),
        ];
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),

      async save(event: Event) {
        if (
          this.type === 'weekly' &&
          !this.validateWeeklyDays(event.target as HTMLFormElement)
        )
          return;

        this.saving = true;
        const settings = {
          enabled: this.type !== 'never',
          type: this.type === 'never' ? 'permanent' : this.type,
          body: this.body,

          // the API does not accept empty string, must be valid value or null
          interval_end: this.intervalEnd || null,
          interval_start: this.intervalStart || null,
          timezone: this.timezone || null,
          weekly_days: this.weeklyDays || null,
          weekly_end: this.weeklyEndTime || null,
          weekly_start: this.weeklyStartTime || null,
        };

        try {
          await api.autoReplySettings.update({ settings });
          this.setToastMessage({
            message: this.$gettext('Your auto-reply settings have been saved.'),
          });
        } catch (err) {
          this.setToastMessage({ message: this.$gettext('Saving failed') });
        }
        this.saving = false;
      },
      getWeekdayName(dayNum: number) {
        return this.weekdayNames[dayNum - 1];
      },
      resetWeekDayValidity(event: Event) {
        const form = (event.target as HTMLInputElement).form as HTMLFormElement;
        form['autoReplyWeekly1'].setCustomValidity('');
      },
      validateWeeklyDays(form: HTMLFormElement) {
        // Show a form field error on the first weekday checkbox if none are selected.
        const mondayCheckbox = form['autoReplyWeekly1'] as HTMLInputElement;
        const valid = Boolean(this.weeklyDays.length);
        mondayCheckbox.setCustomValidity(
          valid ? '' : this.$gettext('Please select at least one day.')
        );
        mondayCheckbox.reportValidity();
        return valid;
      },
    },
  });
