
  import FilterModal from '@/components/FilterModal/FilterModal.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'FiltersActions',
    components: {
      FilterModal,
      Icon,
    },
  });
