
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import api from '@/api';
  import { isAxiosError } from 'axios';
  import getErrorCode from '@/api/get-error-code';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import formatLongNumber from '@/lib/formatLongNumber';
  import NotificationBlock from '../NotificationBlock/NotificationBlock.vue';
  import SupportCenterLink from '../SupportCenterLink.vue';
  import Icon from '../Icon/Icon.vue';

  export default Vue.extend({
    components: {
      DefaultDialog,
      Icon,
      LoadingButton,
      NotificationBlock,
      PasswordField,
      SupportCenterLink,
    },
    props: {
      status: {
        type: Object as PropType<RecoveryStatusResponseData>,
        required: true,
      },
    },
    data() {
      return {
        authenticationError: false,
        loading: false,
        password: '',
        supportsPrinting: !!window.print,
        recoveryCode: null as string | null,
        validatePassword: false,
      };
    },
    computed: {
      passwordLabelText(): string {
        return this.$gettext('Your account password');
      },
      passwordValidationText(): string {
        return this.authenticationError
          ? this.$gettext('Wrong password')
          : this.$gettext('Something went wrong');
      },
      formattedRecoveryCode(): string {
        return this.recoveryCode ? formatLongNumber(this.recoveryCode) : '';
      },
    },
    watch: {
      password() {
        this.validatePassword = false;
        this.authenticationError = false;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      reset() {
        this.authenticationError = false;
        this.password = '';
        this.recoveryCode = null;
        this.validatePassword = false;
      },
      async enableRecoveryByCode() {
        this.loading = true;
        try {
          const { new_recovery_key } = await api.recovery.enableRecoveryKey({
            password: this.password,
          });
          if (!new_recovery_key) throw Error('missing recovery code');
          this.recoveryCode = new_recovery_key;
        } catch (error: any) {
          if (!isAxiosError(error)) throw error;
          this.authenticationError =
            getErrorCode(error) === AUTHENTICATION_FAILURE;
          if (!this.authenticationError) {
            this.setToastMessage({
              message: this.$gettext(
                'Sorry, we could not generate a new recovery code. Please try again later.'
              ),
            });
          }
        } finally {
          this.loading = false;
        }
      },
      async copyRecoveryCode() {
        if (!this.recoveryCode) return;
        try {
          await navigator.clipboard.writeText(this.recoveryCode);
          this.setToastMessage({
            message: this.$gettext('Copied to clipboard'),
          });
        } catch (err) {
          // unable to write text to clipboard
        }
      },
      printRecoveryCode() {
        window.print();
      },
    },
  });
