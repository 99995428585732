<template>
  <div class="swipe-navigation" :style="swipeStyle">
    <slot />
  </div>
</template>

<script>
  import { closestScrollElement } from '@/lib/scroll';

  export default {
    data() {
      return {
        animating: false,
        clientX: 0,
        startingPositionX: null,
        startingPositionY: null,
        swipingCanceled: false,
        swipeDirection: null,
      };
    },
    computed: {
      swipeStyle() {
        if (!this.animating && this.clientX) {
          return {
            transform: `translateX(${this.clientX}px)`,
            transition: 'none',
          };
        }
        return {
          transition: 'transform 100ms ease-out',
        };
      },
    },
    mounted() {
      this.$el.addEventListener('touchstart', this.onTouchStart);
      this.$el.addEventListener('touchmove', this.onTouchMove);
      this.$el.addEventListener('touchend', this.onTouchEnd);
    },
    beforeDestroy() {
      this.$el.removeEventListener('touchstart', this.onTouchStart);
      this.$el.removeEventListener('touchmove', this.onTouchMove);
      this.$el.removeEventListener('touchend', this.onTouchEnd);
    },
    methods: {
      viewportZoomed() {
        if (window.visualViewport) {
          return window.visualViewport.scale > 1;
        }

        if (window.screen.width) {
          return window.screen.width / window.innerWidth > 1;
        }

        return false;
      },
      onTouchStart(ev) {
        this.animating = false;
        this.startingPositionX = ev.touches[0].clientX;
        this.startingPositionY = ev.touches[0].clientY;
      },
      onTouchMove(ev) {
        if (this.swipingCanceled) return;

        // stop swiping when there is more than 1 touch point
        // stop swiping when the viewport is zoomed in
        if (ev.touches.length > 1 || this.viewportZoomed()) {
          this.swipingCanceled = true;
          return;
        }

        const { clientX, clientY } = ev.touches[0];
        const deltaX = clientX - this.startingPositionX;
        const deltaY = clientY - this.startingPositionY;

        if (!this.swipeDirection) {
          this.swipeDirection =
            Math.abs(deltaY) > Math.abs(deltaX)
              ? deltaY < 0
                ? 'down'
                : 'up'
              : deltaX < 0
                ? 'right'
                : 'left';

          // Cancel swiping if the first touchMove event is vertical
          if (this.swipeDirection === 'up' || this.swipeDirection === 'down') {
            this.swipingCanceled = true;
            return;
          }

          // Cancel swiping if the first touchMove is in the direction
          // where there is content to scroll to.
          const scrollingElement = closestScrollElement(ev.target);
          const elWidth = scrollingElement.offsetWidth;
          const scrollWidth = scrollingElement.scrollWidth;
          const scrollLeft = scrollingElement.scrollLeft;
          const scrollRight = elWidth - (scrollWidth - scrollLeft);

          if (
            (this.swipeDirection === 'right' && scrollRight !== 0) ||
            (this.swipeDirection === 'left' && scrollLeft !== 0)
          ) {
            this.swipingCanceled = true;
            return;
          }
        }

        ev.preventDefault();

        window.requestAnimationFrame(() => {
          this.clientX = deltaX;
        });
      },
      onTouchEnd(ev) {
        if (this.swipeDirection && !this.swipingCanceled) {
          ev.preventDefault();

          const swipeThreshold = document.documentElement.clientWidth * 0.2;
          const { clientX } = ev.changedTouches[0];

          if (clientX < this.startingPositionX - swipeThreshold) {
            this.$emit('swipeLeft');
          } else if (clientX > this.startingPositionX + swipeThreshold) {
            this.$emit('swipeRight');
          }
        }

        this.swipingCanceled = false;
        this.swipeDirection = null;

        window.requestAnimationFrame(() => {
          this.clientX = 0;
          this.animating = true;
        });
      },
    },
  };
</script>
