
  import { mapGetters, mapActions } from 'vuex';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import InputField from '@/components/form/InputField.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import getErrorCode from '@/api/get-error-code';
  import { WEB_DB_CONTACT_GROUP_ALREADY_EXISTS } from '@/api/error-codes';
  import contactGroupMixin from '@/mixins/contact-group-mixin';
  import Vue from 'vue';
  import { AxiosError } from 'axios';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default Vue.extend({
    components: {
      Modal,
      ModalConfirmActions,
      InputField,
      Icon,
      SupportCenterLink,
    },
    mixins: [asyncActionsMixin, contactGroupMixin],
    data() {
      return {
        newName: '',
        validateContactGroupName: false,
        contactGroupAlreadyExists: false,
        state: {
          loading: true,
          error: null,
        },
      };
    },
    computed: {
      ...mapGetters(['contactGroups', 'contactUserGroups']),
      renameText(): string {
        return this.$gettext('Rename');
      },
      removeText(): string {
        return this.$gettext('Delete');
      },
      cancelText(): string {
        return this.$gettext('Cancel');
      },
      deleteText(): string {
        return this.$gettext('Delete');
      },
      newContactGroupNameText(): string {
        return this.$gettext('New contact group name');
      },
      contactGroupErrorMessage(): string {
        return this.contactGroupAlreadyExists
          ? this.$gettext('Contact group with that name already exists')
          : this.$gettext('Contact group name is required.');
      },
    },
    watch: {
      newName() {
        this.contactGroupAlreadyExists = false;
        this.validateContactGroupName = false;
      },
    },
    created() {
      this.getContactGroups()
        .then(() => {
          this.state = { loading: false, error: this.state.error };
        })
        .catch((error) => {
          this.state = { loading: false, error };
          throw error;
        });
    },
    methods: {
      ...mapActions([
        'getContactGroups',
        'updateContactGroup',
        'removeContactGroup',
        'setToastMessage',
      ]),
      getContactGroupDeletionConfirmationHtml(
        this: any,
        group: ContactGroup
      ): string {
        const name = this.getContactGroupName({
          id: group.id,
          name: group.name,
        });
        return this.$gettextInterpolate(
          this.$gettext(
            "Are you sure you want to delete group ‘<strong>%{ name }</strong>’? This will not delete the group's contacts."
          ),
          { name }
        );
      },
      renameContactGroupClicked: asyncAction(
        'renaming',
        function (this: any, ev: Event, group: ContactGroup, modalScope: any) {
          this.validateContactGroupName = true;
          const target = ev.target as HTMLFormElement;
          if (!target.checkValidity()) {
            return;
          }
          const oldName = this.getContactGroupName({
            id: group.id,
            name: group.name,
          });
          return this.updateContactGroup({
            id: group.id,
            properties: { name: this.newName },
          })
            .then(() => {
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext(
                    'Contact group %{ oldName } renamed to %{ newName }'
                  ),
                  { oldName, newName: this.newName }
                ),
              });
              this.resetModal(modalScope);
            })
            .catch((err: AxiosError) => {
              if (getErrorCode(err) === WEB_DB_CONTACT_GROUP_ALREADY_EXISTS) {
                this.contactGroupAlreadyExists = true;
              } else {
                this.setToastMessage({
                  message: this.$gettextInterpolate(
                    this.$gettext('Could not rename %{ contactGroup }'),
                    { contactGroup: oldName }
                  ),
                });
                this.resetModal(modalScope);
                throw err;
              }
            });
        }
      ),
      removeContactGroupClicked: asyncAction(
        'removing',
        function (this: any, group: ContactGroup, modalScope: any) {
          const contactGroupName = this.getContactGroupName({
            id: group.id,
            name: group.name,
          });
          return this.removeContactGroup({ id: group.id })
            .then(() => {
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext('Contact group %{ groupName } deleted'),
                  { groupName: contactGroupName }
                ),
              });
              this.resetModal(modalScope);
            })
            .catch((err: AxiosError) => {
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$gettext('Could not delete %{ contactGroup }'),
                  { contactGroup: contactGroupName }
                ),
              });
              throw err;
            });
        }
      ),
      isContactUserGroup(group: ContactGroup) {
        return this.contactUserGroups.includes(group);
      },
      showRenameModal(this: any, group: ContactGroup, modalScope: any) {
        this.newName = this.getContactGroupName({
          id: group.id,
          name: group.name,
        });
        modalScope.toggle();
      },
      resetModal(modalScope: any) {
        this.newName = '';
        this.validateContactGroupName = false;
        modalScope.toggle();
      },
    },
  });
