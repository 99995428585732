<template>
  <Dropdown>
    <template #button="{ isOpen, toggle }">
      <button
        type="button"
        class="button button--icon-only rich-text-editor__format-bar-button"
        :class="{ 'button--icon-only-active': isOpen }"
        @click.prevent="toggle"
        :aria-label="setTextColorText"
        v-tooltip="setTextColorText"
      >
        <span
          class="colour-picker__button colour-picker__toggle"
          :class="colourSpecificClassName(textColour)"
          v-test:colorPickerToggle
        />
        <Icon class="colour-picker__arrow button--arrow" symbol="arrow-down" />
      </button>
    </template>
    <template #content="{ toggle }">
      <ul class="colour-picker__container">
        <li v-for="(option, index) in colours" :key="index">
          <button
            type="button"
            class="colour-picker__button"
            :class="[colourSpecificClassName(option)]"
            @click="
              colourButtonClicked(option);
              toggle();
            "
            v-test:colorButton
          />
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<script>
  import Dropdown from '@/components/Dropdown/Dropdown';
  import Icon from '@/components/Icon/Icon';
  import getKeyByValue from '@/lib/getKeyByValue';

  export const TEXT_COLOURS = new Map([
    // Keep in sync with $text-colors scss variable!
    ['black', 'rgb(0, 0, 0)'],
    ['mine shaft', 'rgb(59, 67, 92)'],
    ['dove gray', 'rgb(102, 102, 102)'],
    ['dusty gray', 'rgb(153, 153, 153)'],
    ['bright red', 'rgb(179, 6, 0)'],
    ['red', 'rgb(234, 8, 0)'],
    ['rusty nail', 'rgb(132, 73, 6)'],
    ['carrot orange', 'rgb(243, 144, 30)'],
    ['golden grass', 'rgb(220, 190, 35)'],
    ['ripe lemon', 'rgb(245, 212, 38)'],
    ['san felix', 'rgb(7, 100, 30)'],
    ['sushi', 'rgb(111, 192, 64)'],
    ['congress blue', 'rgb(0, 84, 147)'],
    ['boston blue', 'rgb(51, 120, 186)'],
    ['royal purple', 'rgb(118, 63, 155)'],
    ['medium purple', 'rgb(164, 77, 219)'],
  ]);
  export default {
    name: 'ColourPicker',
    components: {
      Dropdown,
      Icon,
    },
    props: {
      textColour: {
        type: String,
        required: true,
        default: TEXT_COLOURS.get('mine shaft'),
      },
    },
    computed: {
      setTextColorText() {
        return this.$gettext('Set text color');
      },
      colours() {
        return Array.from(TEXT_COLOURS.values());
      },
    },
    methods: {
      colourSpecificClassName(colourRgbValue) {
        const colourName = getKeyByValue(TEXT_COLOURS, colourRgbValue);
        if (colourName) {
          return `colour-picker__button--${colourName
            .replace(/ /g, '-')
            .toLowerCase()}`;
        }
      },
      colourButtonClicked(option) {
        this.$emit('setTextColour', option);
      },
    },
  };
</script>

<style src="./ColourPicker.scss" lang="scss"></style>
