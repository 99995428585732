
  import api from '@/api';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import RemoveRecoveryCodeModal from '@/components/RecoveryModals/RemoveRecoveryCodeModal.vue';
  import AddRecoveryCodeModal from '@/components/RecoveryModals/AddRecoveryCodeModal.vue';
  import AddRecoveryAddressModal from '@/components/RecoveryModals/AddRecoveryAddressModal.vue';
  import RemoveRecoveryAddressModal from '@/components/RecoveryModals/RemoveRecoveryAddressModal.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import SupportCenterLink from '@/components/SupportCenterLink.vue';

  export default Vue.extend({
    name: 'RecoverySettings',
    components: {
      AddRecoveryAddressModal,
      AddRecoveryCodeModal,
      FlipSwitch,
      Icon,
      NotificationBlock,
      RemoveRecoveryAddressModal,
      RemoveRecoveryCodeModal,
      Spinner,
      SupportCenterLink,
    },
    data() {
      return {
        notificationsEnabled: false,
        status: null as RecoveryStatusResponseData | null,
      };
    },
    computed: {
      hasRecovery(): boolean {
        const code = Boolean(this.status?.recovery_key_enabled);
        const email = Boolean(
          this.status?.recovery_address &&
            this.status?.recovery_address_confirmed
        );
        return code || email;
      },
      emailAddress(): string | undefined {
        return this.status?.recovery_address ?? undefined;
      },
      emailAddressVerified(): boolean | undefined {
        return this.status?.recovery_address_confirmed;
      },
    },
    async created() {
      void this.update();
    },
    methods: {
      ...mapActions(['setToastMessage']),
      async update() {
        this.status = await api.recovery.getRecoveryStatus();
        this.notificationsEnabled = await api.newMailNotifications.get();
      },
      async toggleNotificationsEmails() {
        try {
          await api.newMailNotifications.set(this.notificationsEnabled);
        } catch (error: any) {
          this.setToastMessage({
            message: this.$gettext('Something went wrong'),
          });
          throw error;
        }
      },
    },
  });
