import api from '@/api';
import { BROADCAST_AUTHENTICATION, broadcastMessage } from '@/lib/broadcast';
import { daysDiff, isAfter, isBefore, isBetween, unixTime } from '@/lib/time';
import { resetState } from '@/store';
import { Module } from 'vuex';
import * as types from './authentication-mutation-types';

interface AuthenticationState {
  isAuthenticated: boolean;
  sessionExpiry: number;
  user: User;
  // It's not the best place to store the error here, as it is not necessarily connected to authentication
  // However, decided to leave it here for lack of a better option
  unknownError: Boolean;
  setupGuideCollapsed: Boolean;
}

const emptyUser = (): User => ({
  id: '',
  email: '',
  totp_enabled: false,
  is_trial: false,
  trial_days_remaining: null,
  has_subscription: true,
  preferences: {
    locale: '',
    display_name: '',
    external_link_warning: true,
    session_timeout_seconds: 0,
    recovery_email: null,
    editor: {
      default_font_family: null,
      default_font_color: null,
      default_font_size: null,
    },
    signature: '',
    date_format: '',
    time_format: '',
    pgp_auto_encrypt: false,
    pgp_auto_sign: false,
    pgp_auto_attach_public_key: false,
    recipient_count_warning: false,
    imap_access: false,
    show_welcome_compose_modal: false,
    setup_guide_visible: true,
    setup_guide_completed_seen: true,
    setup_guide_cards: [],
    migration_status_id: null,
  },
  account_active_start: '',
  account_active_end: '',
  account_grace_end: '',
  account_termination_end: '',
  is_manager: false,
  login_count: 3,
  domains: [],
  max_domains: 0,
});

const authenticationModule: Module<AuthenticationState, any> = {
  namespaced: true,

  state: {
    isAuthenticated: false,
    sessionExpiry: 0,
    user: emptyUser(),
    unknownError: false,
    setupGuideCollapsed:
      localStorage.getItem('setup-guide-collapsed') === 'true',
  },

  getters: {
    user: (state) => state.user, // TODO: remove this do-nothing getter
    isInTrial: (state) => state.user.is_trial === true,
    activeDaysRemaining: (state) =>
      typeof state.user.account_active_end === 'string'
        ? daysDiff(state.user.account_active_end)
        : null,
    canAddDomain: (state) =>
      (state.user.domains ?? []).length < state.user.max_domains,
    isActive: (state) =>
      typeof state.user.account_active_end === 'string' &&
      isBefore(state.user.account_active_end),
    isLastActiveMonth: (state, getters) =>
      typeof getters.activeDaysRemaining === 'number' &&
      getters.activeDaysRemaining >= 0 &&
      getters.activeDaysRemaining <= 31,
    isInGrace: (state) =>
      typeof state.user.account_active_end === 'string' &&
      typeof state.user.account_grace_end === 'string' &&
      isBetween(state.user.account_active_end, state.user.account_grace_end),
    isInQuarantine: (state) =>
      typeof state.user.account_grace_end === 'string' &&
      isAfter(state.user.account_grace_end),
    isManager: (state) => state.user.is_manager === true,
    hasSubscription: (state) => state.user.has_subscription === true,
    hasDomains: (state) => state.user.domains && state.user.domains.length > 0,
    hasIncompleteDomains: (state) =>
      state.user.domains.some((domain) => !domain.completed),
    verifiedDomains: (state) =>
      state.user.domains.filter((domain) => domain.verified),
    isSetupGuideVisible: (state) => state.user.preferences.setup_guide_visible,
    isSetupGuideCompletedSeen: (state) =>
      state.user.preferences.setup_guide_completed_seen,
    setupGuideCards: (state) => state.user.preferences.setup_guide_cards,
    isSetupGuideCollapsed: (state) => state.setupGuideCollapsed,
  },

  mutations: {
    [types.SET_AUTHENTICATION_STATUS](state, { isAuthenticated }) {
      state.isAuthenticated = isAuthenticated;
    },
    [types.SET_CURRENT_USER](state, { user }) {
      state.user = {
        preferences: {},
        ...user,
      };
    },
    [types.SET_TRIAL_STATUS](state, { status }) {
      state.user = {
        ...state.user,
        is_trial: status,
      };
    },
    [types.SET_SESSION_EXPIRY](state, expiry: number) {
      state.sessionExpiry = expiry;
    },
    [types.SET_UNKNOWN_ERROR](state, { error }) {
      state.unknownError = error;
    },
    [types.SET_SETUP_GUIDE_VISIBILITY](state, visible) {
      state.user.preferences.setup_guide_visible = visible;
    },
    [types.SET_DOMAINS](state, domains) {
      state.user.domains = domains;
    },
    [types.SET_SETUP_GUIDE_COLLAPESED](state, setupGuideCollapsed) {
      state.setupGuideCollapsed = setupGuideCollapsed;
      localStorage.setItem(
        'setup-guide-collapsed',
        JSON.stringify(setupGuideCollapsed)
      );
    },
  },

  actions: {
    async logout(
      { dispatch },
      { location }: { location: 'login' | 'noop' | 'reload' } = {
        location: 'reload',
      }
    ) {
      await api.authentication.logout();
      broadcastMessage(BROADCAST_AUTHENTICATION, {
        is_authenticated: false,
      });
      if (location === 'noop') {
        await dispatch('processAuthentication', { is_authenticated: false });
      } else {
        // remove beforeunload event listeners because we are going to be
        // changing the location and they could block it and we don't want that
        await dispatch('unloadAndRemoveAllFunctions', null, { root: true });
        if (location === 'reload') {
          // The idea behind "reload" is that it leads to the route access level
          // check storing the current route and then redirecting to login. This
          // brings the user back to where they were when they log back in. It
          // is convenient especially after session expiry, but not appropriate
          // on manual logout.
          window.location.reload();
        } else if (location === 'login') {
          window.location.assign('/login');
        } else {
          throw new Error(`unsupported location parameter ${location}`);
        }
      }
    },
    async login({ dispatch }, { email, password }) {
      const loginResponse = await api.authentication.login({ email, password });
      await dispatch('processAuthentication', loginResponse);
      broadcastMessage(BROADCAST_AUTHENTICATION, loginResponse);
      return loginResponse;
    },
    async totp({ dispatch }, { email, password, token }) {
      const loginResponse = await api.authentication.totp({
        email,
        password,
        token,
      });
      await dispatch('processAuthentication', loginResponse);
      broadcastMessage(BROADCAST_AUTHENTICATION, loginResponse);
      return loginResponse;
    },
    async getAuthenticationStatus({ dispatch }) {
      const loginResponse = await api.authentication.get();
      await dispatch('processAuthentication', loginResponse);
      return loginResponse;
    },
    async disableTotp({ commit, state }, { password }) {
      await api.authentication.disableTotp({ password });
      commit(types.SET_CURRENT_USER, {
        user: {
          ...state.user,
          totp_enabled: false,
        },
      });
    },
    async getDomains({ commit, state }) {
      // Do check because otherwise a 401 will break the application
      // through the axios interceptor in the router index file
      const domains = !state.user.is_manager
        ? []
        : await api.customDomains.all();
      commit(types.SET_DOMAINS, domains);

      return domains;
    },
    async updatePreferences({ commit, state }, { update }) {
      const preferences = await api.settings.update({ update });
      commit(types.SET_CURRENT_USER, {
        user: {
          ...state.user,
          preferences,
        },
      });
    },
    async getPreferences({ commit, state }) {
      const preferences = await api.settings.get();
      commit(types.SET_CURRENT_USER, {
        user: {
          ...state.user,
          preferences,
        },
      });
    },
    async processAuthentication(
      { commit, dispatch },
      { is_authenticated, session_ttl, user }: AuthenticationResponseData
    ) {
      if (is_authenticated) {
        await dispatch('setSessionExpiryByTtl', session_ttl);
        commit(types.SET_CURRENT_USER, { user });
        await dispatch(
          'updateLanguage',
          { language: user?.preferences.locale },
          { root: true }
        );
        await dispatch(
          'features/getFeatures',
          { forceReload: true },
          { root: true }
        );
        // Set authentication status last, to prevent race conditions.
        commit(types.SET_AUTHENTICATION_STATUS, { isAuthenticated: true });
      } else {
        resetState();

        // After clearing state with resetState(), we need to set the darkMode and darkModeEditorReset again according to how it is saved in the users local storage
        const darkMode = localStorage.getItem('dark-mode') === 'true';
        await dispatch('setDarkMode', darkMode, { root: true });

        const darkModeEditorReset =
          localStorage.getItem('dark-mode-editor-reset') === 'true';
        await dispatch('setDarkModeEditorReset', darkModeEditorReset, {
          root: true,
        });

        // After clearing state with resetState(), we need to load the feature flags again for the unauthenticated user
        await dispatch(
          'features/getFeatures',
          { forceReload: true },
          { root: true }
        );
      }
    },
    getSessionExpiryAsTtl({ state }): number {
      return state.sessionExpiry - unixTime();
    },
    setSessionExpiryByTtl({ commit }, ttl: number) {
      // Expire session in TTL seconds from now, minus 60 seconds to ensure that webmail-api does not expire before requests are handled.
      const sessionExpiry = unixTime() + ttl - 60;
      commit(types.SET_SESSION_EXPIRY, sessionExpiry);
    },
    async syncSubscription({ dispatch }) {
      await api.chargebee.syncSubscription();
      await dispatch('getAuthenticationStatus');
    },
    setUnknownError({ commit }, { error }) {
      commit(types.SET_UNKNOWN_ERROR, { error });
    },
    async setSetupGuideVisibility({ dispatch }, visible) {
      await dispatch('updatePreferences', {
        update: {
          setup_guide_visible: visible,
        },
      });
    },
    async setEmailMigrationSetupCardAsDone({ dispatch }) {
      await dispatch('updatePreferences', {
        update: {
          setup_guide_cards: [{ id: 'import_emails', is_completed: true }],
        },
      });
    },
    setSetupGuideCollapsed({ commit }, setupGuideCollapsed) {
      commit(types.SET_SETUP_GUIDE_COLLAPESED, setupGuideCollapsed);
    },
  },
};

export default authenticationModule;
