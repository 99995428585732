<template>
  <div v-if="loading">
    <p class="panel" v-translate>Loading contact…</p>
  </div>

  <ErrorView v-else-if="error" :error-type="error" />

  <div v-else class="panel">
    <div class="panel__section" v-test:contactPanel>
      <div
        class="action-bar panel__section-item panel__section-item--no-padding"
      >
        <div class="action-bar__row">
          <div class="action-bar__row-left">
            <div class="action-bar__group">
              <Modal>
                <template #header>
                  <translate>Edit contact</translate>
                </template>
                <template #toggle="{ toggle }">
                  <ActionBarButton
                    @click="toggle"
                    :label="editText"
                    :title="editText"
                    icon="edit"
                    class="button--subtle"
                  />
                </template>
                <template #content="{ beforeClose, setLoading, toggle }">
                  <ContactForm
                    :before-modal-close="beforeClose"
                    :contact="activeContact"
                    :set-modal-loading="setLoading"
                    :toggle-modal="toggle"
                  />
                </template>
              </Modal>
              <DeleteConfirmationModal
                @delete="onDeleteClicked"
                :ok-button-text="deleteContactText"
                :warning-text="deleteWarningText"
                ref="deleteConfirmationModal"
              >
                <template #DeleteButton="deleteConfirmationModalScope">
                  <ActionBarButton
                    :label="deleteText"
                    :title="deleteContactText"
                    icon="trash"
                    @click="deleteConfirmationModalScope.toggle"
                    class="button--subtle"
                  />
                </template>
              </DeleteConfirmationModal>
              <ActionBarButton
                :aria-label="favoriteText"
                :class="{ active: activeContact.favorite }"
                :icon="activeContact.favorite ? 'star' : 'star-outline'"
                :label="favoriteText"
                :title="favoriteText"
                @click.stop="onFavoriteClicked"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="panel__section-item contact-panel">
        <h2
          v-if="activeContact.name"
          v-test:contactName
          v-text="activeContact.name"
        />
        <div class="contact-details">
          <NotificationBlock
            class="add-details"
            :translated-message="addDetailsText"
          />
          <dl class="icon-list">
            <template
              v-if="
                activeContact.email_addresses &&
                activeContact.email_addresses.length
              "
            >
              <dt>
                <Icon symbol="mail" />
                <translate class="visually-hidden">Email addresses</translate>
              </dt>
              <dd>
                <dl>
                  <div
                    v-for="(address, index) in activeContact.email_addresses"
                    :key="`${address}-${index}`"
                  >
                    <dt>
                      {{ getContactCategoryName(address.label) }}
                      <translate class="deemphasized" v-if="address.is_primary">
                        (Primary)
                      </translate>
                    </dt>
                    <dd>
                      <router-link
                        :to="composeToRoute(address.value)"
                        v-test:emailAddress
                        :data-primary="address.is_primary"
                      >
                        {{ address.value }}
                      </router-link>
                      <router-link
                        v-if="address.fingerprints.length > 0"
                        :to="keyLink(address.value, address.fingerprints)"
                        v-test:pgpKey
                      >
                        <Icon
                          class="icon-pgp-key"
                          symbol="key"
                          v-tooltip="pgpKeyAvailableText"
                        />
                      </router-link>
                    </dd>
                  </div>
                </dl>
              </dd>
            </template>
            <template v-if="activeContactSelectedUserGroups.length">
              <dt>
                <Icon symbol="meeting" />
                <translate class="visually-hidden">Contact groups</translate>
              </dt>
              <dd>
                <dl>
                  <div>
                    <dt
                      v-translate="{
                        count: activeContactSelectedUserGroups.length,
                      }"
                      :translate-n="activeContactSelectedUserGroups.length"
                      translate-plural="Contact groups"
                    >
                      Contact group
                    </dt>
                    <dd
                      v-for="group in activeContactSelectedUserGroups"
                      :key="group.id"
                    >
                      <router-link :to="contactGroupRoute(group.id)">
                        {{ group.name }}
                      </router-link>
                    </dd>
                  </div>
                </dl>
              </dd>
            </template>
            <template v-if="activeContact.phone_number">
              <dt>
                <Icon symbol="phone" />
                <translate class="visually-hidden">Phone number</translate>
              </dt>
              <dd>
                <a
                  :href="`tel:${activeContact.phone_number}`"
                  v-test:phoneNumber
                  v-text="activeContact.phone_number"
                />
              </dd>
            </template>
            <template v-if="activeContact.company_name">
              <dt><Icon symbol="company" /></dt>
              <dd v-test:companyTitle v-text="activeContact.company_name" />
            </template>
            <template v-if="activeContact.job_title">
              <dt><Icon symbol="job_title" /></dt>
              <dd v-test:jobTitle v-text="activeContact.job_title" />
            </template>
            <template v-if="activeContact.website">
              <dt>
                <Icon symbol="website" />
                <translate class="visually-hidden">Website</translate>
              </dt>
              <dd>
                <a
                  :href="websiteLink"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-test:contactWebsite
                  v-text="activeContact.website"
                />
              </dd>
            </template>
            <template v-if="contactHasAddress(activeContact)">
              <dt>
                <Icon symbol="address" />
                <translate class="visually-hidden">Address</translate>
              </dt>
              <dd>
                <p
                  v-if="activeContact.address"
                  v-test:firstLineAddress
                  v-text="activeContact.address"
                />
                <p
                  v-if="activeContact.po_box"
                  v-test:secondLineAddress
                  v-text="activeContact.po_box"
                />
                <p v-if="activeContact.city" v-test:cityAndZipCode>
                  {{ activeContact.city }}
                  {{ activeContact.zipcode }}
                </p>
                <p
                  v-if="activeContact.state"
                  v-test:stateOrProvince
                  v-text="activeContact.state"
                />
                <p
                  v-if="activeContact.country"
                  v-test:country
                  v-text="activeContact.country"
                />
              </dd>
            </template>
            <template v-if="activeContact.notes">
              <dt><Icon symbol="notes" /></dt>
              <dd
                class="multi-line-text"
                v-test:notes
                v-text="activeContact.notes"
              />
            </template>
            <template
              v-if="
                activeContact.email_addresses &&
                activeContact.email_addresses.length
              "
            >
              <dt><Icon symbol="gear" /></dt>
              <dd>
                <dl>
                  <div>
                    <dt v-translate>Contact preferences</dt>
                    <dd>
                      <FlipSwitch
                        id="always_encrypt"
                        class="form-field"
                        v-model="alwaysEncrypt"
                      >
                        <translate>Always encrypt</translate>
                      </FlipSwitch>
                    </dd>
                    <dd>
                      <FlipSwitch
                        id="always_sign"
                        class="form-field"
                        v-model="alwaysSign"
                      >
                        <translate>Always sign</translate>
                      </FlipSwitch>
                    </dd>
                    <dd>
                      <FlipSwitch
                        id="always_display_ext_images"
                        class="form-field"
                        v-model="alwaysDisplayExternalImages"
                      >
                        <translate>Always display external images</translate>
                      </FlipSwitch>
                    </dd>
                  </div>
                </dl>
              </dd>
            </template>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Modal from '@/components/Modal';
  import contactCategoryOptionsMixin from '@/mixins/contact-category-options-mixin';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import ActionBarButton from '@/components/ActionBarButton';
  import ContactForm from '@/components/ContactForm/ContactForm.vue';
  import DeleteConfirmationModal from '@/components/DeleteConfirmationModal/DeleteConfirmationModal';
  import Icon from '@/components/Icon/Icon';
  import FlipSwitch from '@/components/form/FlipSwitch';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView';
  import { sanitizeUrl } from '@/lib/url';

  import {
    MAIL_COMPOSE,
    CONTACTS_GROUP,
    SETTINGS_PGP_KEYRING_SEARCH,
    SETTINGS_PGP_KEYRING_KEY,
  } from '@/router/named-routes';

  export default {
    components: {
      ActionBarButton,
      ContactForm,
      DeleteConfirmationModal,
      ErrorView,
      FlipSwitch,
      Icon,
      Modal,
      NotificationBlock,
    },
    mixins: [contactCategoryOptionsMixin, asyncActionsMixin],
    data() {
      return {
        loading: true,
        error: null,
      };
    },
    computed: {
      ...mapGetters([
        'activeContact',
        'activeContactGroup',
        'contactUserGroups',
      ]),
      activeContactSelectedUserGroups() {
        if (
          !this.activeContact.contact_group_ids ||
          !this.contactUserGroups.length
        ) {
          return [];
        }
        return this.activeContact.contact_group_ids.reduce((groups, id) => {
          const group = this.contactUserGroups.find((group) => group.id === id);
          return group ? [...groups, group] : groups;
        }, []);
      },
      editText() {
        return this.$gettext('Edit');
      },
      deleteText() {
        return this.$gettext('Delete');
      },
      deleteContactText() {
        return this.$gettext('Delete contact');
      },
      deleteWarningText() {
        return this.$gettext(
          'The selected contact will be permanently deleted. It is not possible to undo this action.'
        );
      },
      favoriteText() {
        return this.$pgettext('action', 'Favorite');
      },
      pgpKeyAvailableText() {
        return this.$gettext('PGP key available');
      },
      addDetailsText() {
        return this.$gettext('Edit the contact to add details.');
      },
      alwaysDisplayExternalImages: {
        get() {
          return this.activeContact.always_show_external_images;
        },
        async set(always_show_external_images) {
          await this.updateContact({
            id: this.activeContact.id,
            contact: { always_show_external_images },
          });
        },
      },
      alwaysSign: {
        get() {
          return this.activeContact.always_sign;
        },
        async set(always_sign) {
          await this.updateContact({
            id: this.activeContact.id,
            contact: { always_sign },
          });
        },
      },
      alwaysEncrypt: {
        get() {
          return this.activeContact.always_encrypt;
        },
        async set(always_encrypt) {
          await this.updateContact({
            id: this.activeContact.id,
            contact: { always_encrypt },
          });
        },
      },
      websiteLink() {
        return sanitizeUrl(this.activeContact.website);
      },
    },
    created() {
      this.getActiveContact({ id: this.$route.params.contact })
        .then(() => {
          this.loading = false;
          this.error = null;
        })
        .catch((error) => {
          this.loading = false;
          this.error = ErrorType.ContactNotFound;
          throw error;
        });
    },
    methods: {
      ...mapActions([
        'getActiveContact',
        'setToastMessage',
        'setContactFavoriteStatus',
        'deleteContact',
        'updateContact',
      ]),
      contactHasAddress(contact) {
        return [
          contact.address,
          contact.po_box,
          contact.city,
          contact.zipcode,
          contact.state,
          contact.country,
        ].some((x) => x);
      },
      onFavoriteClicked() {
        this.setContactFavoriteStatus({
          id: this.activeContact.id,
          favorite: !this.activeContact.favorite,
        });
      },
      onDeleteClicked: asyncAction('deleting', function () {
        return this.deleteContact({ contact: this.activeContact })
          .then(() => {
            this.$refs.deleteConfirmationModal.toggleModal();
            this.setToastMessage({ message: this.$gettext('Contact deleted') });
            this.$router.push({
              name: CONTACTS_GROUP,
              params: { group: this.activeContactGroup.id },
            });
          })
          .catch((error) => {
            this.setToastMessage({
              message: this.$gettext('Sorry, we could not delete the contact'),
            });
            throw error;
          });
      }),
      keyLink(address, fingerprints) {
        if (fingerprints.length === 1) {
          return {
            name: SETTINGS_PGP_KEYRING_KEY,
            params: { fingerprint: fingerprints[0] },
          };
        } else {
          return {
            name: SETTINGS_PGP_KEYRING_SEARCH,
            query: { query: address },
          };
        }
      },
      composeToRoute(emailAddress) {
        return { name: MAIL_COMPOSE, query: { mailTo: emailAddress } };
      },
      contactGroupRoute(group) {
        return { name: CONTACTS_GROUP, params: { group } };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .contact-panel {
    display: flex;
    flex-flow: column;
    gap: $spacing-default;
    overflow-wrap: break-word; // prevent long words from overflowing

    .contact-details {
      columns: 3 40ch;
      gap: $spacing-default;
      max-inline-size: 150ch; // avoid too wide column spread
    }
  }

  dl,
  dt,
  dd {
    // reset unfortunate global styles :'(
    float: initial;
    margin: 0;
  }

  dl.icon-list {
    // small column (icon only) and wide column (content)
    display: grid;
    gap: $spacing-s;
    grid: auto / fit-content(4ch);

    * {
      break-inside: avoid-column;
      min-width: 0; // no ‘auto’ to prevent long words from overflowing the grid
    }

    > dt {
      grid-column: 1;
    }

    > dd {
      grid-column: 2;

      > dl {
        // nested definition lists for title/value pairs
        display: flex;
        flex-direction: column;
        gap: $spacing-default;
      }
    }
  }

  .deemphasized {
    font-weight: normal;
  }

  .multi-line-text {
    // user provided text can be multi-line
    white-space: pre-line;
  }

  .icon-pgp-key {
    vertical-align: text-bottom;
  }

  // Only show hint if there are no contact details.
  .add-details:has(+ dl dt) {
    display: none;
  }
</style>
