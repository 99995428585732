/**
 *** Plugin to periodically pull for changes in the active messages list This
 *** plugin is used by default at the time, but in the future should be used as a
 *** fallback to a websockets connection.
 **/

import { MAIL } from '@/router/named-routes';
import { SET_AUTHENTICATION_STATUS_NS } from '@/store/authentication/authentication-mutation-types';

export const PERIODIC_PULL_TIMEOUT_DEFAULT_MS = 15000;
const PERIODIC_PULL_TIMEOUT_MAX_MS = 300000;

export class PeriodicUpdatePlugin {
  constructor(Vue) {
    this.store = Vue.$store;
    this.router = Vue.$router;
    this.root = Vue.$root;
    this.monitorAuthenticationState();
  }

  monitorAuthenticationState() {
    this.store.subscribe(({ type, payload }) => {
      if (type === SET_AUTHENTICATION_STATUS_NS) {
        if (payload.isAuthenticated) {
          // Only setup the periodic pull on views under the Mail route This
          // excludes views like contacts and settings for example
          this.router.afterEach((to) => {
            if (to.matched[0].name === MAIL) {
              this.setupPeriodicPull();
            } else {
              this.teardownPeriodicPull();
            }
          });
        } else {
          this.teardownPeriodicPull();
        }
      }
    });
  }

  setPullTimer(timerId, handler, timeout = PERIODIC_PULL_TIMEOUT_DEFAULT_MS) {
    // Periodic pull for changes. If there are no changes, increase the timeout
    // by a factor 2. The reason for this is because email will be trickling in
    // when the user logs in, but after that, there is no reason to call the api
    // very often.
    this[timerId] = window.setTimeout(async () => {
      // The provided handler should return if the result has any changes.
      const isUpdated = await handler();
      const newTimeout = isUpdated
        ? PERIODIC_PULL_TIMEOUT_DEFAULT_MS
        : Math.min(timeout + timeout, PERIODIC_PULL_TIMEOUT_MAX_MS);
      this.setPullTimer(timerId, handler, newTimeout);
    }, timeout);
  }

  setupPeriodicPull() {
    if (!this.updateTimer) {
      this.setPullTimer('updateTimer', async () => {
        // For messages, we check the activeMessagesIds before and after the
        // update and compare them
        const activeMessagesIds = this.store.state.messages.activeMessagesIds;
        await this.store.dispatch('loadMessages');
        const newActiveMessagesIds =
          this.store.state.messages.activeMessagesIds;
        const messagesUpdate =
          activeMessagesIds.length !== newActiveMessagesIds.length ||
          activeMessagesIds.some(
            (id, index) => id !== newActiveMessagesIds[index]
          );

        return messagesUpdate;
      });
    }
  }

  teardownPeriodicPull() {
    window.clearTimeout(this.updateTimer);
    this.updateTimer = null;
  }
}

export default {
  install(Vue) {
    let pluginInstance = null;

    // we have to wait until the first vm instance is installed before we have
    // access to the store and router
    Vue.mixin({
      beforeCreate() {
        if (pluginInstance) {
          return;
        }

        pluginInstance = new PeriodicUpdatePlugin(this);
      },
    });
  },
};
