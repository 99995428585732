<template>
  <div class="signature-management">
    <RichTextEditor
      v-model="signature"
      class="signature-management__editor"
      :drag-and-drop-enabled="false"
    />
    <LoadingButton
      class="button--accent"
      :loading="isBusy.savingSignature"
      @click="saveSignature"
      v-test:saveSignature
    >
      <translate>Save signature</translate>
    </LoadingButton>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapState } from 'vuex';
  import asyncActionMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import LoadingButton from '@/components/LoadingButton';
  import RichTextEditor from '@/components/RichTextEditor/RichTextEditor.vue';

  export default Vue.extend({
    components: {
      LoadingButton,
      RichTextEditor,
    },
    mixins: [asyncActionMixin],
    data() {
      return {
        signature: '',
      };
    },
    computed: {
      ...mapState({
        preferences: (state) => state.authentication.user.preferences,
      }),
    },
    created() {
      this.signature = this.preferences.signature || this.signature;
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['updatePreferences']),
      saveSignature: asyncAction('savingSignature', function () {
        this.setToastMessage({
          message: this.$gettext('Your signature has been changed.'),
        });
        return this.updatePreferences({
          update: {
            signature: this.signature,
          },
        });
      }),
    },
  });
</script>

<style src="./SignatureManagement.scss" lang="scss"></style>
