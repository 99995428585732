
  import ContactForm from '@/components/ContactForm/ContactForm.vue';
  import ContactSecondHeader from '@/components/ContactSecondHeader/ContactSecondHeader.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';
  import ContactSearchForm from '@/components/SearchForm/ContactSearchForm.vue';
  import contactGroupMixin from '@/mixins/contact-group-mixin';
  import AppHeader from '@/wrappers/AppHeaderWrapper/AppHeaderWrapper';
  import Vue from 'vue';
  import { mapGetters } from 'vuex';

  export default Vue.extend({
    components: {
      AppHeader,
      ContactForm,
      ContactSecondHeader,
      ContactSearchForm,
      Icon,
      Modal,
    },
    mixins: [contactGroupMixin],
    computed: {
      ...mapGetters(['activeContactGroup']),
      groupName(this: any): string {
        if (this.activeContactGroup) {
          return this.getContactGroupName(this.activeContactGroup);
        } else {
          return '';
        }
      },
    },
  });
