var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:{ 'nested-folders-item': _vm.hasChildren }},[_c('FolderItem',{class:{
      'folder-item--has-children': !!_vm.hasChildren,
      'folder-item--is-opened': _vm.isOpen,
    },attrs:{"item":_vm.folder,"is-loading":_vm.isLoading,"count":_vm.getCount({ folder: _vm.folder, isOpen: _vm.isOpen }),"show-current-tag":_vm.showCurrentTag,"shown-in-sidebar":_vm.shownInSidebar},on:{"folderItemClicked":function($event){return _vm.$emit('folderItemClicked', _vm.folder)}},scopedSlots:_vm._u([{key:"settingsActionsForFolder",fn:function({ item }){return [_vm._t("settingsActionsForFolder",null,{"item":item})]}}],null,true)}),(_vm.hasChildren)?[_c('button',{directives:[{name:"test",rawName:"v-test:nestedFolderItemToggle",arg:"nestedFolderItemToggle"}],staticClass:"button button--clean button--icon-only nested-folders-item__toggle-child",class:{ 'nested-folders-item__toggle-child--active': _vm.isOpen },attrs:{"type":"button","aria-label":_vm.toggleNestedFolderText,"aria-expanded":String(_vm.isOpen),"aria-controls":`collapsible-${_vm.folder.id}`},on:{"click":function($event){return _vm.toggleExpansion()}}},[_c('Icon',{attrs:{"symbol":"arrow-right"}})],1),_c('transition',{attrs:{"name":"collapsible-slide-in"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"nested-folders-item__child-folders"},_vm._l((_vm.folder.children),function(childFolder){return _c('NestedFoldersItem',{key:childFolder.id,attrs:{"force-expanded":_vm.forceExpanded,"folder":childFolder,"salt":_vm.salt,"opened-path":_vm.childOpenedPath,"shown-in-sidebar":_vm.shownInSidebar,"show-current-tag":_vm.showCurrentTag,"preserve-expansion":_vm.preserveExpansion},on:{"folderItemClicked":function($event){return _vm.$emit(
              'folderItemClicked',
              arguments.length ? arguments[0] : childFolder
            )}},scopedSlots:_vm._u([{key:"settingsActionsForFolder",fn:function({ item }){return [_vm._t("settingsActionsForFolder",null,{"item":item})]}}],null,true)})}),1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }