
  import Vue from 'vue';
  import SelectList from '@/components/SelectList/SelectList.vue';
  import ActionBarButton from '@/components/ActionBarButton.vue';
  import getKeyByValue from '@/lib/getKeyByValue';

  interface refComponent extends Vue {
    resetHighlightedOption: () => void;
  }

  export const fontFamilies = new Map([
    ['Arial', 'arial, helvetica'],
    ['Comic Sans', 'comic sans ms, chalkboard se, comic neue, sans-serif'],
    ['Courier New', 'courier new, courier, monospace'],
    ['Georgia', 'georgia, serif'],
    ['Helvetica', 'helvetica'],
    ['Tahoma', 'tahoma, geneva'],
    ['Times New Roman', 'times new roman, times, serif'],
    ['Trebuchet MS', 'trebuchet ms, helvetica'],
    ['Verdana', 'verdana, geneva'],
  ]);

  export default Vue.extend({
    name: 'FontSettings',
    components: {
      ActionBarButton,
      SelectList,
    },
    props: {
      font: {
        type: String,
        required: true,
      },
      fontSize: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        fontFamilies: fontFamilies,
        fontSizes: new Map([
          ['Small', 'small'],
          ['Normal', 'medium'],
          ['Large', 'large'],
          ['Huge', 'x-large'],
        ]),
      };
    },
    computed: {
      setFontText(): string {
        return this.$gettext('Set font');
      },
      fontSizeLabels(): { [key: string]: string } {
        return {
          Small: this.$gettext('Small'),
          Normal: this.$gettext('Normal'),
          Large: this.$gettext('Large'),
          Huge: this.$gettext('Huge'),
        };
      },
      setFontSizeText(): string {
        return this.$gettext('Set font size');
      },
      fontsDisplayed(): Array<string> {
        return Array.from(this.fontFamilies.keys());
      },
      fontSizesDisplayed(): Array<string> {
        return Array.from(this.fontSizes.keys());
      },
      currentFontSize(): string {
        return getKeyByValue(this.fontSizes, this.fontSize) || this.fontSize;
      },
      currentFont(): string {
        return getKeyByValue(this.fontFamilies, this.font) || this.font;
      },
    },
    methods: {
      setFont(font: string): void {
        this.$emit('setFont', this.fontFamilies.get(font));
      },
      setFontSize(fontSize: string): void {
        this.$emit('setFontSize', this.fontSizes.get(fontSize));
      },
      resetFontsSelectlist(): void {
        (this.$refs.fontsSelectList as refComponent).resetHighlightedOption();
      },
      resetFontSizesSelectlist(): void {
        (
          this.$refs.fontSizesSelectList as refComponent
        ).resetHighlightedOption();
      },
      optionClassNameSuffix(option: string): string {
        return option.replace(/ /g, '-').toLowerCase();
      },
      getTranslatedFontSizeLabel(option: string): string {
        return this.fontSizeLabels[option] || '';
      },
    },
  });
