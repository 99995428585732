
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import QueryModal from '@/components/QueryModals/QueryModal.vue';
  import RibbonBanner from '@/components/RibbonBanner.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import SubscriptionCancellationFeedback from '@/components/SubscriptionCancellationFeedback.vue';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';

  enum State {
    Loading = 'Loading', // deal availability check
    DiscountDealOffer = 'DiscountDealOffer',
    DiscountDealTaken = 'DiscountDealTaken',
    Feedback = 'Feedback', // why did you leave?
    Confirmation = 'Confirmation', // password entry
    Canceling = 'Canceling', // includes password check
  }

  export default Vue.extend({
    name: 'SubscriptionCancellation',

    components: {
      Modal,
      ModalConfirmActions,
      PasswordField,
      QueryModal,
      RibbonBanner,
      Spinner,
      SubscriptionCancellationFeedback,
    },

    data(): {
      state: State;
      comment: string;
      deal: CancellationDeal | null;
      userPassword: string;
      isPasswordEmpty: boolean;
      isPasswordInvalid: boolean;
    } {
      return {
        state: State.Loading,
        comment: '',
        deal: null,
        userPassword: '',
        isPasswordEmpty: false,
        isPasswordInvalid: false,
      };
    },

    computed: {
      ...mapGetters('authentication', [
        'hasSubscription',
        'isLastActiveMonth',
        'isInGrace',
      ]),

      State(): typeof State {
        return State;
      },

      okText(): string {
        return this.$gettext('Cancel subscription');
      },

      cancelText(): string {
        return this.$gettext('Go back');
      },

      cancelButtonText(): string {
        return this.$gettext('Cancel subscription');
      },

      passwordLabelText(): string {
        return this.$gettext('Please confirm with your account password.');
      },

      passwordValidationError(): string {
        if (this.isPasswordEmpty) {
          return this.$gettext('Password is required.');
        }
        return this.$gettext('Wrong password.');
      },

      dealText(): string {
        switch (this.deal?.type) {
          case 'discount':
            return this.$gettextInterpolate(
              this.$gettext(
                'We’re sorry to see you go. Before you do, we would like to offer you a <strong>special deal for being a valued customer.</strong> Stay with us for another year and receive <strong>%{ percentage }% off your next subscription fee</strong> or group account purchase.'
              ),
              { percentage: this.deal.discount_percentage }
            );
          case 'extension':
            return this.$gettext(
              'Are you sure you want to cancel your subscription? Maybe you need more time to get to know StartMail? Get another free week from us!'
            );
          default:
            throw Error('deal unset or invalid');
        }
      },

      takeDealButtonText(): string {
        return this.$gettext('Take the deal');
      },

      rejectDealButtonText(): string {
        return this.$gettext('Continue with cancellation');
      },

      celebrationText(): string {
        switch (this.deal?.type) {
          case 'discount':
            return this.$gettextInterpolate(
              this.$gettext(
                'Your next subscription year is now %{ percentage }% off. We’re thrilled that you choose to stay with us and continue to enjoy all the benefits of our service. Thank you for your loyalty!'
              ),
              { percentage: this.deal.discount_percentage }
            );
          case 'extension':
            return this.$gettext(
              'You have one more week completely free of charge! Thank you for choosing us and staying with us!'
            );
          default:
            throw Error('deal unset or invalid');
        }
      },
    },

    watch: {
      userPassword() {
        this.isPasswordEmpty = false;
        this.isPasswordInvalid = false;
      },
    },

    methods: {
      ...mapActions(['setToastMessage']),

      onSubmitFeedback(event: string) {
        this.comment = event;
        this.state = State.Confirmation;
      },

      async executeCancelRequest() {
        if (!this.validatePasswordValue()) {
          return;
        }
        this.state = State.Canceling; // prevent clicking twice
        try {
          await api.chargebee.cancelChargebeeSubscription(
            this.userPassword,
            this.comment ? `User canceled with reason: ${this.comment}` : ''
          );
        } catch (error: any) {
          this.state = State.Confirmation; // stay on form
          if (getErrorCode(error) === AUTHENTICATION_FAILURE) {
            this.isPasswordInvalid = true;
            return;
          }
          this.setToastMessage({
            message: this.$gettext(
              'Something went wrong. Please try again later.'
            ),
          });
          throw error;
        }
        api.uiEvents.create({
          event_type: this.deal
            ? 'subscription_cancellation_canceled_deal_offered'
            : 'subscription_cancellation_canceled_no_deal_offered',
        });
        this.setToastMessage({
          message: this.$gettext('Your subscription has been canceled.'),
        });
        this.$emit('cancelled');
        (this.$refs.modal as any).toggleModal();
      },

      validatePasswordValue() {
        if (this.userPassword === '') {
          this.isPasswordEmpty = true;
          this.isPasswordInvalid = false;
          return false;
        }
        this.isPasswordInvalid = false;
        this.isPasswordEmpty = false;
        return true;
      },

      closeModal() {
        (this.$refs.modal as any).toggleModal();
      },

      resetModal() {
        this.state = State.Loading;
        this.userPassword = '';
        this.isPasswordEmpty = false;
        this.isPasswordInvalid = false;
        this.comment = '';
        // Note: do not reset this.deal here, since the celebration
        // modal needs it after the cancellation modal closes!
      },

      closeCelebrationModal() {
        (this.$refs.celebrationModal as any).closeModal();
      },

      async startCancellationFlow() {
        (this.$refs.modal as any).toggleModal();
        let nextState = State.Feedback;
        try {
          this.deal = await api.chargebee.getCancellationDealAvailability();
          if (this.deal) nextState = State.DiscountDealOffer;
        } catch {
          // unexpected failure; assume no deal available
        }
        this.state = nextState;
        api.uiEvents.create({
          event_type:
            this.state === State.DiscountDealOffer
              ? 'subscription_cancellation_deal_offered'
              : 'subscription_cancellation_no_deal_offered',
        });
      },

      async takeDeal() {
        if (!this.deal) throw Error('no deal set');
        (this.$refs.modal as any).toggleModal();
        try {
          await api.chargebee.takeCancellationDeal(this.deal);
        } catch (error) {
          this.setToastMessage({
            message: this.$gettext(
              'Something went wrong. Please try again later.'
            ),
          });
          throw error;
        }
        this.state = State.DiscountDealTaken;
        api.uiEvents.create({
          event_type: 'subscription_cancellation_deal_taken',
        });
      },

      rejectDeal() {
        this.state = State.Feedback;
        api.uiEvents.create({
          event_type: 'subscription_cancellation_deal_rejected',
        });
      },
    },
  });
