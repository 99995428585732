<template>
  <Dropdown
    class="select-list dropdown--align-left"
    @keydown.native.down="nextOption"
  >
    <template #button="{ toggle, isOpen }">
      <slot name="trigger" :toggle="toggle" :isOpen="isOpen" />
    </template>
    <template #content="{ toggle }" ref="content">
      <ul class="link-list" @click="toggle">
        <li
          v-for="(option, index) in options"
          :key="option"
          :class="{
            'is-highlighted': highlightedOptionIndex === index,
            'is-current': currentOption === option,
          }"
          @keydown.down.stop.prevent="nextOption"
          @keydown.up.stop.prevent="previousOption"
        >
          <button
            type="button"
            class="button button--clean link-list__item"
            :ref="'option-' + index"
            @click.stop.prevent="
              toggle();
              onButtonClick(option);
            "
          >
            <slot name="option" :option="option">
              {{ option }}
            </slot>
          </button>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<script>
  import Dropdown from '@/components/Dropdown/Dropdown';

  export default {
    name: 'SelectList',

    components: {
      Dropdown,
    },
    props: {
      options: {
        type: Array,
        required: true,
      },

      currentOption: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        highlightedOptionIndex: -1,
      };
    },
    methods: {
      onButtonClick(option) {
        this.$emit('select', option);
      },

      nextOption() {
        if (this.highlightedOptionIndex === this.options.length - 1) {
          return;
        }
        this.highlightedOptionIndex++;

        // Make the highlighted option button in the select list an active element
        this.$refs['option-' + this.highlightedOptionIndex][0].focus();
      },

      previousOption() {
        if (this.highlightedOptionIndex === 0) {
          return;
        }
        this.highlightedOptionIndex--;

        // Make the highlighted option button in the select list an active element
        this.$refs['option-' + this.highlightedOptionIndex][0].focus();
      },

      resetHighlightedOption() {
        this.highlightedOptionIndex = -1;
      },
    },
  };
</script>

<style src="./SelectList.scss" lang="scss"></style>
