
  import Vue from 'vue';
  import EmptyView from '@/components/EmptyView.vue';
  import emptyStateSearchImage from '@/assets/img/empty-state-search.svg';
  import emptyStateAllContactsImage from '@/assets/img/empty-state-all-contacts.svg';
  import emptyStateFavoritesImage from '@/assets/img/empty-state-favorites.svg';
  import emptyStateContactGroupImage from '@/assets/img/empty-state-contact-group.svg';

  export default Vue.extend({
    components: {
      EmptyView,
    },
    name: 'EmptyContactGroup',
    props: {
      groupId: {
        type: String,
        default: undefined,
      },
      activeSearchQuery: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        image: null as string | null,
        content: null as string | null,
      };
    },
    created() {
      this.updateData();
    },
    methods: {
      updateData(): void {
        if (this.activeSearchQuery) this.setSearchView();
        else if (this.groupId === 'all') this.setAllContactsView();
        else if (this.groupId === 'favorites') this.setFavoritesView();
        else this.setUserGroupView();
      },
      setSearchView(): void {
        this.image = emptyStateSearchImage;
        this.content = this.$gettext(
          'could not be found in any of your contacts. Try checking for typos.'
        );
      },
      setAllContactsView(): void {
        this.image = emptyStateAllContactsImage;
        this.content = this.$gettext(
          'All contacts that you add will be saved here. To start adding a contact, click <strong>Add contact</strong> above. Or click <strong>Import</strong> to add multiple contacts at once.'
        );
      },
      setFavoritesView(): void {
        this.image = emptyStateFavoritesImage;
        this.content = this.$gettext(
          'Hand-pick your favorite contacts and they will be saved here in your <strong>Favorites</strong> group.'
        );
      },
      setUserGroupView(): void {
        this.image = emptyStateContactGroupImage;
        this.content = this.$gettext(
          'A brand new group?! Move contacts here to add them.'
        );
      },
    },
  });
