
  import Vue from 'vue';
  import contactCategoryOptionsMixin from '@/mixins/contact-category-options-mixin';

  interface Part {
    value: string;
    highlight: boolean;
  }

  interface Match {
    index: number;
    length: number;
  }

  export default Vue.extend({
    mixins: [contactCategoryOptionsMixin],
    props: {
      name: {
        type: String,
        required: true,
      },
      addresses: {
        type: Array,
        required: true,
      },
      query: {
        type: String,
        required: true,
      },
      suggestionType: {
        type: String,
        required: true,
      },
    },
    computed: {
      nameParts(): Part[] {
        return this.getParts(this.name, this.match(this.name));
      },
      moreEmailAddressesText(): string {
        if (this.addresses.length > 10) {
          return this.$gettextInterpolate(
            this.$gettext('and %{ moreEmailAddressesCount } more'),
            { moreEmailAddressesCount: this.addresses.length - 10 },
            true
          );
        }
        return '';
      },
    },
    methods: {
      splitAt(value = '', index = -1): string[] {
        return index === -1
          ? [value]
          : [value.slice(0, index), value.slice(index)];
      },
      getParts(value: string, match: Match): Part[] {
        const [prePart, rest = ''] = this.splitAt(value, match.index);
        const [matchPart, postPart] = this.splitAt(rest, match.length);

        return [
          { value: prePart, highlight: false },
          { value: matchPart, highlight: true },
          { value: postPart, highlight: false },
        ].filter(({ value }) => value);
      },
      match(value: string): Match {
        return {
          index: value.toLowerCase().search(this.query.toLowerCase()),
          length: this.query.length,
        };
      },
      emailParts(emailAddress: string): Part[] {
        return this.getParts(emailAddress, this.match(emailAddress));
      },
    },
  });
