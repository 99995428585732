import { BrowserInfo, detect } from 'detect-browser';

function getMajorVersion(version = '') {
  return Number(version.substr(0, version.indexOf('.')));
}

const UNSUPPORTED_BROWSERS: ((browserInfo: BrowserInfo) => boolean)[] = [
  ({ name }) => name === 'ie',
  ({ name, version }) =>
    name.indexOf('ios') === 0 && getMajorVersion(version) <= 10,
  ({ name, version }) => name === 'safari' && getMajorVersion(version) <= 10,
];

export default function isSupported(userAgent?: string) {
  const browser = detect(userAgent);
  const isUnsupportedBrowser =
    browser &&
    UNSUPPORTED_BROWSERS.some((predicate) => predicate(browser as BrowserInfo));
  return !isUnsupportedBrowser;
}
