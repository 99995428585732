var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-status"},[_c('div',{directives:[{name:"test",rawName:"v-test:messageStatus",arg:"messageStatus"}],staticClass:"message-status__information row"},[(_vm.encrypted)?_c('Icon',{attrs:{"symbol":"locked"}}):_vm._e(),(_vm.signed)?_c('Icon',{attrs:{"symbol":"signed"}}):_vm._e(),_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.statusText)}}),_vm._l((_vm.pgpKeys),function(pgpKey,index){return [_c('span',{key:index},[(!index)?_c('span',[_vm._v(_vm._s(` ${_vm.usingLabelText} `))]):_c('span',[_vm._v(_vm._s(', '))]),_c('span',[_vm._v(_vm._s(`${_vm.keyIdLabelText} `))]),_c('strong',[_c('router-link',{directives:[{name:"test",rawName:"v-test:messageKeyLink",arg:"messageKeyLink"}],attrs:{"to":{
                name: _vm.SETTINGS_PGP_KEYRING_KEY,
                params: {
                  fingerprint: pgpKey.fingerprint,
                  origin: 'message',
                },
              }}},[_c('span',{domProps:{"textContent":_vm._s(`${pgpKey.key_id}`)}})])],1)])]})],2)],1),(_vm.messageDecryptionStatusText)?_c('div',{staticClass:"message-status__error row"},[_c('Icon',{attrs:{"symbol":"locked"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.messageDecryptionStatusText)}})],1):_vm._e(),(_vm.erroneousSignatures.length)?_c('div',{staticClass:"message-status__error row"},[_c('Icon',{attrs:{"symbol":"key-error"}}),_c('div',{staticClass:"col"},_vm._l((_vm.erroneousSignatures),function(signature,index){return _c('div',{key:index,staticClass:"col"},[(_vm.signatureStatusError(signature))?_c('span',{domProps:{"innerHTML":_vm._s(_vm.signatureStatusError(signature))}}):_vm._e(),(signature.matches_sender === false)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.senderDoesNotMatchError)}}):_vm._e(),(signature.weak_algorithm === true)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.weakAlgorithmError)}}):_vm._e()])}),0)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }