<template>
  <div class="multi-select" @focusin="onFocusIn" @focusout="onFocusOut">
    <div class="multi-select__wrapper">
      <span :id="`${id}_label`" class="form-field__label">
        {{ label }}
      </span>

      <div class="multi-select__combobox-wrapper">
        <ul
          class="multi-select__combobox select-input__input"
          ref="combobox"
          :class="{ 'select-input__input--is-focused': showListbox }"
          :aria-labelledby="`${id}_label`"
          :tabindex="tabindex"
          @click="toggleListbox"
          @keydown.space.prevent="toggleListbox"
          @keydown.down.prevent="onKeyDown"
          @keydown.esc.stop.prevent="onKeyEscape"
        >
          <li v-if="isEmpty && placeholder" aria-hidden="true">
            {{ placeholder }}
          </li>

          <li v-for="item of valuesAsOptions" :key="item.value">
            <Badge
              ref="badges"
              class="multi-select__badge"
              :tabindex="0"
              :is-removable="true"
              @delete.stop="onDeleteBadge(item.value)"
              :key="item.value"
              :value="item.name"
            />
          </li>
        </ul>
        <Icon
          :symbol="showListbox ? 'arrow-up' : 'arrow-down'"
          class="select-input__chevron"
        />
      </div>

      <ul
        v-show="showListbox"
        role="listbox"
        :id="`${id}_listbox`"
        aria-multiselectable="true"
        :aria-labelledby="`${id}_label`"
        :aria-activedescendant="
          activeOptionIndex > -1 ? `${id}_${activeOptionIndex}` : null
        "
        :tabindex="tabindex"
        ref="listbox"
        class="multi-select__listbox"
        @keydown.down.prevent="onKeyDown"
        @keydown.up.prevent="onKeyUp"
        @keydown.space.prevent="onKeySpaceInListbox"
        @keydown.esc.stop.prevent="onKeyEscape"
      >
        <li
          v-for="(option, index) of options"
          ref="options"
          :id="`${id}_${index}`"
          role="option"
          :aria-selected="isChecked(option.value) ? 'true' : 'false'"
          class="multi-select__option"
          :class="{
            'multi-select__option--is-active': activeOptionIndex === index,
            'multi-select__option--selected': isChecked(option.value),
          }"
          :key="option.value"
          @click="onToggleOption(option.value)"
        >
          <span class="multi-select__option-name">{{ option.name }}</span>
          <Icon
            v-if="isChecked(option.value)"
            symbol="check"
            class="multi-select__option-check"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Badge from '@/components/Badge/Badge';
  import Icon from '@/components/Icon/Icon';

  export default {
    components: {
      Badge,
      Icon,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        required: false,
        default: '',
      },
      value: {
        type: Array,
        default() {
          return [];
        },
      },
      options: {
        type: Array,
        default() {
          return [];
        },
      },
      id: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: false,
        default: '',
      },
      tabindex: {
        type: [Number, String],
        required: false,
        default: 0,
      },
    },

    data() {
      return {
        isFocused: false,
        activeOptionIndex: -1,
        showListbox: false,
      };
    },

    computed: {
      model: {
        get() {
          return this.value ? this.value : [];
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      valuesAsOptions() {
        return this.value
          .map((valueItem) =>
            this.options.find((option) => option.value === valueItem)
          )
          .filter((value) => typeof value !== 'undefined');
      },
      isEmpty() {
        return this.value.length === 0;
      },
    },

    methods: {
      onFocusIn() {
        this.isFocused = true;
      },
      onFocusOut(e) {
        this.isFocused = false;

        //detect when focus is moved outside the multi-select so we can close the listbox
        if (!this.$el.contains(e.relatedTarget)) {
          this.showListbox = false;
          this.activeOptionIndex = -1;
        }
      },
      onDeleteBadge(id) {
        this.model = this.model.filter((modelId) => modelId !== id);
      },
      isChecked(id) {
        return this.value.includes(id);
      },
      /*
      We need to move the active option into view.
      The native solution of calling scrollIntoView did not work reliabily for me so i made my own implementation.
    */
      scrollActiveOptionIntoView() {
        const scroller = this.$refs.listbox;
        const optionEl = this.$refs.options[this.activeOptionIndex];
        const optionTop = optionEl.offsetTop;
        const optionBottom = optionTop + optionEl.offsetHeight;

        if (optionTop < scroller.scrollTop) {
          //scrollup
          scroller.scrollTo(0, optionTop);
        } else if (optionBottom > scroller.scrollTop + scroller.offsetHeight) {
          //scrolldown
          scroller.scrollTo(0, optionBottom - scroller.offsetHeight);
        }
      },
      onToggleOption(id) {
        if (!this.isChecked(id)) {
          this.model = [...this.model, id];
        } else {
          this.model = this.model.filter((modelId) => modelId !== id);
        }
      },
      toggleListbox(e) {
        /*
        We will move focus to the listbox when you press space.
        ...however when you click on one of the badges we still want to keep focus on the badge while also open the listbox.
      */

        let moveFocusToListbox = true;
        if (e.type === 'click' && this.$refs.badges) {
          const clickedOnBadge = this.$refs.badges.some((badge) =>
            badge.$el.contains(e.target)
          );

          if (this.showListbox && !clickedOnBadge) {
            this.close();
            return;
          } else if (clickedOnBadge) {
            moveFocusToListbox = false;
          }
        }

        this.showListbox = true;
        this.activeOptionIndex = moveFocusToListbox ? 0 : -1;

        this.$nextTick(() => {
          if (moveFocusToListbox) {
            this.$refs.listbox.focus();
          }
          this.$refs.listbox.scrollTop = 0;
        });
      },
      close() {
        this.isFocused = false;
        this.activeOptionIndex = -1;
        this.showListbox = false;
      },
      onKeyDown() {
        if (this.activeOptionIndex === this.options.length - 1) {
          return;
        }

        if (this.activeOptionIndex === -1) {
          this.$refs.listbox.focus();
        }

        this.activeOptionIndex++;
        this.scrollActiveOptionIntoView();
      },
      onKeyUp() {
        if (this.activeOptionIndex > 0) {
          this.activeOptionIndex--;
          this.scrollActiveOptionIntoView();
        } else if (this.$refs.badges.length) {
          this.activeOptionIndex = -1;
          this.$refs.badges[0].$refs.button.focus();
        }
      },
      onKeyEscape() {
        this.showListbox = false;
        this.$refs.combobox.focus();
      },
      onKeySpaceInListbox() {
        this.onToggleOption(this.options[this.activeOptionIndex].value);
      },
    },
  };
</script>

<style lang="scss" src="./MultiSelectInput.scss"></style>
