
  import api from '@/api';
  import { CHARGEBEE_INVOICE } from '@/api/endpoints';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import { getDateFormat } from '@/lib/dateTimeFormats';
  import formatCurrency from '@/lib/formatCurrency';
  import { mixin as timeMixin } from '@/lib/time';
  import Vue from 'vue';

  export default Vue.extend({
    components: { DefaultDialog, Spinner },
    mixins: [timeMixin],
    data() {
      return {
        error: false,
        invoices: [] as ChargebeeInvoice[],
        loading: false,
        // null = no more pages, string = next page info, undefined = dont yet
        // know if there are more pages
        next_offset: undefined as string | null | undefined,
      };
    },
    methods: {
      CHARGEBEE_INVOICE,
      formattedDate(date: string) {
        const dateFormat = getDateFormat(
          this.$store.state.authentication.user.preferences.date_format
        );
        return (this as any).formatDate(date, dateFormat);
      },

      formatCurrency,
      reset() {
        this.error = false;
        this.invoices = [];
        this.loading = false;
        this.next_offset = undefined;
        this.loadNextPage();
      },
      async loadNextPage() {
        if (this.next_offset === null) {
          // no more pages
          return;
        }
        this.loading = true;
        try {
          const response = await api.chargebee.listInvoices(this.next_offset);
          this.invoices.push(...response.invoices);
          this.next_offset = response.next_offset;
        } catch (err) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      },
    },
  });
