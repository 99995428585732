
  import InputField from '@/components/form/InputField.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { MAIL } from '@/router/named-routes';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import getErrorCode from '@/api/get-error-code';
  import { TOTP_FAILURE } from '@/api/error-codes';

  export default Vue.extend({
    name: 'TotpForm',
    components: {
      InputField,
      LoadingButton,
      Icon,
    },
    mixins: [asyncActionsMixin],
    props: {
      email: {
        type: String,
        required: true,
      },
      password: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        TOTPToken: '',
        TOTPError: '',
        showLoginError: false,
      };
    },
    computed: {
      authenticationCodeText(): string {
        return this.$gettext('Authentication code');
      },
    },
    watch: {
      TOTPToken() {
        this.TOTPError = '';
      },
    },
    methods: {
      ...mapActions('authentication', ['totp']),
      onTOTPSubmit: asyncAction(
        'authenticatingUsingTotp',
        function (this: any) {
          this.showLoginError = false;
          return this.totp({
            email: this.email,
            password: this.password,
            token: this.TOTPToken,
          })
            .then(({ user }: { user: User }) => {
              if (user.totp_enabled) {
                this.$emit('processUserLogin', user.login_count);
              } else {
                this.$router.push({
                  name: MAIL,
                  query: { modal: 'totp-disabled-warning' },
                });
              }
            })
            .catch((error: AxiosError) => {
              const errorCode = getErrorCode(error);
              if (errorCode === TOTP_FAILURE) {
                this.TOTPError = this.$gettext('Incorrect one-time code');
              } else {
                this.showLoginError = true;
                throw error;
              }
            });
        }
      ),
    },
  });
