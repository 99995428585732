
  import Vue from 'vue';
  import Icon from '@/components/Icon/Icon.vue';
  import OfficialBadge from '@/components/OfficialBadge.vue';

  export default Vue.extend({
    name: 'Badge',
    components: {
      Icon,
      OfficialBadge,
    },
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        required: false,
        default: '',
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
      isRemovable: {
        type: Boolean,
        default: false,
      },
      keyAvailable: {
        type: Boolean,
        default: null,
      },
      official: {
        type: Boolean,
        default: false,
      },
      showKeyAvailability: {
        type: Boolean,
        default: false,
      },
      tabindex: {
        type: [Number, String],
        default: 0,
      },
      title: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        required: true,
      },
    },
    computed: {
      button(): HTMLButtonElement {
        return this.$refs.button as HTMLButtonElement;
      },
      selectBadgeText(): string {
        return this.$gettextInterpolate(
          this.$gettext('Select %{ badgeValue }'),
          { badgeValue: this.value },
          true
        );
      },
      removeBadgeText(): string {
        return this.$gettextInterpolate(
          this.$gettext('Remove %{ badgeValue }'),
          { badgeValue: this.value },
          true
        );
      },
      titleText(): string {
        return this.keyAvailable
          ? this.$gettext('Encrypted')
          : this.$gettext('Password protected');
      },
    },
    methods: {
      focus() {
        this.button.focus();
      },
      onClick(ev: Event) {
        //firefox does not move focus to button which causes problems with the keydown.delete action
        if (document.activeElement !== this.button) {
          this.focus();
        }
        this.$emit('click', ev);
      },
      onBlur(ev: Event) {
        this.$emit('blur', ev);
      },
      onDelete(ev: Event) {
        this.$emit('delete', ev);
      },
      onFocus(ev: Event) {
        this.$emit('focus', ev);
      },
    },
  });
