import aliases from './aliases';
import authentication from './authentication/authentication';
import contacts from './contacts/modules/contacts';
import contactGroups from './contacts/modules/contact-groups';
import drafts from './mail/modules/drafts';
import entities from './entities';
import filters from './mail/modules/filters';
import folders from './mail/modules/folders';
import layout from './layout/layout';
import messages from './mail/modules/messages';
import pagination from './pagination';
import sorting from './sorting/sorting';
import search from './search/search';
import settings from './settings/settings';
import sidebar from './sidebar/sidebar';
import signup from './signup/signup';
import toast, { legacyToastModule as legacyToast } from './toast';
import subscription from './subscription';
import createBrowserMetricsCollectorPlugin from './plugins/browser-metrics-collector';

const debug = process.env.NODE_ENV !== 'production';

const browserMetricsCollectorPlugin = createBrowserMetricsCollectorPlugin();

export default {
  modules: {
    aliases,
    authentication,
    contacts,
    contactGroups,
    drafts,
    entities,
    filters,
    folders,
    layout,
    messages,
    pagination,
    sorting,
    search,
    settings,
    sidebar,
    signup,
    toast,
    legacyToast,
    subscription,
  },
  strict: debug,
  plugins: [browserMetricsCollectorPlugin],
};
