
  /*

    I think we can remove the created() hook and watcher on burnerAlias by not
    fetching burnerAlias from the store here, but getting it as a prop. Then the
    parent can send the burnerAlias store getter as prop and this thing can react
    on the prop change.

    */

  import Vue from 'vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import Spinner from '@/components/Spinner/Spinner.vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'BurnerAlias',
    components: { LoadingButton, Spinner },
    created() {
      // this is needed for when a burner is in the store at created() hook
      // time, for example when navigating from custom aliases section to burner
      // alias section
      if (this.burnerAlias) {
        this.startTimer();
      }
    },
    destroyed() {
      window.clearInterval(this.intervalId);
    },
    data() {
      return {
        loading: false,
        intervalId: 0,
        secondsLeft: 0,
      };
    },
    computed: {
      burnerAlias(): Alias | undefined {
        return this.$store.getters['aliases/burnerAlias'];
      },
      titleText(): string {
        return this.$gettext('Burner alias');
      },
      generateButtonText(): string {
        return this.$gettext('Generate');
      },
      formattedTimeLeft(): string {
        const minutes = `0${String(Math.floor(this.secondsLeft / 60))}`.slice(
          -2
        );
        const seconds = `0${String(Math.floor(this.secondsLeft % 60))}`.slice(
          -2
        );
        return `${minutes}:${seconds}`;
      },
    },
    watch: {
      burnerAlias(newValue) {
        // this is needed for when the current burner gets loaded into the store
        // which is after created() hook
        if (newValue) {
          this.startTimer();
        }
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('aliases', ['createBurner']),
      async createBurnerAlias() {
        this.loading = true;
        try {
          await this.createBurner();
          this.setToastMessage({
            message: this.$gettext('New burner alias created'),
          });
        } catch (error) {
          this.setToastMessage({
            message: this.$gettext(
              'Unable to create burner alias, please try again'
            ),
          });
          throw error;
        } finally {
          this.loading = false;
        }
      },
      startTimer() {
        window.clearInterval(this.intervalId); // this is always safe to call
        this.updateAliasExpiry();
        this.intervalId = window.setInterval(() => {
          this.updateAliasExpiry();
        }, 1000);
      },
      updateAliasExpiry() {
        if (!this.burnerAlias || !this.burnerAlias.expires) {
          return;
        }

        const secondsLeft =
          (new Date(this.burnerAlias.expires).getTime() -
            new Date().getTime()) /
          1000;

        if (secondsLeft < 1) {
          window.clearInterval(this.intervalId);
          this.secondsLeft = 0;
          this.$store.dispatch('aliases/loadAliases');
          return;
        }
        this.secondsLeft = secondsLeft;
      },
      async copyAliasToClipboard() {
        try {
          if (this.burnerAlias?.alias) {
            await navigator.clipboard.writeText(this.burnerAlias.alias);
            this.setToastMessage({
              message: this.$gettext('Burner alias is copied'),
            });
          } else {
            this.setToastMessage({
              message: this.$gettext('Unable to copy'),
            });
          }
        } catch (err) {
          // unable to write text to clipboard
        }
      },
    },
  });
