
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { MAIL_COMPOSE } from '@/router/named-routes';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import api from '@/api';

  export default Vue.extend({
    name: 'Tutorials',
    components: {
      FlipSwitch,
    },
    computed: {
      ...mapGetters('authentication', ['isSetupGuideVisible']),
      accountSetUp: {
        get(): boolean {
          return this.isSetupGuideVisible;
        },
        set(visible) {
          this.setSetupGuideVisibility(visible);
          if (visible) this.setSetupGuideCollapsed(false);
          const eventType = `setup_guide_${
            visible ? 'enabled' : 'disabled'
          }_in_settings`;
          api.uiEvents.create({
            event_type: eventType,
          });
        },
      },
      accountSetUpLabel(): string {
        return this.$gettext(
          'Show set up guide in your Inbox to help you with importing emails and contacts from another provider, setting up IMAP and 2FA, and creating aliases.'
        );
      },
    },
    methods: {
      ...mapActions('authentication', [
        'setSetupGuideVisibility',
        'setSetupGuideCollapsed',
      ]),
      async goToCompose(): Promise<void> {
        api.uiEvents.create({
          event_type: 'tutorial_settings_start_tutorial',
        });
        await this.$router.push({
          name: MAIL_COMPOSE,
          params: { startTutorial: 'true' },
        });
      },
    },
  });
