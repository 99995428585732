
  import ErrorView, { ErrorType } from '@/components/ErrorView/ErrorView.vue';
  import KeyList from '@/components/KeyList/KeyList.vue';
  import SkeletonList from '@/components/SkeletonList/SkeletonList.vue';
  import { getDateFormat } from '@/lib/dateTimeFormats';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'PGPKeyring',
    components: {
      KeyList,
      SkeletonList,
      ErrorView,
    },
    data() {
      return {
        loading: true,
        error: false,
        LoadingKeysError: ErrorType.LoadingKeysError,
      };
    },
    computed: {
      ...mapGetters(['keys']),
      ...mapGetters('authentication', ['user']),
      newKeyPairText(): string {
        return this.$gettext('New key pair');
      },
      importKeyText(): string {
        return this.$gettext('Import key');
      },
      dateFormat(): string {
        return getDateFormat(this.user.preferences.date_format);
      },
    },
    created() {
      (this as any).updateKeys();
    },
    methods: {
      ...mapActions(['loadKeys']),
      async updateKeys(this: any): Promise<void> {
        const query = this.$route.query.query;
        this.loading = true;
        try {
          await this.loadKeys({ query });
        } catch (error) {
          this.error = true;
          throw error;
        } finally {
          this.loading = false;
        }
      },
    },
  });
