
  import ActionBarButton from '@/components/ActionBarButton.vue';
  import Dropdown from '@/components/Dropdown/Dropdown.vue';
  import Vue from 'vue';
  import emojiData from 'emoji-mart-vue-fast/data/all.json';
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import 'emoji-mart-vue-fast/css/emoji-mart.css';

  const BRAND_BLUE = '#6573FF';

  export default Vue.extend({
    name: 'EmojiPicker',
    components: {
      ActionBarButton,
      Dropdown,
      Picker,
    },
    data() {
      return {
        color: BRAND_BLUE,
        Picker: null,
        emojiIndex: new EmojiIndex(emojiData),
      };
    },
    computed: {
      emojiPickerTitle(): string {
        return this.$gettext('Pick your emoji…');
      },
      emojiPickerText(): string {
        return this.$gettext('Insert emoji');
      },
      emojiPickerI18n(): object {
        // see https://github.com/serebrov/emoji-mart-vue#i18n
        return {
          search: this.$pgettext('verb', 'Search'),
          notfound: this.$gettext('No Emoji Found'),
          categories: {
            search: this.$gettext('Search Results'),
            recent: this.$gettext('Frequently Used'),
            smileys: this.$pgettext('emoji picker', 'Smileys & Emotion'),
            people: this.$pgettext('emoji picker', 'People & Body'),
            nature: this.$pgettext('emoji picker', 'Animals & Nature'),
            foods: this.$pgettext('emoji picker', 'Food & Drink'),
            activity: this.$pgettext('emoji picker', 'Activity'),
            places: this.$pgettext('emoji picker', 'Travel & Places'),
            objects: this.$pgettext('emoji picker', 'Objects'),
            symbols: this.$pgettext('emoji picker', 'Symbols'),
            flags: this.$pgettext('emoji picker', 'Flags'),
            custom: this.$pgettext('emoji picker', 'Custom'),
          },
        };
      },
    },
    methods: {
      emojiSelected(emoji: object, toggle: Function) {
        toggle();
        this.$emit('addEmoji', emoji);
      },
    },
  });
