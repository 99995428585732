<template>
  <form @submit.prevent="$emit('submit', $event)">
    <slot name="information">
      <p class="form-field" v-translate>
        In case you lose your authentication device, you can use this two-factor
        authentication backup code to gain access to your account.
      </p>
    </slot>
    <div class="totp-deactivation-code-form__code form-field">
      {{ deactivationCode }}
    </div>
    <p class="user-message user-message--warning">
      <Icon symbol="warning" />
      <span v-translate class="user-backup-code-container">
        <strong>Important:</strong> If you lose your backup code and your
        device, you will not be able to access your StartMail account. Please
        keep this code safe and somewhere accessible.
      </span>
    </p>

    <div class="modal__footer panel">
      <button v-test:OkButton class="button button--primary" type="submit">
        {{ okText }}
      </button>
    </div>
  </form>
</template>

<script>
  import Icon from '@/components/Icon/Icon';

  export default {
    name: 'TotpDeactivationCodeForm',
    components: {
      Icon,
    },
    props: {
      deactivationCode: {
        type: String,
        required: true,
      },
    },
    computed: {
      okText() {
        return this.$gettext('Close');
      },
    },
  };
</script>

<style src="./TotpDeactivationCodeForm.scss" lang="scss"></style>
