
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import InputField from '@/components/form/InputField.vue';
  import Vue, { PropType } from 'vue';
  import { mapActions } from 'vuex';
  import DefaultDialog from '../DefaultDialog.vue';
  import { isAxiosError } from 'axios';
  import Spinner from '../Spinner/Spinner.vue';

  export default Vue.extend({
    name: 'DeleteGroupSubscriptionModal',
    components: {
      Icon,
      DefaultDialog,
      InputField,
      PasswordField,
      Spinner,
    },
    props: {
      account: {
        type: Object as PropType<AccountDetails>,
        required: true,
      },
    },
    data() {
      return {
        deleting: false,
        email: '',
        emailError: '',
        password: '',
        passwordError: '',
      };
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('subscription', ['getGroupSubscriptions']),
      reset() {
        this.email = '';
        this.emailError = '';
        this.password = '';
        this.passwordError = '';
      },
      async onclick(showModal: Function) {
        await showModal();
        this.reset();
      },
      async deleteAccount(event: Event, closeModal: Function) {
        const matches =
          this.email.toLowerCase().trim() === this.account.email.toLowerCase();
        if (!matches) {
          this.emailError = this.$gettext('Email address does not match');
          this.$nextTick(() => {
            (event.target as HTMLFormElement).reportValidity();
          });
          return;
        }

        this.deleting = true;
        try {
          await api.groupSubscriptions.deleteGroupSubscription({
            password: this.password,
            terminate: { username: this.email.toLowerCase().trim() },
          });
          await this.getGroupSubscriptions();
          closeModal();
          this.setToastMessage({
            message: this.$gettext('Account successfully deleted'),
          });
        } catch (error: any) {
          if (
            isAxiosError(error) &&
            getErrorCode(error) === AUTHENTICATION_FAILURE
          ) {
            this.passwordError = this.$gettext('Wrong password');
            this.$nextTick(() => {
              (event.target as HTMLFormElement).reportValidity();
            });
            return;
          } else {
            this.setToastMessage({
              message: this.$gettext(
                'Something went wrong. Please try again later.'
              ),
            });
            throw error;
          }
        } finally {
          this.deleting = false;
        }
      },
    },
    computed: {
      labelEmail(): string {
        return this.$gettext(
          'Type the email address of the account that you want to delete'
        );
      },
      labelPassword(): string {
        return this.$gettext('Confirm with the password of your own account');
      },
    },
    watch: {
      email() {
        this.emailError = '';
      },
      password() {
        this.passwordError = '';
      },
    },
  });
