
  import Vue, { PropType } from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { getDateFormat, getTimeFormat } from '@/lib/dateTimeFormats';
  import { mixin as timeMixin } from '@/lib/time';
  import { MAIL_COMPOSE } from '@/router/named-routes';
  import CustomAliasModal from '@/components/CustomAliasModal.vue';
  import FlipSwitch from '@/components/form/FlipSwitch.vue';
  import Modal from '@/components/Modal.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Icon from '@/components/Icon/Icon.vue';

  export default Vue.extend({
    components: {
      CustomAliasModal,
      FlipSwitch,
      Modal,
      ModalConfirmActions,
      Icon,
    },

    props: {
      aliases: {
        type: Array as PropType<Alias[]>,
        required: true,
      },
    },

    mixins: [timeMixin],

    data() {
      return {
        isExpanded: false,
        noteModalContent: '',
        visibleNoteLength: 100,
      };
    },
    computed: {
      ...mapGetters('authentication', ['user']),
      ...mapGetters('subscription', ['hasMultipleAccounts']),
      deleteText(): string {
        return this.$gettext('Delete');
      },
      closeText(): string {
        return this.$gettext('Close');
      },
    },

    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('aliases', ['loadAliases', 'activate', 'remove']),
      ...mapActions('authentication', ['getAuthenticationStatus']),

      getActivateText(isActive: boolean): string {
        return isActive
          ? this.$gettext('Deactivate alias')
          : this.$gettext('Activate alias');
      },

      aliasIsActive(alias: Alias): boolean {
        const expired =
          alias.expires && Date.parse(alias.expires) < new Date().getTime();
        return alias.enabled && !expired;
      },

      aliasCanBeUsedAsSender(alias: Alias): boolean {
        return (
          this.aliasIsActive(alias) &&
          alias.recipients.includes((this.user as User).email)
        );
      },

      aliasHasIncompleteCustomDomain(alias: Alias): boolean {
        const domain = this.findCustomDomain(alias);
        if (!domain) return false; // not a custom domain
        return !domain.completed;
      },

      deletionConfirmationHtml(alias: Alias): string {
        const domain = this.findCustomDomain(alias);
        const template = domain
          ? this.$gettext(
              'This alias will be deleted. Email sent to <strong>%{ email }</strong> will no longer be delivered.'
            )
          : this.$gettext(
              'This alias will be deleted and can never be recreated. Email sent to <strong>%{ email }</strong> will no longer be delivered.'
            );
        return this.$gettextInterpolate(template, { email: alias.alias });
      },

      findCustomDomain(alias: Alias): Domain | undefined {
        const domainName = alias.alias.split('@')[1];
        return (this.$store.state.aliases.customDomains as Domain[]).find(
          (d) => d.domain_name === domainName
        );
      },

      async setAliasIsActive(alias: Alias, active: boolean) {
        try {
          await this.activate({ id: alias.id, active });
        } catch {
          this.setToastMessage({
            message: active
              ? this.$gettext('Could not activate alias')
              : this.$gettext('Could not deactivate alias'),
          });
        }
      },

      composeRoute(aliasEmail: string) {
        return {
          name: MAIL_COMPOSE,
          query: { ...this.$route.query, mailFrom: aliasEmail },
          params: { ...this.$route.params, backRoute: this.$route },
        };
      },

      async copy(email: string) {
        try {
          await navigator.clipboard.writeText(email);
          this.setToastMessage({
            message: this.$gettext('Alias copied to clipboard'),
          });
        } catch {
          // unable to write text to clipboard
        }
      },

      expirationText(alias: Alias): string {
        if (!alias.expires) return this.$gettext('No expiration');
        const expiryDate = new Date(alias.expires);
        if (!(expiryDate instanceof Date)) {
          throw new Error('Invalid date');
        }
        const preferences = (this.user as User)?.preferences || {};
        const formats = {
          date: getDateFormat(preferences.date_format),
          time:
            getTimeFormat(preferences.time_format) === '12h'
              ? 'h:mm a'
              : 'H:mm',
        };
        const sameDay = this.$gettextInterpolate(
          this.$gettext('[Deactivates today at] %{ time }'),
          formats
        );
        const otherDays = this.$gettextInterpolate(
          this.$gettext('[Deactivates] %{ date } [at] %{ time }'),
          formats
        );
        return (this as any).fromNow(expiryDate, {
          sameDay,
          nextDay: otherDays,
          nextWeek: otherDays,
          sameElse: otherDays,
        });
      },

      async deleteAlias(alias: Alias) {
        try {
          await this.remove(alias.id);
          this.setToastMessage({ message: this.$gettext('Alias deleted') });
        } catch {
          this.setToastMessage({
            message: this.$gettext('Could not delete alias'),
          });
        }
      },

      showRecipients(alias: Alias): boolean {
        const isGroupSubscription = this.hasMultipleAccounts ?? true; // undefined for non-managers
        switch (alias.type) {
          case 'personal':
            return false; // Alias goes to oneself by definition.
          case 'group':
            // For single-account subscriptions (vast majority),
            // aliases either go to yourself (very common, expected
            // situation) or to no-one (exceptional blackholing
            // situation). Avoid repeatedly showing the repicient in
            // the common case since it is just visual clutter (too
            // many email addresses visible!) without conveying actual
            // information.
            //
            // For group subscriptions, the situation is different,
            // since aliases can go to different (groups of) people:
            // always show the recipients in that case.
            return isGroupSubscription || alias.recipients.length === 0;
          default:
            return true;
        }
      },

      truncate(text: string, length = 50): string {
        return text.length > length
          ? `${text.substring(0, length - 1).trim()}…`
          : text;
      },
    },
  });
