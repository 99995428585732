/** Best effort URL sanitization. */
export function sanitizeUrl(s: string): string | null {
  try {
    return new URL(s).toString();
  } catch {
    try {
      return new URL(`https://${s}`).toString(); // handle bare domains
    } catch {
      return null;
    }
  }
}

/**
 * Gets a single URL query param value; useful for vue-router $route objects.
 *
 * This intentionally disallows ambiguous repeated param values.
 *
 * @param rawValue - the raw value, e.g. ‘$route.query.something’
 * @returns the value (if any) or undefined.
 */
export function singleQueryValue(
  rawValue: undefined | string | (string | null)[]
): string | undefined {
  // Note: input type matches vue-router Route.query properties.
  return Array.isArray(rawValue)
    ? rawValue.length === 1
      ? (rawValue[0] ?? undefined)
      : undefined
    : rawValue;
}
