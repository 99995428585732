
  import Icon from '@/components/Icon/Icon.vue';
  import RecordList from '@/components/RecordList/RecordList.vue';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    components: {
      Icon,
      RecordList,
    },
    props: {
      domainLabel: {
        type: String,
        required: true,
      },
      records: {
        type: Array,
        required: true,
      },
      fromVerifiedDomain: {
        type: Boolean,
        required: false,
        default: false,
      },
      checkingStatus: {
        type: Boolean,
        required: false,
      },
      complete: {
        type: Boolean as PropType<boolean | undefined>,
        required: false,
        default: undefined,
      },
    },
    data() {
      return {
        open: this.complete === false,
      };
    },
    watch: {
      complete(newValue: boolean | undefined) {
        // ensure errors are visible after loading
        if (newValue === false) this.open = true;
      },
    },
    computed: {
      warningText(): string {
        return this.$gettext('Domain setup not completed yet.');
      },
    },
    methods: {
      onToggle(event: Event) {
        // Keep Vue state synced with DOM state. Note: the <details>
        // ‘toggle’ event has a .newState property in some browsers,
        // but it cannot be relied on: according the specs it is a
        // regular Event, not a ToggleEvent (which does have that
        // property)
        this.open = (event.target as HTMLDetailsElement).open ?? false;
      },
    },
  });
