<template>
  <Modal class="import-keys-modal" ref="modal">
    <template #toggle>
      <slot :toggle="openModal" />
    </template>
    <template #header>
      <translate>Import public key</translate>
    </template>
    <template #content>
      <form @submit.prevent="submitKeys">
        <h2 class="panel__title">
          <Icon symbol="upload" />
          <translate>Upload</translate>
        </h2>
        <FileUpload
          name="key-file"
          class="import-keys-modal__file-upload"
          multiple="true"
          ref="fileUpload"
          :accept="['.asc', '.txt']"
          v-model="keyFiles"
        >
          <template #default="{ browse }">
            <Icon symbol="upload" />
            <p v-translate>Drag a .txt or .asc file here OR</p>
            <button
              @click="browse"
              type="button"
              class="button button--primary"
            >
              <translate>Browse</translate>
            </button>
          </template>
        </FileUpload>
        <h4 class="panel__title">
          <Icon symbol="notepad" />
          <translate>Paste</translate>
        </h4>
        <TextAreaField
          id="key-data"
          :label="keyDataLabel"
          :rows="10"
          v-model="keyData"
        />

        <p
          v-for="message in successMessages"
          :key="message"
          class="import-keys-modal__message import-keys-modal__message--success"
        >
          <Icon symbol="check" />
          {{ message }}
        </p>

        <p
          v-for="message in errorMessages"
          :key="message"
          class="import-keys-modal__message import-keys-modal__message--error"
        >
          <Icon symbol="error" />
          {{ message }}
        </p>

        <div class="modal__footer">
          <button
            type="button"
            class="button button--accent"
            @click="closeModal"
          >
            <translate>Close</translate>
          </button>
          <LoadingButton
            class="button--primary"
            :loading="isBusy.importing"
            type="submit"
          >
            <translate>Import public key</translate>
          </LoadingButton>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
  import { PGP_KEY_NOT_ASCII_ENCODED } from '@/api/error-codes';
  import FileUpload, { statusEnum } from '@/components/FileUpload/FileUpload';
  import Icon from '@/components/Icon/Icon';
  import LoadingButton from '@/components/LoadingButton';
  import Modal from '@/components/Modal';
  import TextAreaField from '@/components/form/TextAreaField';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { mapActions } from 'vuex';

  export default {
    name: 'ImportKeysModal',
    components: {
      FileUpload,
      Icon,
      LoadingButton,
      Modal,
      TextAreaField,
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        keyData: '',
        keyFiles: [],
        errorMessages: [],
        successMessages: [],
      };
    },
    computed: {
      keyDataLabel() {
        return this.$gettext('Key data');
      },
    },
    methods: {
      ...mapActions(['importKeys', 'loadKeys', 'setToastMessage']),
      openModal() {
        if (!this.$refs.modal.isOpen) {
          this.$refs.modal.setBeforeCloseCallback(this.resetStatus);
          this.$refs.modal.toggleModal();
        }
      },
      closeModal() {
        if (this.$refs.modal.isOpen) {
          this.$refs.modal.toggleModal();
        }
      },
      submitKeys: asyncAction('importing', function () {
        this.errorMessages = [];
        this.successMessages = [];

        return this.importKeys({ files: this.keyFiles, keyData: this.keyData })
          .then(({ files, keyData }) => {
            const keyDataSuccess = keyData === null || keyData.status === 0;

            let filesSuccess = true;
            this.keyFiles = this.keyFiles.map((file, index) => {
              const errorCode = files[index].status;
              if (errorCode === PGP_KEY_NOT_ASCII_ENCODED) {
                file.status = statusEnum.INVALID;
                filesSuccess = false;
              } else if (errorCode !== null && errorCode !== 0) {
                file.status = statusEnum.FAILED;
                filesSuccess = false;
              } else {
                file.status = 0;
              }
              return file;
            });

            if (keyDataSuccess && filesSuccess) {
              const nrFilesValid = this.keyFiles.filter((file) => {
                return file.status === 0;
              }).length;
              const n =
                keyData && keyData.status === 0
                  ? nrFilesValid + keyData.count
                  : nrFilesValid;
              this.setToastMessage({
                message: this.$gettextInterpolate(
                  this.$ngettext(
                    'PGP key imported',
                    '%{ n } PGP keys imported',
                    n
                  ),
                  { n }
                ),
              });
              this.closeModal();
            } else {
              const nrFilesFailed = this.keyFiles.filter((file) => {
                return file.status === statusEnum.FAILED;
              }).length;
              const nrFilesInvalid = this.keyFiles.filter((file) => {
                return file.status === statusEnum.INVALID;
              }).length;
              const totalFailed =
                keyData === null || keyData.status === 0
                  ? nrFilesFailed
                  : nrFilesFailed + keyData.count;
              const nrFilesValid = files.filter((file) => {
                return file.status === 0;
              }).length;
              const totalValid =
                keyData === null || keyData.status === 0
                  ? nrFilesValid + keyData.count
                  : nrFilesValid;

              if (totalFailed > 0) {
                this.errorMessages = [
                  ...this.errorMessages,
                  this.$gettextInterpolate(
                    this.$ngettext(
                      '%{ n } PGP key was not imported, please try again',
                      '%{ n } PGP keys were not imported, please try again',
                      totalFailed
                    ),
                    { n: totalFailed }
                  ),
                ];
              }

              if (nrFilesInvalid > 0) {
                this.errorMessages = [
                  ...this.errorMessages,
                  this.$gettextInterpolate(
                    this.$ngettext(
                      '%{ n } PGP key is invalid, please try again with a valid PGP key',
                      '%{ n } PGP keys are invalid, please try again with a valid PGP key',
                      nrFilesInvalid
                    ),
                    { n: nrFilesInvalid }
                  ),
                ];
              }

              if (totalValid > 0) {
                this.successMessages = [
                  ...this.successMessages,
                  this.$gettextInterpolate(
                    this.$ngettext(
                      'PGP key imported',
                      '%{ n } PGP keys imported',
                      totalValid
                    ),
                    { n: totalValid }
                  ),
                ];
              }

              this.removeFailedFiles();
            }
          })
          .then(() => {
            return this.loadKeys().catch((err) => {
              this.setToastMessage({
                message: this.$gettext(
                  'Something went wrong. Please refresh the page.'
                ),
              });
              throw err;
            });
          })
          .catch(() => {
            this.errorMessages = [
              this.$ngettext(
                'Your PGP key was not imported, please try again',
                'Your PGP keys were not imported, please try again',
                this.keyFiles.length + (this.keyData ? 1 : 0)
              ),
            ];
          });
      }),
      removeFailedFiles() {
        const importedFilesIndices = this.keyFiles.reduce(
          (indices, file, index) => {
            if (
              file.status === statusEnum.FAILED ||
              file.status === statusEnum.INVALID
            ) {
              return indices;
            }

            return indices.concat(index);
          },
          []
        );

        importedFilesIndices
          .reverse()
          .forEach(this.$refs.fileUpload.removeFile);
      },
      resetStatus() {
        this.keyData = '';
        this.keyFiles = [];
        this.errorMessages = [];
        this.successMessages = [];
      },
    },
  };
</script>

<style src="./ImportKeysModal.scss" lang="scss"></style>
