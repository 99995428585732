
  import * as endpoints from '@/api/endpoints';
  import ContactImportModal from '@/components/ContactImportModal/ContactImportModal.vue';
  import {
    CONTACTS_GROUP,
    SETTINGS_CONTACT_CONTACTGROUPS,
  } from '@/router/named-routes';
  import Icon from '@/components/Icon/Icon.vue';
  import Sidebar from '@/wrappers/SidebarWrapper/SidebarWrapper';
  import Vue from 'vue';
  import { Location, Route } from 'vue-router';
  import { mapActions } from 'vuex';
  import contactGroupMixin from '@/mixins/contact-group-mixin';
  import SidebarItem from '@/components/SidebarItem/SidebarItem.vue';

  export default Vue.extend({
    components: {
      SidebarItem,
      ContactImportModal,
      Icon,
      Sidebar,
    },
    mixins: [contactGroupMixin],
    data() {
      return {
        error: false,
        loading: true,
      };
    },
    computed: {
      contactGroups(): ContactGroup[] {
        return this.$store.getters.contactGroups;
      },
      manageGroupsText(): string {
        return this.$gettext('Manage groups');
      },
      importContactsText(): string {
        return this.$gettext('Import contacts');
      },
      importText(): string {
        return this.$gettext('Import');
      },
      exportContactsText(): string {
        return this.$gettext('Export contacts');
      },
      exportText(): string {
        return this.$gettext('Export');
      },
      contactSettingsRoute(): Location {
        return { name: SETTINGS_CONTACT_CONTACTGROUPS };
      },
      exportContactsUrl(): string {
        const fileName = this.$gettext('startmail-contacts-export.vcf');
        return endpoints.CONTACTS_EXPORT_GROUP('all', fileName);
      },
      contactSidebarItems(): {
        name: string;
        icon: string;
        route: object;
        count: number;
      }[] {
        return this.contactGroups.map((contactGroup: ContactGroup) => {
          return {
            name: (this as any).getContactGroupName(contactGroup),
            icon: (this as any).iconForGroup(contactGroup.id),
            route: { name: CONTACTS_GROUP, params: { group: contactGroup.id } },
            count: contactGroup.contact_count,
          };
        });
      },
    },
    watch: {
      $route(this: any, route: Route) {
        if (route.params.group) {
          this.setActiveContactGroup({ id: route.params.group });
        }
      },
    },
    created(this: any) {
      this.getContactGroups()
        .then(() => {
          this.setActiveContactGroup({ id: this.$route.params.group });
          this.loading = false;
          this.error = false;
        })
        .catch((error: Object) => {
          this.loading = false;
          this.error = true;
          this.setLoadingContactGroupsError({ error });
          throw error;
        });
    },
    methods: {
      ...mapActions([
        'getContactGroups',
        'setActiveContactGroup',
        'setLoadingContactGroupsError',
      ]),
    },
  });
