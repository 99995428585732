
  import Vue from 'vue';
  import Icon from '../Icon/Icon.vue';
  import ModalDialog from '../ModalDialog.vue';

  export default Vue.extend({
    components: {
      Icon,
      ModalDialog,
    },
  });
