
  import Vue from 'vue';
  export default Vue.extend({
    name: 'EmptyView',
    props: {
      image: {
        type: String,
        required: false,
        default: undefined,
      },
    },
  });
