
  import AuthenticationLayout from '@/components/AuthenticationLayout/AuthenticationLayout.vue';
  import Vue from 'vue';
  import {
    SIGNUP_DEFAULT,
    SIGNUP_WITH_COUPON,
    SIGNUP_CUSTOM_DOMAIN,
  } from '@/router/named-routes';
  import { Route } from 'vue-router';

  interface CookiesNotEnabledComponent extends Vue {
    tryAgainRoute: Route;
  }

  export default Vue.extend({
    components: {
      AuthenticationLayout,
    },
    data() {
      return {
        backgroundImage: require('@/assets/img/cookie.svg'),
        tryAgainRoute: '/',
      };
    },
    props: {
      originalToPath: {
        type: Object,
        default: () => ({}),
      },
    },
    beforeRouteEnter(to, from: Route, next) {
      if (
        from.name === SIGNUP_DEFAULT ||
        from.name === SIGNUP_WITH_COUPON ||
        from.name === SIGNUP_CUSTOM_DOMAIN
      ) {
        return next((vm) => {
          (vm as CookiesNotEnabledComponent).tryAgainRoute = from;
        });
      }
      next();
    },
    created() {
      if (
        this.originalToPath.name === SIGNUP_DEFAULT ||
        this.originalToPath.name === SIGNUP_WITH_COUPON ||
        this.originalToPath.name === SIGNUP_CUSTOM_DOMAIN
      ) {
        this.tryAgainRoute = this.originalToPath;
      }
    },
    computed: {
      privacyPolicyLink(): string {
        return this.$gettext('https://www.startmail.com/privacy/');
      },
      supportArticleLink(): string {
        return this.$gettext(
          'https://support.startmail.com/hc/en-us/articles/360006636657'
        );
      },
    },
  });
